<!--TO DO
1.set date on component if already set (date binding prop being passed as "date")
-->

<template>
  <div class="date-picker">
    <div class="selecteddate" v-on:click="showingpicker = !showingpicker">{{ duedate }}<button class="clear-button" v-on:click="clearDate($event)" v-if="date">X</button></div>
<transition name="slide-fade">
<div class="datearea" v-if="showingpicker">
  <transition name="fade" mode="out-in">
    <div v-if="showmonths" class="months-container" key="months">
      <div class="months">
        <button class="sidebuttons" v-on:click="recedeactiveyearindex()">
          +</button
        ><button v-on:click="showmonths = false" class="button">
          {{ years[activeyearindex] }}</button
        ><button class="sidebuttons" v-on:click="advanceactiveyearindex()">
          +
        </button>
      </div>
      <div class="monthscontainer">
        <div
          v-for="months in longMonths"
          v-on:click="selectmonthyear(months, activeyearindex)"
          v-bind:key="months"
          class="showmonths"
          :class="{
            today: currentmonthyear(months, years[activeyearindex]),
            selected: selectedmonthyear(months, years[activeyearindex]),
            fromparent: fromparentmonthyear(months, years[activeyearindex])
          }"
        >
          {{ months }}
        </div>
      </div>
    </div>
  <!-- </transition>
<transition name="dates-fade"> -->
      <div v-else class="dates-container" key="dates">
        <div class="months">
          <button class="sidebuttons" v-on:click="recedeactivemonthindex()">
            +
            </button><button v-on:click="showmonths = true" class="button">
            {{ longMonths[activemonthindex] + " " + years[activeyearindex] }}
            </button><button class="sidebuttons" v-on:click="advanceactivemonthindex()">
            +
          </button>
        </div>
        <div  class="week-container">
          <div v-for="days in shortDays" v-bind:key="days" class="week"><h5>{{days}}</h5></div>
        </div>
        <div class="days">
          <div
            class="emptyday"
            v-for="empty in emptyarray"
            :key="empty + '-empty'"
          >
            <h4>{{null}}</h4>
          </div>
          <div
            class="day"
            v-for="day in array"
            v-on:click="selectdaymonthyear(day, activemonthindex, activeyearindex)"
            :key="day"
            :id="'dates'+day"
            :class="{
              today: currentdaymonthyear(day,activemonthindex,years[activeyearindex]),
              selected: selecteddaymonthyear(day,activemonthindex,years[activeyearindex]),
              fromparent: fromparentdaymonthyear(day,activemonthindex,activeyearindex)
            }"
          >
            <h4>{{day}}</h4>
          </div>
        </div>
        
      </div>
  </transition>
</div>
</transition>
  </div>
</template>

<script>
var datefns = require("date-fns");
export default {
  props:['date','reset'],
  methods: {
    clearDate(event){
      event.stopPropagation()
      // console.log("Click")
      this.clear=true
    },
    yearsarray(year) {
      for (var i = 1900; i <= 2100; i++) {
        this.years.push(i);
      }
      this.indexofcurrentyear = this.years.indexOf(year);
      this.activeyearindex = this.years.indexOf(year);
    },
    daysarray() {
      return (this.daysinmonth = datefns.getDaysInMonth(
        new Date(this.years[this.activeyearindex], this.activemonthindex)
      ));
      this.emptydaysinmonth = datefns.getDay(datefns.startOfMonth(this.today));
    },
    currentdaymonthyear(day, month, year) {
      return (
        datefns.isSameDay(this.today, datefns.setDate(this.checkdate, day)) &&
        datefns.isSameMonth(
          this.today,
          datefns.setMonth(this.checkdate, month)
        ) &&
        datefns.isSameYear(this.today, datefns.setYear(this.checkdate, year))
      );
    },
    currentmonthyear(month, year) {
      return (
        datefns.isSameMonth(
          this.today,
          datefns.setMonth(this.checkdate, this.longMonths.indexOf(month))
        ) &&
        datefns.isSameYear(this.today, datefns.setYear(this.checkdate, year))
      );
    },
    currentyear() {},
    recedeactiveyearindex() {
      if (this.activeyearindex >= 1) {
        this.activeyearindex = this.activeyearindex - 1;
      } else {
        this.activeyearindex = 200;
      }
    },
    advanceactiveyearindex() {
      if (this.activeyearindex <= 199) {
        this.activeyearindex = this.activeyearindex + 1;
      } else {
        this.activeyearindex = 0;
      }
    },
    recedeactivemonthindex() {
      if (this.activemonthindex >= 1) {
        this.activemonthindex = this.activemonthindex - 1;
      } else {
        this.activemonthindex = 11;
        this.activeyearindex = this.activeyearindex - 1;
      }
    },
    advanceactivemonthindex() {
      if (this.activemonthindex <= 10) {
        this.activemonthindex = this.activemonthindex + 1;
      } else {
        this.activemonthindex = 0;
        this.activeyearindex = this.activeyearindex + 1;
      }
    },
    selectmonthyear(month, year) {
      this.selectedmonthindex = this.longMonths.indexOf(month);
      this.selectedyearindex = year;
      this.activemonthindex = this.longMonths.indexOf(month);
      this.showmonths = false;
    },
    selectdaymonthyear(day, month, year) {
      if (this.selectedday !== day ||  this.selectedmonthindex !== month) {
        this.selectedmonthindex = month;
        this.selectedyearindex = year;
        this.selectedday = day;
      } else {
        this.selectedday = null;
      }
    },
    selectedmonthyear(month, year) {
      return (
        datefns.isSameMonth(
          datefns.setMonth(this.checkdate, this.selectedmonthindex),
          datefns.setMonth(this.checkdate, this.longMonths.indexOf(month))
        ) &&
        datefns.isSameYear(
          datefns.setYear(this.checkdate, this.years[this.selectedyearindex]),
          datefns.setYear(this.checkdate, year)
        )
      );
    },
    selecteddaymonthyear(day, month, year) {
      return this.selectedday === day && this.selectedmonthindex === month && this.years[this.selectedyearindex] === year
      /*(
        datefns.isSameDay(
          datefns.setDate(this.checkdate, this.selectedday),
          datefns.setDate(this.checkdate, day)
        ) &&
        datefns.isSameMonth(
          datefns.setMonth(this.checkdate, this.selectedmonthindex),
          datefns.setMonth(this.checkdate, month)
        ) &&
        datefns.isSameYear(
          datefns.setYear(this.checkdate, this.years[this.selectedyearindex]),
          datefns.setYear(this.checkdate, year)
        )
      );*/
    },
    fromparentmonthyear(month,year){
      return datefns.isSameMonth(
          datefns.setMonth(this.checkdate, this.fromparentdata.month),
          datefns.setMonth(this.checkdate, this.longMonths.indexOf(month))
        )  &&
        datefns.isSameYear(
          datefns.setYear(this.checkdate, this.years[this.fromparentdata.year]),
          datefns.setYear(this.checkdate, year)
        )
      },
    fromparentdaymonthyear(day,month,year){
      return this.fromparentdata.day === day && this.fromparentdata.month === month && this.fromparentdata.year === year
      }
  },
  computed: {
    emptyarray() {
      return datefns.getDay(
        datefns.startOfMonth(
          new Date(this.years[this.activeyearindex], this.activemonthindex)
        )
      );
    },
    array() {
      return datefns.getDaysInMonth(
        new Date(this.years[this.activeyearindex], this.activemonthindex)
      );
    },
    duedate() {
      if ((this.selectedday || this.selectedmonthindex || this.selectedyearindex) && !this.clear){
      this.$emit(
        "dateInput",
        (this.selectedday == null ? "" : this.selectedday + " ") +
          
          (this.selectedmonthindex == null
            ? ""
            : this.longMonths[this.selectedmonthindex] +
              " " +
              this.years[this.selectedyearindex])
      );
        return (
        (this.selectedday == null ? " " : this.selectedday) +
        " " +
        (this.selectedmonthindex == null
          ? "Due on"
          : this.longMonths[this.selectedmonthindex] +
            " " +
            this.years[this.selectedyearindex])
      );

      }
      if (this.date && !this.clear){
        var date = new Date(this.date)
        this.fromparentdata.day= !isNaN(parseFloat(this.date.charAt(0))) && isFinite(this.date.charAt(0)) ? datefns.getDate(date) : null
        this.fromparentdata.month = datefns.getMonth(date)
        this.fromparentdata.year = this.years.indexOf(datefns.getYear(date))
        return this.date
      }
      else { 
        if(this.clear){
          this.$emit("clear",{event:'clear'})
          this.clear = false;
          this.selectedday = null
          this.selectedmonthindex = null
          this.selectedyearindex  = null
        }
        return "Due on"
        }


/*
      return (
        (this.selectedday == null ? " " : this.selectedday) +
        " " +
        (this.selectedmonthindex == null
          ? "Due on"
          : this.longMonths[this.selectedmonthindex] +
            " " +
            this.years[this.selectedyearindex])
      );*/
      
    }
  },
  watch:{
    date:function(){
      var date = new Date(this.date)
        this.fromparentdata.day= !isNaN(parseFloat(this.date.charAt(0))) && isFinite(this.date.charAt(0)) ? datefns.getDate(date) : null
        this.fromparentdata.month = datefns.getMonth(date)
        this.fromparentdata.year = this.years.indexOf(datefns.getYear(date))
    },
    reset:function(){
      this.selectedday = null
      this.selectedmonthindex = null
      this.selectedyearindex  = null
      this.showingpicker= false
      this.clear = false
    }
  },

  beforeMount() {
    this.today = Date.now();
    this.emptydaysinmonth = datefns.getDay(datefns.startOfMonth(this.today));
    this.activemonthindex = datefns.getMonth(this.today);
    this.yearsarray(datefns.getYear(this.today));
  },
  data() {
    return {
      clear:false,
      showingpicker: false,
      years: [],
      indexofcurrentyear: null,
      today: null,
      daysinmonth: null,
      emptydaysinmonth: null,
      activemonthindex: null,
      activeyearindex: null,
      checkdate: new Date(),
      showmonths: false,
      selectedyearindex: null,
      selectedmonthindex: null,
      selectedday: null,
      fromparentdata:{day:null,month:null,year:null},
      format: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      },
      longDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      longMonths: [
        "January",
        "Feburary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    };
  }
};
</script>
<style scoped>
.date-picker {
  width: var(--width);
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);
  --width: 100%;
  min-width: 250px;
  margin: 0px;
}
.datearea {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius:3px;
  margin-bottom: 5px;
  max-height:500px;
  width:100%;
  position:relative;
}
.months-container{
  height:100%;
  /* position:absolute; */
}
.dates-container{
  height:100%;
  /* position:relative; */
  /* position:absolute; */
}
.months {
  vertical-align: middle;
  height: 10%;
}
.button {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  color: white;
  font-weight: bold;
  font-size: 100%;
  text-align: center;
  letter-spacing: 0.2em;
  height: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  width: 80%;
  vertical-align: middle;
}
.button:hover {
  background-color: gray;
}
.sidebuttons {
  width: 10%;
  border: none;
  margin: 0px;
  height: 100%;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  color: white;
}
.week-container {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 0.5fr));
  justify-items: center;
  align-content: center;
  vertical-align: middle;
  height:10%;
  color:rgb(150, 150, 150);
}
.week h5{
  font-weight: bolder;
  text-align: center;
  font-size: 1rem;
  text-align: center;
  margin:0;
}
.days {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  justify-content: center;
  align-items: center;
  /* vertical-align: middle; */
  height:80%;
}
.day {
  color: white;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  padding: 2%;
  width:100%;
  transition: all 0.2s ease-in-out;
}
.day h4{
  font-size: 1rem;
}
.day:hover {
  background-color: black;
  color:white !important;
  border-radius: 2px;
  cursor: pointer;
}
.emptyday {
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  text-align: center;
  vertical-align: middle;
  flex: 0 1 14.28571428571429%;
  padding: 2%;
}
.today {
  background-color: gray;
}
.selected {
  background-color: black;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
}
.fromparent {
  background-color: white;
  color:black !important;
  font-weight:bold;
}
.fromparent h4{
  /* background-color: white; */
  color:black !important;
  font-weight:bold;
}
.selecteddate {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
  cursor:pointer;
  background-color: black;
  color: white;
  font-weight: bold;
  /* font-size: 100%; */
  /* text-align: center; */
  letter-spacing: 0.1em;
  height: 40px;
  border-radius: 5px;
  /* padding: 8px; */
  margin-bottom: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;

}
.clear-button{
  position:absolute;
  right:0;
  height:100%;
  width:10%;
  border-radius:0 5px 5px 0;
  border:none;
  outline:none;
  background-color: rgb(197, 55, 55);
  color:white;
}
.monthscontainer {
  /* display: flex;
  width: 100%;
  height:238.34px;
  flex-flow: row wrap;
  justify-content: center; */
  display:grid;
  height:90%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  /* min-height:300px; */
  /* align-items: center; */

}
.showmonths {
  font-weight: bold;
  color: white;
  font-size: 0.7rem;
  letter-spacing: 0.2rem;
  text-align: center;
  vertical-align: middle;
  padding: 2%;
  margin: 2%;
  display:flex;
  align-items: center;
  justify-content: center;
  /* flex: 30%; */
}
.showmonths:hover {
  background-color: black;
  color:white !important;
  border-radius: 2px;
  cursor: pointer;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: max-height .3s ease 0s, opacity .3s ease .3s;
}
.slide-fade-leave-active {
  transition: max-height .3s ease .3s, opacity .3s ease .0s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  /* transform: translatey(10px); */
  opacity: 0;
  max-height: 0px;
}
.fade-enter-active {
  transition:  opacity .3s ease ;
}
.fade-leave-active {
  transition:  opacity .3s ease ;
}
.fade-enter, .fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  /* transform: translatey(10px); */
  opacity: 0;
}
</style>
