import 'babel-polyfill'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Store from "./Vuex/Vuex";
import Plugins from "./plugins/plugins";
import axios from "axios";
import axiosInstances from "./AxiosInstances"
import * as Keycloak from "keycloak-js";
import scrollAnimation from './directives/inview.js'
import globalClickOutside from './directives/globalClickOutside.js'
import componentClickOutside from './directives/componentClickOutside.js'
import { createApi } from 'unsplash-js';


import GlobalUtilities from './GlobalUtilities';

let initOptions = {
  url: "https://login.cubeitdone.com/auth",
  realm: "cubeItDone",
  clientId: "Website",
  onLoad: "check-sso"
};
let keycloak = Keycloak(initOptions);
keycloak.onTokenExpired = ()=>{
          keycloak.updateToken(180).then((refreshed)=>{
          if(refreshed){
            console.log("token refresh")
            Vue.prototype.$api ? Vue.prototype.$api.defaults.headers.common["Authorization"] =
            "Bearer " + keycloak.token : alert("ERROR!")
            Vue.prototype.$apiShared ? Vue.prototype.$apiShared.defaults.headers.common["Authorization"] =
            "Bearer " + keycloak.token : alert("ERROR!")
            Vue.prototype.$fileStore ? Vue.prototype.$fileStore.fileStoreApi.defaults.headers.common["Authorization"] =
            keycloak.token : alert("ERROR!")
            Vue.prototype.$fileStore ? Vue.prototype.$fileStore.fileStoreApiShared.defaults.headers.common["Authorization"] =
            keycloak.token : alert("ERROR!")
            Vue.prototype.$people.peopleApi ? Vue.prototype.$people.peopleApi.defaults.headers.common["Authorization"] =
            "Bearer " + keycloak.token : alert("ERROR!")
          Vue.prototype.$people.peopleApiRequests ? Vue.prototype.$people.peopleApiRequests.defaults.headers.common["Authorization"] =
            "Bearer " + keycloak.token : alert("ERROR!")
          Vue.prototype.$people.peopleApiSearch ? Vue.prototype.$people.peopleApiSearch.defaults.headers.common["Authorization"] =
            "Bearer " + keycloak.token : alert("ERROR!")
          }
          else{
            alert("Hmmmmmm.....somethings wrong gtg...")
            window.location.href="https://cubeitdone.com"
          }
        }).catch(()=>{
          alert('Failed to refresh token');
        });
}
keycloak.init({ onLoad: initOptions.onLoad,checkLoginIframe: false })
  .then(auth => {

      var token = keycloak.token
      const unsplash = createApi({
        apiUrl: `${process.env.VUE_APP_UNSPLASH_API}`
      });
      
      Vue.config.productionTip = false;
      Vue.directive('scrollanimation',scrollAnimation)
      Vue.directive('global-click-outside', globalClickOutside)
      Vue.directive('component-click-outside', componentClickOutside)
      Vue.use(Store);
      Vue.use(Plugins);
      Vue.use(axiosInstances);
      Vue.use(GlobalUtilities);
      Vue.prototype.$fileStore.fileStoreApi.defaults.headers.common["Authorization"] = token;
      Vue.prototype.$fileStore.fileStoreApiShared.defaults.headers.common["Authorization"] = token;
      Vue.prototype.$api.defaults.headers.common["Authorization"] ="Bearer " + token;
      Vue.prototype.$apiShared.defaults.headers.common["Authorization"] = "Bearer " + token;
      Vue.prototype.$profile.defaults.headers.common["Authorization"] ="Bearer " + token;
      Vue.prototype.$people.peopleApi.defaults.headers.common["Authorization"] = "Bearer " + token;
      Vue.prototype.$people.peopleApiRequests.defaults.headers.common["Authorization"] = "Bearer " + token;
      Vue.prototype.$people.peopleApiSearch.defaults.headers.common["Authorization"] = "Bearer " + token;
      Vue.prototype.$loggedIn = auth;
      Vue.prototype.$loginURL = keycloak.createLoginUrl({redirectUri:"https://cubeitdone.com"});
      Vue.prototype.$registerURL = keycloak.createRegisterUrl();
        Vue.prototype.$name = keycloak.tokenParsed?.given_name || 'Test'
        Vue.prototype.$userName = keycloak.tokenParsed?.preferred_username || null
        Vue.prototype.$axios = axios
        Vue.prototype.$settings = Vue.observable({
                                                    wallpaperId:null,
                                                    wallpaperUrl:null,
                                                    wallpaperUsername:null,
                                                    wallpaperUsernameLink:null,
                                                    backgroundColor:null,backgroundWidth:null,
                                                    backgroundHeight:null,
                                                    theme:null
                                                  })
                                                  Vue.prototype.$storageInfo = Vue.observable({
                                                    fileCount:null,
                                                    filesRemaining:1000,
                                                    storageUsed:null,
                                                    storageRemaining:null,

                                                  })
        Vue.prototype.$state = Vue.observable({tool:false,showtool:false})
        Vue.prototype.$img = Vue.observable({img:null})
        Vue.prototype.$viewFile = Vue.observable({file:null})
        Vue.prototype.unsplash = unsplash
        new Vue({
          router,
          render: h => h(App)
        }).$mount("#app")
  })
  .catch(() => {
    console.log("error");
  });