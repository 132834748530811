import api from "./apiService.js"
import apiShared from "./apiServiceSharing.js"
import fileStore from "./fileStoreService.js"
import profileService from "./profileService.js"
import peopleService from "./peopleService.js"


export default {
    install(Vue){
        Vue.prototype.$api = api;
        Vue.prototype.$fileStore = fileStore;
        Vue.prototype.$profile = profileService;
        Vue.prototype.$people = peopleService;
        Vue.prototype.$apiShared = apiShared
    }
}