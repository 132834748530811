<template>
  <div class="settings-container">
    <div class="dark-mode">
        <!-- <button v-on:click="toggleTheme()">theme</button> -->
        <DarkThemeSwitch></DarkThemeSwitch>
    </div>
    <transition name="slide" appear>
        <div class="settings-tiles" v-if="!show" key="tiles">
            <div class="Storage-Tile">
                <StorageTile></StorageTile>
            </div>
            <div class="Wallpaper-Tile">
                <WallpaperTile v-on:show="show = !show"></WallpaperTile>
            </div>
            <div class="Profile-Tile">
                <ProfileTile></ProfileTile>
            </div>
        </div>
        <div class="wallpaper-tile-view" v-else key="wallpaper">
            <WallpaperSettings v-on:show="show = !show"></WallpaperSettings>
        </div>
    </transition>

</div>
</template>

<script>


import DarkThemeSwitch from "../../components/DarkThemeSwitch.vue"
import ProfileTile from './Tiles/ProfileTile.vue';
import StorageTile from './Tiles/StorageTile.vue';
import WallpaperTile from './Tiles/WallpaperTile.vue';
import WallpaperSettings from "./WallpaperSettings.vue"

export default {
    components:{
        DarkThemeSwitch,
        StorageTile,
        WallpaperTile,
        ProfileTile,
        WallpaperSettings
    },
    computed:{
        storageUsed:function(){
            return (this.$storageInfo.storageUsed / 1.074e+9).toFixed(2)
        }
    },
    data(){
        return {
            Status:"loading...",
            show:false,         
        }
    },
    mounted(){

    },
    watch:{

    },
    methods:{
        
    }
};
</script>

<style>
.settings-container{
    width:100%;
    height:100%;
    position:relative;
}


.dark-mode{
    height:10%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.wallpaper-tile-view{
    width:100%;
    height:90%;
    position: absolute; /*coz transitioin*/
}

.settings-tiles{
    height:88%;
    width:100%;
    display:grid;
    grid-template-columns: 95%;
    grid-gap:15px;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: space-around;
    position: absolute; /*coz transitioin*/
}

.settings-tiles button{
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 0px 0px;
}

.settings-tiles button:hover{
    box-shadow: 0px 0px 50px -10px;
}





</style>