const animatedScrollObserver = new IntersectionObserver(
    (entries) => entries.forEach((entry)=>{
        if(entry.isIntersecting){
            //console.log(entry.target)
            entry.target.classList.add('enter');
            //animatedScrollObserver.unobserve(entry.target)
        }
        if(!entry.isIntersecting){
            //console.log(entry.target)
            entry.target.classList.remove('enter');
            //animatedScrollObserver.unobserve(entry.target)
        }
    })
)

export default {
    bind(el){
        el.classList.add('before-enter');
        animatedScrollObserver.observe(el);
    }
}