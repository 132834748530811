<template>
<transition-group tag="div" mode="out-in" name="fade" appear>
      <div v-if="!imageLoaded || !$settings.wallpaperUrl" key="loading" class="loading-dom"  >
        <img src="./assets/img/logo.png" alt="">
        <h2>Loading...</h2>
      </div>
  <div  id="Dash" class="Dash" :class="animation()" :style="styleFunc()" key="Dash" v-if="$settings.wallpaperUrl">
  <div class="overlay"></div>
        <DocumentViewer></DocumentViewer>
    <div v-if="imageLoaded">
      <transition name="appear-translate" mode="out-in" appear>
        <router-view name="header" v-if="$loggedIn" />
        <router-view name="headerNoAuth" v-else />
      </transition>      
      <div>
        <transition appear name="appear" mode="out-in">
        <router-view />
        </transition>
      </div>
      <router-view name="footer" />
    </div>
  </div>
</transition-group>

</template>
<script>
import DocumentViewer from "./Subviews/DocumentViewer.vue"
export default {
  components:{
    DocumentViewer
  },
  mounted(){
    this.images = this.importimages(require.context('@/assets/wallpapers', false, /\.(png|jpe?g|svg)$/));
    this.imageLoaded = false;
    if(this.$loggedIn){
      this.$api.get(`/api/settings`)
      .then(result => {
        this.$settings.theme = result.data.theme
        this.$settings.wallpaperId = result.data.wallpaper_id
        this.$settings.backgroundColor = result.data.wallpaper_color
      })
      .catch(()=>{this.$settings.wallpaperId = 1234})
      .then(()=>{
        this.unsplash.photos.get({ photoId:this.$settings.wallpaperId }).then((data)=>{
          var image = new Image();
          image.onload = ()=> {
                 this.$settings.wallpaperUrl = image.src
          }
          image.src = data.response.urls.full
          // this.$settings.wallpaperUrl = data.response.urls.full
          this.$settings.wallpaperUsername = data.response.user.username
          this.$settings.wallpaperUsernameLink = data.response.user.links.html
          this.$settings.backgroundWidth = data.response.width
          this.$settings.backgroundHeight = data.response.height
          })
      .catch(()=>{this.$settings.wallpaperUrl = this.images[0]})
      .then(()=>{
        this.$nextTick(()=>{
          window.setTimeout(()=>{this.imageLoaded = true},30)
        })
      })
      })
    }
    else{
      this.$settings.wallpaperUrl = 'https://images.unsplash.com/photo-1532581291347-9c39cf10a73c?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=aaron-wu-_8rjlHwN4uk-unsplash.jpg'
      this.$settings.wallpaperId = 1234
        this.$nextTick(()=>{
          window,setTimeout(()=>{this.imageLoaded = true},3000)
    })
    }
    if(this.$loggedIn){
      this.fileStore()
      this.getProfileImage()
    }
  },
  data(){
    return{
      images:[],
      wallpaper:this.$settings.id,
      imageLoaded:false
    }
  },
    methods:{
    importimages(r) {
      // console.log(r.keys().map(r))
      return r.keys().map(r);
    },
    styleFunc(){
      return {
            backgroundImage:'url('+this.$settings.wallpaperUrl+')',
            backgroundColor:this.$settings.backgroundColor,
            backgroundSize:this.aspectRatio(),
      }
    },
    animation(){
      if (this.$settings.backgroundWidth > this.$settings.backgroundHeight) {
                // console.log('landscape');
                return "Dash-landscape"
            } else if (this.$settings.backgroundWidth < this.$settings.backgroundHeight) {
                // console.log('portrait');
                return "Dash-portrait"
            } else {
                console.log('Problem with wallpaper aspect ratio');
                // return "cover"
            }
    },
    aspectRatio(){
      if (this.$settings.backgroundWidth > this.$settings.backgroundHeight) {
                // console.log('landscape');
                return "cover"
            } else if (this.$settings.backgroundWidth < this.$settings.backgroundHeight) {
                // console.log('portrait');
                return "auto 100%"
            } else {
                console.log('Problem with wallpaper aspect ratio');
                // return "cover"
            }
    },
    fileStore(){
      this.$fileStore.fileStoreApi.post('/storage/info').then((data)=>{
        this.$storageInfo.storageUsed = data.data.bytesUsed
        this.$storageInfo.fileCount = data.data.objectCount
      })
    },
    getProfileImage(){
        this.$profile.get('/size/lg').then((data)=>{
          this.$img.img=data.data
        })}
  }
}
</script>
<style scoped>
/* .Dash {
  transition: background-image 1s ease-in-out;
  height:100vh;
  background-size: cover; 
  background-repeat:no-repeat;
  background-attachment: fixed;
  background-position: center;
  z-index:0;
  position:relative;
 
} */
.Dash{
  transition: background-image 1s 2s ease-in-out,background-color 1s ease-in-out,background-size 1s ease-in-out;
  height:100vh;
  background-repeat:no-repeat;
  background-size: cover; 
  background-position: center;
  z-index:0;
  position:relative;
}

.Dash-portrait{
  animation: splash-portrait 1.5s normal forwards ease-in-out;
}
.Dash-landscape{
  animation: splash 1.5s normal forwards ease-in-out;
}

@keyframes splash-portrait {
  from {
    background-size:auto 105%; 
  }

  to {
    background-size:auto 100%;
  }
}
@keyframes splash {
  from {
    background-size:105% 105%; 
  }

  to {
    background-size:100% 100%;
  }
}

.overlay{
  position:absolute;
  background-color: var(--overlay-color);
  height:100vh;
  width:100vw;
  z-index:-1;
  pointer-events: none;
  transition: background-color 1s ease-in-out;
}



.loading-dom{
  position:absolute;
  height:100vh;
  width:100vw;
  backdrop-filter: blur(100px);
  z-index:999;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:50px;
  /* background-color: brown; */
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ripple div {
  position: absolute;
  border: 4px solid rgb(28, 211, 211);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


.slide-fade-enter-active {
    transition: max-height .3s ease 0s, opacity .3s ease .3s;
  }
  .slide-fade-leave-active {
    transition: max-height .3s ease .3s, opacity .3s ease .0s;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    /* transform: translatey(10px); */
    opacity: 0;
    max-height: 0px;
  }
</style>
