<template>
<div class="notification-container">
    <div class="crono-container">
        <button class="side-button" v-on:click="$event.stopPropagation();activeviewindex = activeviewindex-1"><img src="../assets/img/arrow-left.png"></button>
        <h5 class="crono">{{'Due '+duedates[activeviewindex]}}</h5>
        <button class="side-button" v-on:click="$event.stopPropagation();activeviewindex = activeviewindex+1"><img src="../assets/img/arrow-right.png"></button>
    </div>
    <div class="notifications-container">
        <div class="nothing" v-if="datelist().length === 0"><h4>nothing due {{duedates[activeviewindex]}}</h4></div>
        <template v-for="dates in datelist()">
            <div v-bind:key="'list'+dates.id" class="notification" v-on:click="clickNotification(dates.id,dates.due_done)">
                <h4 :style="dates.due_done >> false ? {textDecoration: 'line-through'}:{}">{{dates.title}}</h4>
            </div>
        </template>
    </div>
</div>
</template>
<script>
var datefns = require("date-fns");

export default {
        props:['ID','dateData'],
        methods:{
            notificationList(){
            if (this.datedata)
                this.datedata.forEach(element => {
                    element.activeviewindices=[]
                    if(datefns.isToday(new Date(element.due_on))){
                        element.activeviewindices.push(0)
                    }
                    if(datefns.isTomorrow(new Date(element.due_on))){
                        element.activeviewindices.push(1)
                    }
                    if(datefns.isThisWeek(new Date(element.due_on))){
                        element.activeviewindices.push(2)
                    }
                    if(datefns.isThisWeek(datefns.subWeeks(new Date(element.due_on),1))){
                        element.activeviewindices.push(3)
                    }
                    if(datefns.isThisMonth(new Date(element.due_on))){
                        element.activeviewindices.push(4)
                    }
                    if(datefns.isThisMonth(datefns.subMonths(new Date(element.due_on),1))){
                        element.activeviewindices.push(5)
                    }
                    });
            },
            datelist(){
            if (this.datedata) 
                return this.datedata.filter(element => {return element.activeviewindices.includes(this.activeviewindex) && element.flow_id === this.ID})
            },
            clickNotification(itemId,done){
                event.stopPropagation()
                const index = this.datedata.findIndex(item => item.id == itemId)
                const val = !done << 0
                this.$apiShared.put(`/api/sharing/items/update/duedone`,{
                    id:itemId,
                    duedone: val,
                    flow_id: this.ID
                }).then(()=>{
                    this.datedata[index].due_done = val 
                })
            }
        },
        data(){
            return{
                duedates:['today','tomorrow','this Week','next Week','this Month','next Month'],
                datedata:[],
                activeviewindex:0
            }
        },
        watch:{
            activeviewindex:function(){
            if(this.activeviewindex === -1) {
                this.activeviewindex = 5
            }
            if(this.activeviewindex === 6) {
                this.activeviewindex = 0
            }
            },
            dateData:function(){
                this.datedata = this.dateData
                this.notificationList()
            }
        }
}
</script>
<style scoped>
.notification-container{
    width:100%;
    height:100%;
    /* background-color: rgba(0, 0, 0, 0.459); */
}
.crono-container{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:10%;
}
.crono{
    width:80%;
    margin:0;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:var(--text-color);
    transition:color 1s ease-in-out;
}

.side-button{
    width:10%;
    height:100%;
    padding:0;
    display:flex;
    align-items: center;
    justify-content: center;
    border:none;
    background-color: transparent;
    opacity:0;
    transition: all 0.2s ease-in-out;
}

.side-button img{
    width:50%;
    filter:drop-shadow(0 0 9px )
    
}
.notifications-container{
    /* background-color: aqua; */
    height:83%;
    padding:5%;
    overflow-y: auto;
}


.nothing h4{
    color: var(--text-color);
    transition:color 1s ease-in-out;
}

.notification{
    background-color: var(--color);
    margin-top:3%;
    padding-left:5%;
    color:var(--text-color);
    transition:background-color 1s ease-in-out;
    border-radius:2px;
}
.notification h4{
    margin:0;
    color: var(--text-color);
    transition:color 1s ease-in-out;
    /* text-decoration: line-through; */
}


</style>