<template>
  <div class="chat-main">
      <div class="video-chat-container" v-if="!noDeviceAttached || permissioinDenied">
          <div class="video-away">
                    <div class="call-stat">
                        <h1 v-if="calling">Calling...</h1>
                        <h1 v-if="declined">Declined</h1>
                        <h1 v-if="error">Error</h1>
                    </div>
                    <div v-if="callFrom" class="call-from call-stat" >
                        <h1>Call from</h1>
                        <h2>{{callFrom}}</h2>
                        <button id="Answer">Answer</button>
                        <button id="Decline">Decline</button>
                    </div>

                <div class="video-call-stats" v-if="Object.keys(remoteState).length === 3">
                    <div><div :class="'video-state ' + (remoteState.mic? 'remote-mic' : 'remote-mic-off')" v-on:click="micState()"></div></div>
                    <div><div :class="'video-state ' + (remoteState.camera? 'remote-camera' : 'remote-camera-off')" v-on:click="cameraState()"></div></div>
                </div>
              <video autoplay="true" ref="awayVideo"></video>
          </div>
          <div class="video-home"><video autoplay="true" ref="homeVideo"></video></div>
          <div class="video-screen-share"><video autoplay="true" ref="screenShare"></video></div>
      </div>
      <div v-if="noDeviceAttached" class="video-chat-options  no-permission">
          <h1>Looks like there is no camera or microphone attached.</h1>
      </div>
      <div v-else-if="permissioinDenied" class="video-chat-options  no-permission">
          <h1>Looks like we don't have permission to use your camera and microphone.</h1>
      </div>
      <div v-else class="video-chat-options-container">
        <div class="video-chat-options">
            <!-- <div v-if="!$userName" class="hello-container">
                <h1>Hi! This is not the actual front page.</h1>
                <p> This page is being use to test video chat, it works but doesn't.</p>
                <h3>cubeItDone is being built to be a more responsible platfrom that is solely focused on balance and productivity for everyone.</h3>
                <h3>Do signIn to check it out and leave a feedback / let us know how we can make it more useful for you.</h3>
                <h3>There is no need to SignIn to video chat with someone.</h3>
                <p> Just send over the random numbers and letters to the other person or enter the other persons random number and letters ( or username ) and click connect!</p>
            </div> -->
            <div class="video-chat-user-details">
                <h2>{{peerID}}</h2>
                <div class="video-chat-user-details-buttons">
                    <button v-if="!$userName" v-on:click="reInit()">get new ID</button>
                    <button v-on:click="copy()">
                                                    <svg
      :style="copied ? {strokeDashoffset:0} : {strokeDashoffset:-50}"
      class="tick"
      width="65"
      height="30"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"

    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
                        <svg
                        :style="copied ? {strokeDashoffset:-50} : {strokeDashoffset:0}"
                        class="clip"
                        width="65"
                        height="30"
                        viewBox="0 0 16 16"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"

                        >
                            <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
                            <path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
                        </svg>

                    </button>
                    <!-- <button>share</button> -->
                </div>
            </div>
            <div class="connect-to">
                <div class="connect-input">
                    <input type="text" v-model="connectTo">
                    <button v-on:click="connect()">Connect</button>
                    
                </div>

                <!-- <div class="connect-options">
                    <button>use camera</button>
                    <button>use mic</button>
                    <button>use screen</button>
                </div> -->
            </div>
        </div>

        <div class="video-chat-settings-container">

                <div class="video-call-actions">
                    <button class="video-action-button endcall" id="hangup" disabled="true">Leave</button>
                    <div><button :class="'video-action-button ' + (state.mic? 'mic' : 'mic-off')" v-on:click="micState()"></button><p class="button-text">{{state.mic? "on" : "off"}}</p></div>
                    <div><button :class="'video-action-button ' + (state.camera? 'camera' : 'camera-off')" v-on:click="cameraState()"></button><p class="button-text">{{state.camera? "on" : "off"}}</p></div>
                    <div><button class="video-action-button screen-share" v-on:click="screenShare()"></button><p class="button-text">{{state.screenShare? "on" : "off"}}</p></div>
                    <!-- <div><button class="video-action-button maximize"></button><p class="button-text"></p></div> -->
                    <button v-on:click="showTests = !showTests" class="expand-test"><div :class="showTests ? 'arrows down':'arrows up'"></div></button>
                </div>
            <transition name="slide-fade">
                <div class="device-settings" v-if="showTests">
                    <div class="device-select camera-select">
                        <div class="video-action-button camera"></div>
                        <select name="camera" class="device-selector">
                            <option v-for="(camera,index) in cameras" v-bind:key="'camera'+index">{{camera.label}}</option>
                        </select>
                        <div class="device-test">
                            <video autoplay="true" ref="testVideo"></video>
                        </div>
                    </div>
                    <div class="device-select mic-select">
                        <div class="video-action-button mic"></div>
                        <select name="mic" class="device-selector">
                            <option v-for="(mic,index) in mics" v-bind:key="'mic'+index">{{mic.label}}</option>
                        </select>
                    </div>
                    <div class="device-select speaker-select">
                        <div class="video-action-button camera"></div>
                        <select name="speaker" class="device-selector">
                            <option v-for="(speaker,index) in speakers" v-bind:key="'speaker'+index">{{speaker.label}}</option>
                        </select>
                    </div>

                </div>
            </transition>

        </div>
      </div>
  </div>
</template>

<script>
import Peer from 'peerjs';
import AudioLevels from '../Subviews/Chat/AudioLevels.vue';
export default {
    components:{
        AudioLevels
    },
    data(){
        return{
            state:{
                camera:false,
                mic:false,
                screenShare:false,
            },
            remoteState:{

            },
            is:{
                caller:false,
                callee:false
            },
            cameras:[],
            mics:[],
            speakers:[],
            testStream:null,
            copied:false,
            showTests:false,
            peerID:null,
            connectTo:'',
            peer:new Peer(this.$userName,{host: 'chat.cubeitdone.com',path: '/chat',secure:true}),
            permissioinDenied:false,
            noDeviceAttached:false,
            mediaStream:null,
            calling:false,
            declined:false,
            error:false,
            callFrom:null,
            socket:null,
            caller:null,
            callee:null,
            videoTrack:[],
            audioTrack:[],
            permissionsNames : [
  "geolocation",
  "notifications",
  "push",
  "midi",
  "camera",
  "microphone",
  "speaker",
  "device-info",
  "background-fetch",
  "background-sync",
  "bluetooth",
  "persistent-storage",
  "ambient-light-sensor",
  "accelerometer",
  "gyroscope",
  "magnetometer",
  "clipboard",
  "display-capture",
  "nfc"
]
        }
    },

async mounted(){
    navigator.mediaDevices.enumerateDevices().then((data)=>{
        console.log(data)
    })
    // this.peer = 
//         const allPermissions = []
//   // We use Promise.all to wait until all the permission queries are resolved
//   await Promise.all(
//     this.permissionsNames.map(async permissionName => {
//         try {
//           let permission
//           switch (permissionName) {
//             case 'push':
//               // Not necessary but right now Chrome only supports push messages with  notifications
//               permission = await navigator.permissions.query({name: permissionName, userVisibleOnly: true})
//               break
//             default:
//               permission = await navigator.permissions.query({name: permissionName})
//           }
//         //   console.log(permission)
//           allPermissions.push({permissionName, state: permission.state})
//         }
//         catch(e){
//           allPermissions.push({permissionName, state: 'error', errorMessage: e.toString()})
//         }
//     })
//   )
        var audio
        var video
        await navigator.permissions.query({name:'microphone'}).then((config)=>{
            // console.log(config)
            switch(config.state){
                case "prompt":
                    audio = true
                    break;
                case "granted":
                    audio = false
                    break;
                case "denied":
                    this.permissioinDenied = true
                    break;
            }
        }).catch(error => {console.log("navigator.permissions error", error);audio=true})
        await navigator.permissions.query({name:'camera'}).then((config)=>{
            // console.log(config)
            switch(config.state){
                case "prompt":
                    video = true
                    break;
                case "granted":
                    video = false
                    break;
                case "denied":
                    this.permissioinDenied = true
                    break;
            }
        }).catch(error => {console.log("navigator.permissions error", error);video=true})
        // console.log(audio,video)
        if (audio || video){
            navigator.mediaDevices.getUserMedia({audio:audio,video:video }).then((stream)=>{
                stream.getVideoTracks().forEach((track)=>{
                    track.stop()
                })
                stream.getAudioTracks().forEach((track)=>{
                    track.stop()
                    })
                this.mediaStream = stream
                }
                
            ).catch(error=>{
                this.noDeviceAttached = true
            })
        }
        navigator.mediaDevices.enumerateDevices().then((devices)=>{
            devices.forEach((device)=>{
                switch (device.kind){
                    case "videoinput":
                        this.cameras.push(device)
                        break;
                    case 'audioinput':
                        this.mics.push(device)
                        break;
                    case 'audiooutput':
                        this.speakers.push(device)
                        break;
                }
            })
        })
        .catch((error)=>{console.log(error)})
        if(!this.cameras.length && !this.mics.length) this.noDeviceAttached = true

        this.init();
    },
    watch:{
        showTests:function (val){
            if(val){
                navigator.mediaDevices.getUserMedia({video: true}).then((stream)=>{
                    this.testStream = stream;
                    // console.log(this.testStream)
                    this.$refs.testVideo.srcObject = this.testStream;
                })
            }
            else if(this.testStream){
                // console.log("stop", this.testStream)
                this.testStream.getVideoTracks().forEach(track=>{track.stop()})
            }
        }
    },
    methods:{
        reInit(){
            this.peer=new Peer(this.$userName,{host: 'chat.cubeitdone.com',path: '/chat',secure:true})
            this.init()
        },
        init(){
            
            this.peer.on('open', () =>{
                // console.log(this.peer.id)
                this.peerID = this.peer.id
            });
            this.peer.on('error',(error)=>{
                // console.log(error)
                window.location.reload()
            })
            this.peer.on('connection', (conn) => {
                conn.on('data', async (data) => {
                    // Will print 'hi!'
                    // console.log(data);
                    // console.log(Object.keys(data))
                    conn.send("Received")
                if (data == "Decline"){
                    document.querySelector(".video-chat-container").style.display=""
                    this.callee.call.close()
                    this.callee.stream.getVideoTracks().forEach(track=>{track.stop()})
                    this.callee.stream.getAudioTracks().forEach(track=>{track.stop()})
                    conn.close()
                    this.declined = false
                    this.calling = false
                    this.error = false
                    document.querySelector("#hangup").disabled=true
                    this.remoteState={}
                }
                
                else if (this.$utils.arraysAreEqual(Object.keys(data), ["camera", "mic","screenShare"])){
                    this.remoteState = data
                }
                else if(this.$utils.arraysAreEqual(Object.keys(data), ["RemoteDescriptionCallee"])){
                    // console.log("setting decsc")
                    await this.callee.call.peerConnection.setRemoteDescription(data.RemoteDescriptionCallee)
                    await this.callee.call.peerConnection.setLocalDescription()
                    this.callee.connection.send({RemoteDescriptionCallee: this.callee.call.peerConnection.localDescription.toJSON()})
                }
                else if(this.$utils.arraysAreEqual(Object.keys(data), ["RemoteDescriptionCaller"])){
                    // console.log("setting decsc rem")
                    await this.callee.call.peerConnection.setRemoteDescription(data.RemoteDescriptionCaller)
                }
                else if (data == "ScreenEnd"){
                    // console.log("SCREEN END DATA");document.querySelector(".video-chat-container").classList.remove("show-screen-share");this.state.screenShare = false
                }
                });
                conn.peerConnection.addEventListener('track', async (event) => {
    // console.log("TRACK!!")
})
                this.socket = conn
            });


                        
            this.peer.on("call",(call)=>{
                // document.querySelector(".video-chat-container").style.display="flex"
                // console.log("INCOMING")
                this.callFrom = call.peer
                
                this.$nextTick(()=>{
                    navigator.mediaDevices.getUserMedia({video: true, audio:true}).then((stream) => {
                    // console.log("INCOMING")
                    stream.onaddtrack = (event) =>{
                    //   console.log("TRACK ADDED HERE", event)
                    }

                    this.callee = {call:call,stream:stream,connection:this.socket}
                    this.$refs.homeVideo.srcObject = stream;
                    
                    document.querySelector('#Answer').addEventListener("click",()=>{
                        this.callFrom = null
                        this.is.callee = true
                        stream.getVideoTracks().forEach(track=>{track.enabled=this.state.camera})
                        stream.getAudioTracks().forEach(track=>{track.enabled=this.state.mic})
                        call.answer(stream)
                        })
                    document.querySelector('#Decline').addEventListener("click",()=>{
                        document.querySelector(".video-chat-container").style.display=""
                        call.close()
                        this.callee.connection.send("Decline")
                        this.callFrom = null
                        stream.getVideoTracks().forEach(track=>{track.stop()})
                        stream.getAudioTracks().forEach(track=>{track.stop()})
                        })

                    document.querySelector('#hangup').addEventListener("click",()=>{
                        document.querySelector(".video-chat-container").style.display=""
                        call.close()

                        })
                    call.on("close",()=>{
                        // console.log("close from callee")
                        document.querySelector(".video-chat-container").classList.remove("show-screen-share");this.state.screenShare = false
                        this.callee.connection.send("Close")
                        this.callFrom = null
                        // stream.getVideoTracks().forEach(track=>{track.stop()})
                        // stream.getAudioTracks().forEach(track=>{track.stop()})
                        stream.getTracks().forEach(track=>{track.stop()})
                        if (this.callee.screenShare) this.callee.screenShare.getTracks().forEach(track => {track.stop()})
                        this.is.callee = false
                        document.querySelector("#hangup").disabled=true
                        this.remoteState = {}
                    }) //perform cleanup's here, this is called when callee closes connection
                    // call.onnegotiationneeded = e => console.log("connection nego")
                    // call.peerConnection.onnegotiationneeded = e => console.log("connection nego")
            })
                })
                call.on("stream",(remoteStream)=>{
                    //   console.log("STREAM!!")
                remoteStream.onaddtrack = async (event) =>{
                    //   console.log("TRACK ADDED on add", event)
                      document.querySelector(".video-chat-container").classList.add("show-screen-share")
                        let newStream = await new MediaStream([event.track])
                        // newStream.getTracks()[0].onended = e =>{console.log("SCREEN END");document.querySelector(".video-chat-container").classList.remove("show-screen-share")}
                        this.$refs.screenShare.srcObject = newStream;
                        // console.log(newStream.getVideoTracks())
                  }
                remoteStream.onremovetrack = async (event) =>{
                    //   console.log("TRACK REMOVE on add", event)
                      
                  }
                    //   console.log(remoteStream.getVideoTracks())
                    document.querySelector("#hangup").disabled=false
                    // this.$refs.homeVideo.srcObject = stream;
                    this.callee.connection.send({camera:this.state.camera,mic:this.state.mic,screenShare:this.state.screenShare})
                      this.$refs.awayVideo.srcObject = remoteStream;
                })

                

            })
        },
        connect(){
            this.declined = false 
            const conn = this.peer.connect(this.connectTo);
            conn.on('open', () => {
                conn.send('hi!');
                conn.send('hi!');
                conn.send('hi!');
                conn.send('hi!');
            });
            conn.on('data',async (data)=>{
                // console.log(data)
                
                if (data == "Decline" || data == "Close"){
                    this.caller.call.close()
                    // this.caller.stream.getVideoTracks().forEach(track=>{track.stop()})
                    this.caller.stream.getTracks().forEach(track=>{track.stop()})
                    if (this.caller.screenShare) this.caller.screenShare.getTracks().forEach(track => {track.stop()})
                    conn.close()
                    data == "Decline" ? this.declined = true : this.declined = false
                    this.calling = false
                    this.error = false
                    document.querySelector("#hangup").disabled=true
                    this.remoteState ={}
                }
                else if (this.$utils.arraysAreEqual(Object.keys(data), ["camera", "mic","screenShare"])){
                    this.remoteState = data
                }
                else if(this.$utils.arraysAreEqual(Object.keys(data), ["RemoteDescriptionCallee"])){
                    // console.log("setting decsc local")
                    await this.caller.call.peerConnection.setRemoteDescription(data.RemoteDescriptionCallee)
   
                }
                else if(this.$utils.arraysAreEqual(Object.keys(data), ["RemoteDescriptionCaller"])){
                    // console.log("setting decsc rem")
                    await this.caller.call.peerConnection.setRemoteDescription(data.RemoteDescriptionCaller)
                    await this.caller.call.peerConnection.setLocalDescription()
                    this.caller.connection.send({RemoteDescriptionCaller: this.caller.call.peerConnection.localDescription.toJSON()})
                }
                else if (data == "ScreenEnd"){
                    // console.log("SCREEN END DATA");document.querySelector(".video-chat-container").classList.remove("show-screen-share");this.state.screenShare = false
                }
            })
            // conn.peerConnection.onnegotiationneeded = e => console.log("connection nego")
            navigator.mediaDevices.getUserMedia({ video: true,audio:true })
                .then((stream) => {
                    // console.log(stream)
                  const call = this.peer.call(this.connectTo, stream);
                  stream.getVideoTracks().forEach(track=>{track.enabled=this.state.camera})
                  stream.getAudioTracks().forEach(track=>{track.enabled=this.state.mic})
                //   console.log(stream.getVideoTracks())
                  this.$refs.homeVideo.srcObject = stream;

                  this.calling=true
                  stream.onaddtrack = (event) =>{
                      console.log("TRACK ADDED", event)
                  }
                  this.caller = {call:call,stream:stream,connection:conn}
                  call.on("stream", (remoteStream)=>{
                    remoteStream.onaddtrack = async(event) =>{
                    //   console.log("TRACK ADDED new stream", event)
                        document.querySelector(".video-chat-container").classList.add("show-screen-share")
                        let newStream = await new MediaStream([event.track])
                        newStream.getTracks()[0].onended = e =>{document.querySelector(".video-chat-container").classList.remove("show-screen-share");this.state.screenShare = false}
                        this.$refs.screenShare.srcObject = newStream;
                  }
                    remoteStream.onremovetrack = async (event) =>{
                    //   console.log("TRACK REMOVE new stream", event)
                      
                  }
                    //   console.log("CALLER STREAM!!")
                    this.caller.connection.send({camera:this.state.camera,mic:this.state.mic,screenShare:this.state.screenShare})
                    document.querySelector("#hangup").disabled=false
                    this.calling = false
                    this.error = false
                    this.decline = false
                    this.is.caller = true
                    // console.log(remoteStream.getVideoTracks())
                    this.$refs.awayVideo.srcObject = remoteStream;
                  })
                  call.on("close",()=>{ //perform cleanup's here, this is called when caller closes connection
                    //   console.log("Close from caller")
                      conn.send("Decline")
                      this.caller.stream.getVideoTracks().forEach(track=>{track.stop()})
                      this.caller.stream.getAudioTracks().forEach(track=>{track.stop()})
                      this.declined = false
                      this.calling = false
                      this.error = false
                      this.is.caller = false
                      document.querySelector("#hangup").disabled=true
                      this.remoteState = {}
                  })
                  call.on("error",()=>{
                      this.error = true
                      this.declined = false
                      this.calling = false
                  })
                //   call.onnegotiationneeded = e => console.log("connection nego")
                  document.querySelector('#hangup').addEventListener("click",()=>{
                      if(this.caller.screenShare) this.caller.screenShare.getTracks().forEach(track => {track.stop()})
                      call.close()
                      })
                })
                .catch(function (error) {
                //   console.log(error);
                });
        },
        micState(){
            this.state.mic = !this.state.mic
            if (this.is.caller){
                this.caller.connection.send({camera:this.state.camera,mic:this.state.mic,screenShare:this.state.screenShare})
                this.caller.stream.getAudioTracks().forEach(track=>{track.enabled = this.state.mic})
            }
            else if (this.is.callee){
                this.callee.connection.send({camera:this.state.camera,mic:this.state.mic,screenShare:this.state.screenShare})
                this.callee.stream.getAudioTracks().forEach(track=>{track.enabled = this.state.mic})
            }
        },
        cameraState(){
            this.state.camera = !this.state.camera
            if (this.is.caller){
                this.caller.connection.send({camera:this.state.camera,mic:this.state.mic,screenShare:this.state.screenShare})
                this.caller.stream.getVideoTracks().forEach(track=>{track.enabled = this.state.camera})
            }
            else if (this.is.callee){
                this.callee.connection.send({camera:this.state.camera,mic:this.state.mic,screenShare:this.state.screenShare})
                this.callee.stream.getVideoTracks().forEach(track=>{track.enabled = this.state.camera})
            }
        },
        async screenShare(){

            // console.log(this.callee.stream)
            // console.log(this.callee.connection)
            navigator.mediaDevices.getDisplayMedia().then(async (stream)=>{
                this.state.screenShare = true
                document.querySelector(".video-chat-container").classList.add("show-screen-share")
                // this.callee.connection.peerConnection.addTrack(stream.getTracks()[0])
                // this.callee.stream.addTrack(stream.getTracks()[0])
                // this.callee.call.peerConnection.addTrack(stream.getTracks()[0])
                // console.log(this.callee.call._localStream.getTracks())
                // this.callee.call._localStream.addTrack(stream.getTracks()[0])
                // this.callee.call._remoteStream.addTrack(stream.getTracks()[0])
                // console.log(this.callee.call._localStream.getTracks())
                // this.callee.call.peerConnection.addStream(stream)
                // this.callee.connection.peerConnection.addStream(stream)
                if(this.callee){
                    // console.log(this.callee.call)
                    this.callee.screenShare = stream
                    stream.getTracks()[0].onended = e =>{
                        // console.log("SCREEN END");
                        this.callee.stream.getTracks()[0].stop()
                        document.querySelector(".video-chat-container").classList.remove("show-screen-share")
                        this.state.screenShare = false
                        this.callee.connection.send("ScreenEnd")}
                    this.callee.call.peerConnection.getSenders().forEach((sender)=>{
                    // console.log(sender)
                    })
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                    this.callee.call.peerConnection.addTrack(stream.getTracks()[0],this.callee.stream)
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                    this.callee.call.peerConnection.getSenders().forEach((sender)=>{
                    // console.log(sender)
                    })
                    	await this.callee.call.peerConnection.setLocalDescription();
                        const msids = this.callee.call.peerConnection.localDescription.sdp.split('\n')
                                        .map(l => l.trim())
                                        .filter(l => l.startsWith('a=msid:'));
                        this.callee.connection.send({RemoteDescriptionCaller:this.callee.call.peerConnection.localDescription.toJSON()})
                        // console.log('offer msids', msids);
                        // await pc2.setRemoteDescription(pc1.localDescription);
	                    // await pc2.setLocalDescription();
                        // await pc1.setRemoteDescription(pc2.localDescription); 
                    this.callee.call.peerConnection.getSenders().forEach((sender)=>{
                    // console.log(sender)
                    })
                    // console.log(this.callee.call)
                
                    // this.callee.connection.peerConnection.getSenders().forEach((sender) => {
                    //     if (sender.track && (sender.transport.state === "connected")) {
                    //         console.log("add")
                    //       sender.setStreams(stream);
                    //     }
                    // });

                    this.$refs.screenShare.srcObject = stream
                }
                else {
                    // console.log(this.caller)

                    this.caller.screenShare = stream
                    this.caller.call.peerConnection.getSenders().forEach((sender)=>{
                    // console.log(sender)
                    })
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                    stream.getTracks()[0].onended = e =>{
                        // console.log("SCREEN END");
                        this.caller.stream.getTracks()[0].stop()
                        document.querySelector(".video-chat-container").classList.remove("show-screen-share")
                        this.state.screenShare = false
                        this.caller.connection.send("ScreenEnd")}
                    this.caller.call.peerConnection.addTrack(stream.getTracks()[0],this.caller.stream)
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
                    this.caller.call.peerConnection.getSenders().forEach((sender)=>{
                    // console.log(sender)
                    })
                    	await this.caller.call.peerConnection.setLocalDescription();
                        const msids = this.caller.call.peerConnection.localDescription.sdp.split('\n')
                                        .map(l => l.trim())
                                        .filter(l => l.startsWith('a=msid:'));
                        // console.log('offer msids', msids);
                        this.caller.connection.send({RemoteDescriptionCallee:this.caller.call.peerConnection.localDescription.toJSON()})
                        // await pc2.setRemoteDescription(pc1.localDescription);
	                    // await pc2.setLocalDescription();
                        // await pc1.setRemoteDescription(pc2.localDescription); 
                    this.caller.call.peerConnection.getSenders().forEach((sender)=>{
                    // console.log(sender)
                    })

                    this.$refs.screenShare.srcObject = stream
                }



            })

            // navigator.mediaDevices.getDisplayMedia().then((stream)=>{
            //     this.callee.call.peerConnection.getSenders().forEach((sender)=>{
            //         console.log(sender)
            //         if(sender.track.kind == "video"){
            //              sender.replaceTrack(stream.getVideoTracks()[0]);
            //         }
            //     })
            // })
        },
        copy(){
            var copyText = this.peerID;
             /* Copy the text inside the text field */
            navigator.clipboard.writeText(copyText);
            this.copied = true;
            setTimeout(() => {this.copied = false}, 1000)
        }
    }
}
</script>

<style>
.hello-container{
    color:white;
    width:80%;
    background-color: black;
    border-radius:5px;
    padding:0% 2% 2%;
    
}
.hello
.no-permission{
    display:flex;
    justify-content: center;
    align-items: center;
}
video{
    height:100%;
    width:100%;
    z-index:1000;
    object-fit:cover;
    border-radius:inherit;
}
.chat-main{
    height:90vh;
    width:100vw;
    display:flex;
    /* background-color: bisque; */
}
.video-chat-container{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    width:40%;

    /* background-color: black; */
}

.call-stat{
    position:absolute;
    width:100%;
    z-index:100;
    background-color: cadetblue;
    border-radius:5px;
}

.video-away{
    background-color: rgb(14, 14, 14);
    height:90%;
    width:80%;
    border-radius:5px;
    position:relative;
}

.callfrom h2{
    width:100%;
}

.video-home{
    position:absolute;
    background-color:rgb(14, 14, 14);
    width:150px;
    height:250px;
    bottom:4%;
    right:-5%;
    border-radius:2px;
    box-shadow: 0 0 20px -15px;
}

.video-screen-share{
    /* background-color: green; */
    position:absolute;
    display:none;
    background-color:rgb(14, 14, 14);
}

.show-screen-share{
    display:grid;
    /* background-color: blue; */
    width:70%;
    position:relative;
    height:100%;
    grid-template-rows: 1.5fr .5fr;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
}

.show-screen-share .video-away{
    order:3;
    position:unset;
    width:150px;
    height:150px;
    background-color: aquamarine;

}
.show-screen-share .video-home{
    order:4;
    position:unset;
    width:150px;
    height:150px;
    background-color:aqua;

}
.show-screen-share .video-screen-share{
    order:1;
    position:unset;
    display:block;
    grid-column-start: 1;
    grid-column-end: 3;
    width:90%;
}

.video-controls{
    position:absolute;
    /* background-color:green; */
    width:auto;
    height:auto;
    bottom:6%;
    left:12%;
    border-radius:2px;
    
    display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:35px;
}
.controls{
    display:flex;
    justify-content: center;
    align-items: center;
    transition:all .5s ease-in-out;
    opacity:0;
    pointer-events: none;
}
.control{
    height: 70px;
    width: 70px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 20px -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.control img{
    width:60%;
    filter:invert(1);
}
.hangup-container{
    transform:translateX(0%);
    z-index:100;
}
.video-mute-container{
    transform:translateX(-150%);
}
.mute-container{
    transform:translateX(-300%);
}
.screen-share-container{
    transform:translateX(-450%);
}
.hangup{
    background-color: red;
}
.video-chat-options-container{
    width:60%;
    height:100%;
}
.video-chat-options{
    width: 100%;
    height:90%;
    gap:50px; 
    display:flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
}
.video-chat-user-details{
    width:90%;

    border-radius:5px;
    background-color: var(--color-lightest);
    backdrop-filter:blur(5px);
}
.video-chat-user-details h2{
    margin-bottom:20px;
    color:var(--text-color);
}
.video-chat-user-details-buttons{
    display:flex;
    width:100%;
    align-items: flex-end;
    justify-content: flex-end;
    gap:1%;
}
.video-chat-user-details-buttons button{
    position: relative;
    border:none;
    border-radius:5px;
    height:35px;
}

.connect-to{
    width:90%;
    height:50px;
    /* background-color: cornflowerblue; */
}
.connect-input{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
}
.connect-input input{
    width:50%;
    height:100%;
    padding:0;
    border:none;
    border-radius:2px;
    text-align: center;
    outline:none;
    background-color: var(--color-light);
    backdrop-filter: blur(10px);
    color:var(--text-color)
}
.video-chat-settings-container{
    position:absolute;
    bottom:0;
    right:0;
    width:50%;
    border-radius:5px;
    background-color: rgba(255, 255, 255, 0.472);
    backdrop-filter: blur(15px);
}

.video-chat-settings-container .button-text{
    max-height:0px;
    opacity:0;
    pointer-events:none;
    margin:0;
    transition:all 0.5s ease-in-out;
    text-align: center;
}
.video-chat-settings-container:hover .button-text{
    max-height:100px;
    opacity:1;
}
.device-settings{
    max-height:500px;
    display:grid;
    grid-auto-rows:3;
    gap:10px;
    justify-content: center;
    margin: 40px 0px;
    /* width:90%; */
}

.device-select{
    display:flex;
    gap:10px;
    justify-content: center;
    width:90%;
    position: relative;
}

.device-test{
    height:48px;
    width:70px;
    position: relative;
}

.device-test video{
    border-radius:5px;
    position:absolute;
    right:0;
    bottom:0;
    transition:all 0.2s ease-in-out;
}
.device-select video:hover{
    border-radius:5px;
    height:700%;
    width:700%;

}

.device-selector{
  background-color: rgba(194, 194, 194, 0.856);
  width:100%;
  border:none;
  border-radius: 2px;
  text-align: center;
  /* color:white; */
}

.expand-test{
    width:5%;
    height:40px;
    border:none;
    border-radius:5px;
    display:flex;
    align-items: center;
    justify-content: center; 
    margin-left:auto;
    margin-right:5px;
}

.active{
    transform:translateX(0%)!important;
    opacity:1;
    pointer-events: auto;
}

.clip{
              color: grey;
              position: absolute;
              top:5%;
              left:10%;
              stroke-dasharray: 50;
              transition: all 300ms ease-in-out;
}
.tick{
              color: green;

            top:0;
            left:0;
              stroke-dasharray: 50;
              transition: all 300ms ease-in-out;
}


.slide-fade-enter-active {
    transition: max-height .3s ease 0s, opacity .3s ease .3s,margin .3s ease 0s;
  }
  .slide-fade-leave-active {
    transition: max-height .3s ease .3s, opacity .3s ease .0s,margin .3s ease .3s;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    /* transform: translatey(10px); */
    opacity: 0;
    max-height: 0px;
    margin:0px 0px;
  }

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (max-width: 1024px) {
  
  .video-chat-container{
      display:none;
      position:absolute;
      background-color: azure;
      width:100%;
      height:90%; 
      z-index:1000;
  }
  .video-home{
      right:0%;
  }
  .video-away{
      width:100%;
      height:100%;
  }
  .video-chat-options-container{
      width:100%;
  }
  .video-chat-options{
        display:flex;
      align-items: center;

      flex-direction: column;
  }
  
}

@media (max-width: 700px) {
  
  .video-chat-settings-container{
      width:100%;
  }
  
}

@media (max-height: 400px) {
.video-chat-options{
    margin-top:10px;
}
  
.video-chat-settings-container .chat-test-title h1{
      font-size: 20px;
  }
  
}

</style>
<style lang="scss">
.video-call-stats{
position: absolute;
background-color: #fbfbfb;
border-radius:5px;

.video-state{
  background-repeat: no-repeat;
  background-size: 24px;
  border: none;
  height: 48px;
  border-radius: 8px;
  width: 48px;

&.remote-camera {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera-off' viewBox='0 0 24 24'%3E%3Cpath d='M 21 21 L 3 21 C 1.895 21 1 20.105 1 19 L 1 8 C 1 6.895 1.895 6 3 6 L 6.514 6 M 8.565 3 L 15 3 L 17 6 L 21 6 C 22.105 6 23 6.895 23 8 L 23 18.961 M 15.28 15.28 C 13.541 17.821 9.703 17.527 8.372 14.751 C 7.519 12.971 8.091 10.835 9.72 9.72 C 12.306 8.026 15.756 9.767 15.93 12.853 C 15.979 13.711 15.751 14.561 15.28 15.28 Z M 6.561 5.979 L 8.561 2.979 M 20.996 20.979 C 22.101 20.979 22.996 20.084 22.996 18.979'/%3E%3C/svg%3E%0A");
  background-position: center;
}

&.remote-camera-off {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M21 21H3a2 2 0 01-2-2V8a2 2 0 012-2h3m3-3h6l2 3h4a2 2 0 012 2v9.34m-7.72-2.06a4 4 0 11-5.56-5.56'/%3E%3C/svg%3E%0A");
  background-position: center;
}
&.remote-mic {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-on' viewBox='0 0 24 24'%3E%3Cpath d='M 9 3.561 L 9 12 C 9.002 14.309 11.452 15.971 13.473 14.853 C 14.902 14.063 14.971 12.779 14.983 12.106 M 14.971 12.131 L 15 4 C 15.002 1.691 12.504 0.245 10.503 1.397 C 9.757 1.827 9.231 2.556 9.06 3.4'/%3E%3Cpath d='M 17 16.95 C 13.229 20.799 6.705 19.123 5.257 13.932 C 5.082 13.303 4.995 12.653 5 12 L 5 10 M 19 10 L 19 12 C 19 12.412 18.905 14.838 17.019 16.971 M 12 19 L 12 23 M 8 23 L 16 23'/%3E%3C/svg%3E%0A");
    background-position: center;
  }

&.remote-mic-off {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M9 9v3a3 3 0 005.12 2.12M15 9.34V4a3 3 0 00-5.94-.6'/%3E%3Cpath d='M17 16.95A7 7 0 015 12v-2m14 0v2a7 7 0 01-.11 1.23M12 19v4M8 23h8'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
}
}

.video-call-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:8px;
}

.video-call-actions > :first-child {
    background-color:#ff1932;
    margin-left:5px;
    margin-right:auto;
    height:40px;
}


.video-action-button {
  background-repeat: no-repeat;
  background-size: 24px;
  border: none;
  height: 48px;
  box-shadow: var(--navigation-box-shadow);
  border-radius: 8px;
  min-width: 48px;
  cursor: pointer;
  outline: none;
  background-color: var(--button-bg);
  
  &.mic {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-on' viewBox='0 0 24 24'%3E%3Cpath d='M 9 3.561 L 9 12 C 9.002 14.309 11.452 15.971 13.473 14.853 C 14.902 14.063 14.971 12.779 14.983 12.106 M 14.971 12.131 L 15 4 C 15.002 1.691 12.504 0.245 10.503 1.397 C 9.757 1.827 9.231 2.556 9.06 3.4'/%3E%3Cpath d='M 17 16.95 C 13.229 20.799 6.705 19.123 5.257 13.932 C 5.082 13.303 4.995 12.653 5 12 L 5 10 M 19 10 L 19 12 C 19 12.412 18.905 14.838 17.019 16.971 M 12 19 L 12 23 M 8 23 L 16 23'/%3E%3C/svg%3E%0A");
    background-position: center;
  }

  &.mic-off {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M9 9v3a3 3 0 005.12 2.12M15 9.34V4a3 3 0 00-5.94-.6'/%3E%3Cpath d='M17 16.95A7 7 0 015 12v-2m14 0v2a7 7 0 01-.11 1.23M12 19v4M8 23h8'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
  
  &.camera {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera-off' viewBox='0 0 24 24'%3E %3Cpath d='M 21 21 L 3 21 C 1.895 21 1 20.105 1 19 L 1 8 C 1 6.895 1.895 6 3 6 L 6 6 M 8.108 3 L 15 3 L 17 6 L 21 6 C 22.105 6 23 6.895 23 8 L 23 18.894 M 15.28 15.28 C 13.541 17.821 9.703 17.527 8.372 14.751 C 7.519 12.971 8.091 10.835 9.72 9.72 C 10.456 9.227 11.334 8.992 12.218 9.051 C 15.296 9.256 16.997 12.717 15.28 15.28 Z'/%3E %3Cpath d='M 6.05 5.993 L 8.05 2.993'/%3E %3Cpath d='M 3.058 5.993 C 1.953 5.993 1.144 6.917 1.144 8.022' transform='matrix(-1, 0, 0, -1, 24.116, 26.986)'/%3E %3C/svg%3E%0A");
    background-position: center;
  }

  &.camera-off {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M21 21H3a2 2 0 01-2-2V8a2 2 0 012-2h3m3-3h6l2 3h4a2 2 0 012 2v9.34m-7.72-2.06a4 4 0 11-5.56-5.56'/%3E%3C/svg%3E%0A");
    background-position: center;
  }

  &.screen-share{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-screen-share' viewBox='0 0 24 24'%3E %3Cpath d='M 11.925 8.071 L 8.592 10.829 M 12.058 8.962 L 12.018 16.112 M 12.008 8.014 L 15.341 10.772 M 2.576 3.42 L 21.424 3.42 C 22.568 3.42 23.496 4.348 23.496 5.492 L 23.496 17.29 C 23.496 18.434 22.568 19.362 21.424 19.362 L 2.576 19.362 C 1.432 19.362 0.504 18.434 0.504 17.29 L 0.504 5.492 C 0.504 4.348 1.432 3.42 2.576 3.42 Z M 6.1 19.638 L 17.899 19.638 L 17.899 21.767 L 6.1 21.767 L 6.1 19.638 Z'/%3E %3C/svg%3E%0A");
    background-position: center;
  }
  
  &.maximize {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-maximize' viewBox='0 0 24 24'%3E%3Cpath d='M8 3H5a2 2 0 00-2 2v3m18 0V5a2 2 0 00-2-2h-3m0 18h3a2 2 0 002-2v-3M3 16v3a2 2 0 002 2h3'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
  
  &.endcall {
    color: #ffffff;
    width: auto;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    // background-size: 20px;
    // background-position: center left 12px;
  }
  


  
  &.magnifier {
    padding: 0 12px;
    display: flex;
    align-items: center;
    width: auto;
    flex-grow: 0;
    color: #2c303a;
    
    svg {
      width: 20px;
      flex-shrink: 0;
    }
    
    span {
      display: block;
      margin: 0 16px;
    }
  }
}
</style>