<template>
  <div class="addnote">
    <textarea
      class="note-text"
      v-model="note"
      :maxlength="350"
      v-on:focus="focuss = true"
      v-on:blur="blur()"
      :style="focuss ? { height: '150px' } : { height: '40px' }"
      placeholder="add note"
      ref="textArea"
    ></textarea>
    <div class="notearea-stuff">
      <div
        class="note-remaining"
        :style="
          (note.length || notedata) && note !== notedata
            ? { width: '60%' }
            : { width: '100%' }
        "
      >
        <h5>{{ note.length }} / 350</h5>
      </div>
      <button
        v-on:focus.prevent.stop
        v-show="(note.length || notedata) && note !== notedata"
        :style="{ width: '40%' }"
        class="save-note"
        v-on:click="addnote($event)"
      >
        {{ btntxt + " note" }}
      </button>
    </div>
  </div>
</template>
<script>

export default {
  props: ["itemId","notedata", "reset"],
  methods: {
    init() {
      if (this.notedata) {
        this.note = this.notedata;
      }
    },
    addnote(event) {
        event.preventDefault()
        event.stopPropagation()

        this.$refs.textArea.focus()
        // console.log("ADD")
      if (this.update) {
        this.$api.put(`/api/notes/update`, {
            itemId: this.itemId, 
            note: this.note,
          })
          .then((data) => {
            this.$emit("note", data.data);
            this.update = false;
          });
      } else if (this.delete) {
        this.$api.delete(`/api/notes/delete`, {
            data: {
              itemId: this.itemId,
            },
          })
          .then(() => {
            this.$emit("deleteNote");
            this.delete = false;
          });
      } else {
        this.$api.put(`/api/notes/add`, {
            itemId: this.itemId, 
            note: this.note,
          },{indicator:document.querySelector(".save-note")})
          .then((data) => {
            this.$emit("note", data.data);
          }).catch((error)=>console.log(error))
      }
    },
    blur(){
        if(!((this.note.length || this.notedata) && this.note !== this.notedata)){
            this.focuss = false
        }
    }
  },
  watch: {
    reset: function () {
      if (this.notedata) {
        this.note = this.notedata;
      } else {
        this.note = "";
      }
    },
    note: function () {
      if (this.notedata && this.note != this.notedata) {
        this.btntxt = "update";
        this.delete = false;
        this.update = true;
      }
      if (this.notedata && !this.note.length) {
        this.btntxt = "delete";
        this.update = false;
        this.delete = true;
      }
      if (!this.notedata) {
        this.btntxt = "save";
      }
    },
  },
  beforeMount() {
    this.init();
  },
  data() {
    return {
      focuss: false,
      note: "",
      btntxt: "save",
      update: false,
      delete: false,
    };
  },
};
</script>
<style scoped>
h5 {
  margin: 0px;
}
.note-text {
  vertical-align: bottom;
  border-radius: 2px 2px 0px 0px;
  margin: 0px;
  border: none;
  width: 98%;
  height: 40px;
  transition: all 0.2s ease-in-out;
  padding: 1%;
  outline: none;
  resize: none;
}
.note-remaining {
  border-radius: 0px 0px 5px 5px;
  vertical-align: top;
  height: 30px;
  display: inline-block;
  text-align: center;
  background-color: white;
  box-shadow: 0px 0px 20px -10px;
  transition: all 0.2s ease-in-out;
}
.save-note {
  font-weight: bold;
  color: white;
  border: none;
  vertical-align: top;
  height: 30px;
  display: inline-block;
  text-align: center;
  background-color: black;
  transition: all 0.2s ease-in-out;
}
.notearea-stuff {
  margin-top: 0;
}
.hashtag {
  background-color: red;
  color: #ffffff;
  padding: 0px 2px;
}
/* width */
::-webkit-scrollbar {
  height: 100%;
  width: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(46, 44, 44, 0.4);
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(46, 44, 44, 0.8);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 0, 0);
  transition: 0.5s ease;
}

.loading{
  background-color: #4285f4;
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes color {
  0% {
    background-color: #222;
  }
  50% {
    background-color: #4285f4;
  }
  100% {
    background-color: #222;
  }
}
</style>