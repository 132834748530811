export default {
    bind: function (el, binding, vNode) {
        const handler = (e) => {
            // console.log(binding.arg)
            // console.log(e.target.parentNode)
            // console.log(document.getElementById(binding.arg).contains(e.target))

            if (!el.contains(e.target) && el !== e.target && !document.getElementById(binding.arg).contains(e.target) ) {
                //and here is you toggle var. thats it
                vNode.context[binding.expression] = false
                if (vNode.children[0].tag === 'svg'){
                    vNode.children[0].elm.classList.remove('opened');vNode.children[0].elm.setAttribute('aria-expanded', vNode.children[0].elm.classList.contains('opened'))
                }
            }
        }
        el.out = handler
        document.addEventListener('click', handler)
    },

    unbind: function (el, binding) {
        document.removeEventListener('click', el.out)
        el.out = null
    }
}
