var state = {
  is_authed: false
};
var getters = {
  is_authed(state) {
    return state.is_authed;
  }
};
var actions = {};
var mutations = {
  change(state, payload) {
    state.is_authed = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
