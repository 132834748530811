<template>
  <div class="flow-externals">
    <p>
      Shared with you by {{persons[0].fullname}}
    </p>
  </div>
</template>

<script>
export default {
  props: ["Flow"],
  mounted() {
    this.init();
  },
  data() {
    return {
      show: false,
      persons: [],
      query: null,
      a: [],
    };
  },
  watch: {
    query: function () {
      // console.log(this.query);
      this.query == "" ? (this.a = []) : this.search();
    },
  },
  methods: {
    init() {
    this.$apiShared.get(`/api/sharing/flows/${this.Flow}/sharing`).then((data) => {
        // console.log(data.data);
        this.persons = data.data;
      });
    },
  },
};
</script>
