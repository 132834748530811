<template>
    <div draggable="true" v-on:drag.stop.prevent v-on:dragstart.stop.prevent>
      <div class="tabs-container">
                <div class="tabs" v-if="note || files">
            <button v-if="note" v-on:click="showNoteAttachment = !showNoteAttachment">Note</button>
            <button v-if="files" v-on:click="showFilesAttachment = !showFilesAttachment">Files</button>
            <!-- <button>Links</button> -->
        </div>
      </div>

        <!-- <div class="component-area">
            <transition name="fadea" mode="in-out">
                <NoteArea v-if="note && show" v-bind:notestuff="note" class="note-area-component" v-enter key="notes"></NoteArea>
                <div v-else key="hello">hello</div>
            </transition>
        </div> -->
        <transition-group name="fade-item-attachment" mode="out-in">
          <div class="note-component" v-if="note && showNoteAttachment" key="notes">
            <div  class="component-title"><h2>Note</h2></div>
              <NoteArea v-bind:notestuff="note" class="note-area-component" v-enter key="notes"></NoteArea>
          </div>
          <div class="files-component" key="files" v-if="files && showFilesAttachment">
            <div  class="component-title"><h2>Files</h2></div>
              <FilesArea class="files-area-component" :files="Files" :itemId="$props.itemId" :shared="shared" :del="del" v-on:delete="$emit('deleteFile',$event)"></FilesArea>
          </div>
        </transition-group>
    </div>  
</template>

<script>
import FilesArea from '../components/FilesArea.vue'
import LinkArea from '../components/Linkarea.vue'
import NoteArea from '../components/Notearea.vue'
import TransitionExpand from '../transitions/TransitionExpand.vue'
export default {
    directives: {
  enter: {
    // directive definition
    bind: function (el) {
      // console.log(el)
      // console.log(el.offsetHeight)
    //   el.target.offsetHeight
    },
    inserted: function (el) {
      // console.log(el)
      // console.log(el.OffsetHeight)
    //   el.target.offsetHeight
    },
    unbind: function (el) {
      // console.log(el)
      // console.log(el.offsetHeight)
    //   el.target.offsetHeight
    }
  }
},
    components:{
        NoteArea,
        LinkArea,
        TransitionExpand,
        FilesArea
    },
    props:["itemId","note","showNote","files","showFiles","shared","del"],
    data(){
        return {
            notes:this.$props.note,
            showNoteAttachment:false,
            showFilesAttachment:false,
            // Files:this.$props.files
        }
    },
    computed:{
      Files:function(){
        return this.$props.files
      }
    },
    mounted(){
        this.show=this.showNote
    },
}
</script>

<style>
.tabs-container{
  display:flex;
  justify-content: center;

}

 .tabs{
     height:40px;
     display:flex;
     justify-content:flex-start;
     align-items: center;
     gap:5px;
     width:95%;
 }
 .tabs button{
  width:20%;
  height:70%;
  padding:0;
  border:0;
  border-radius:2px;
  background-color: black;
  color:var(--text-color-white);
}


.component-area{
  height:auto;
}

.component-title{
  padding:5%;
  max-height: 200px;
  transition: all 0.5s;
  /* position:absolute; */
}
.component-title h2{
  transition: color 0.2s ease-in-out;
  margin:0px;
  justify-content: flex-end;
  color: hsla(var(--light-text-hsl-hue),var(--light-text-hsl-saturation),var(--light-text-hsl-lightness), 0.4);
  background-color: hsla(0, 0%, 30%, 0.2);
  border-radius:5px;
  padding-right:2%;
}
.note-area-component,.files-area-component{
    padding:2%; 
    transition: all 0.5s;
    max-height:600px;
}


.fade-item-attachment-enter-active {
  transition: max-height 0.5s ease-in-out,opacity 0.5s 0.5s ease-in-out;
  max-height:600px;
}

.fade-item-attachment-leave-active{
    transition: opacity 0.5s ease-in-out,max-height 0.5s 0.5s ease-in-out;
   max-height:600px; 
}

.fade-item-attachment-enter,
.fade-item-attachment-leave-to
{
  opacity: 0;
  max-height: 0px;
}


</style>