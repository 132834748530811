<template>
  <div class="default-view">
    <h1>People</h1>
    <div
      class="person-grid"
      :style="show ? { height: '49%' } : { height: '80%' }"
    >
      <template v-for="person in people">
        <person v-bind:key="person.id" v-bind:person="person">
        </person>
      </template>
    </div>
    <div
      class="requests-container"
      :style="show ? { height: '35%' } : { height: '5%' }"
      :class="styleFunction"
    >
      <div class="requests-container-div">
        <h2 :style="show ? {} : { fontSize: '1.4em' }">Requests</h2>
        <h2 :style="show ? {opacity:0,width:0} : { opacity:1,fontSize: '1.4em',fontWeight:'600',width:'50px' }">{{Requests.length}}</h2>
        <button
          v-if="Requests.length"
          v-on:click="show = !show"
          :class="show ? 'exit' : 'up-svg'"
        ></button>
      </div>
      <div class="nothing" v-if="errors.requests">
          <h1 style="color:#FEE715FF">Seems like there is an error on our end</h1>
      </div>
      <div class="request-grid" :style="show ? { height: '70%' } : { height: '0%' }">
        <template v-for="(person,index) in Requests">
          <person v-bind:key="person.id" v-bind:person="person">
            <template #inline>
                <button v-on:click="acceptRequest(person.user_id, index)" class="accept">
                  <h5>Accept</h5></button
                ><button v-on:click="declineRequest(person.user_id, index)" class="exit"></button>
            </template>
          </person>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Person from "../../../GlobalComponents/person.vue";
export default {
  props: ["people", "requests","errors"],
  components: {
    Person,
  },
  computed:{
    styleFunction:function(){
        var arr = []
        if(this.show)
            arr.push('requests-container-active')
        if(this.Requests.length)
            arr.push('requests-container-pulse')
        return arr
    },
    Requests:function(){
        // console.log(this.requests)
        return this.requests
    }
  },
  data() {
    return {
      show: false,
    };
  },
  methods:{
    acceptRequest(accept_ID, index) {
      this.$people.peopleApiRequests
        .post("/requests/accept-request", {
          accept_from: accept_ID,
        })
        .then(() => {
             this.Requests.splice(index,1)
             if (!this.Requests.length) this.show = false
             this.$emit("update")
        })
        .catch(() => {
          const newData = this.people[index];
          newData.status = "error";
          this.$set(this.people, index, newData);
        });
    },
    declineRequest(decline_ID, index){
        // console.log(decline_ID,index)
      this.$people.peopleApiRequests
        .delete("/requests/decline-request", {data:{
          decline_from: decline_ID,
        }})
        .then(() => {
             this.Requests.splice(index,1)
             if (!this.Requests.length) this.show = false
        })
        .catch(() => {
          const newData = this.people[index];
          newData.status = "error";
          this.$set(this.people, index, newData);
        });
    }
  }
};
</script>

<style scoped>
h1 {
  justify-content: flex-end;
  width: 95%;
}
.default-view {
  height: 100%;
  display:flex;
  flex-direction: column;
}
.requests-container {
  border-radius: 10px 0px 0px 0px;
  transition: all 0.5s;
  margin-top:auto;
}

.requests-container {
  background: linear-gradient(10deg,transparent,#88888816,#3a3a3a39,#2888a3);
  background-color:transparent;
  backdrop-filter: blur(0px);
  background-size: 240% 240%;
  
}

.requests-container-pulse{
  animation: gradient-animation 10s ease infinite;
}

.requests-container-active{
  background-color:rgba(7, 7, 7, 0.305);
  /* backdrop-filter: blur(15px); */
  animation: blur-in 400ms ease-in 500ms forwards; /* Important */
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.requests-container-div {
  /* background-color: white; */
  display: flex;
  gap: 2em;
  align-items: center;
  justify-content: flex-end;
  height: auto;


  /* justify-content: flex-end; */
}
.requests-container-div button {
  /* background-color: white; */
  height: 25px;
  width: 25px;
  margin-right: 50px;
  /* background-color: transparent; */
}
.requests-container-div h2 {
  transition: all 0.5s;
  margin: 0;
  color: var(--text-color-white);
  /* margin-left:auto; */
}
.request-grid {
  display: grid;
  gap: 1rem;
  grid-auto-columns: minmax(300px, 400px);
  grid-template-rows: repeat(1, minmax(200px, 250px));
  grid-auto-flow: column;
  margin: 10px;
  padding-bottom:10px;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 70%;
  transition: all 0.5s;
}
.person-grid {
  /* height:49%; */
  transition: all 0.5s;
}
</style>