const formatBytes = (bytes) =>{
    let i = ~~(Math.log2(bytes)/10);
    let j = (bytes/Math.pow(1024,i))
    return (j % 1 ? j.toFixed(2) + " " : j) + ("KMGTPEZY"[i-1]||" ") + "B"
}

const arraysAreEqual = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}


export default {
    install(Vue){
        Vue.prototype.$utils = {
            formatBytes,
            arraysAreEqual
        };
    }
}