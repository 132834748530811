import axios from 'axios';

const fileStoreApi = axios.create({
    baseURL: `${process.env.VUE_APP_FILES_API}`
  })

  const fileStoreApiShared = axios.create({
    baseURL: `${process.env.VUE_APP_FILES_SHARING_API}`
  })





export default {fileStoreApi,fileStoreApiShared}