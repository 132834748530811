<template>
  <div class="add-cube-container">
    <div class="line">
      <h3>Add a cube named</h3>
      <input v-model="cubeName" placeholder="name" v-on:keyup.enter="$refs.addCube.click()">
      <h3 v-if="selected">,</h3>
      <h3 v-else>.</h3>
    </div>
    <div class="line" v-if="Cubes.length" :class="selected ? {  } : 'translucent'">
      <h3>after</h3>
      <select name="" id="" v-model="selected">
        <option>{{ null }}</option>
        <template v-for="cubeOption in Cubes">
          <option v-bind:key="cubeOption.title + cubeOption.sort + 'option'" :value="cubeOption.cube_id">
            {{ cubeOption.title }}
          </option>
        </template>
      </select>
      <h3 v-if="selected">.</h3>
    </div>
    <div class="add-button">
        <button v-on:click="addCube()" :disabled="!cubeName" ref="addCube">Add Cube</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["Cubes"],
  data() {
    return {
      selected: null,
      cubeName:null
    };
  },
  methods:{
      addCube(){
        // console.log(this.selected)
          let add = {
                        name: this.cubeName,
                        position: this.selected ? this.Cubes[this.Cubes.findIndex(cube => cube.cube_id == this.selected)].sort : this.Cubes.length+1
                    }
          this.$emit('addCube',add)
          this.selected=null
          this.cubeName=null
      }
  }
};
</script>

<style>
select:hover{
    opacity:1 !important;
}
h3 {
  width: 100%;
  margin:0;
}
.add-cube-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color:var(--text-color);

}
.line {
  display: flex;
  align-items: center;
  justify-content: center;
  height:fit-content;
  width:fit-content;
  margin:1%;

}
.line h3{
  margin:0;
  margin-right:1.5%;
}
.line input{
  text-align:center;
  font-size: 1.3rem;
  border:none;
  border-bottom: 2px solid black;
  background-color: transparent;
  outline:none;
  color:var(--text-color);
}
.line select{
  text-align:center;
  font-size: 1rem;
  border:none;
  height:100%;
  background-color: rgb(231, 231, 231);
  outline:none;
  border-radius :4px;
  margin-left:1%;
}
.line option{
  text-align:center;
  border:none;
  background-color: rgb(255, 255, 255);
  outline:none;
  border-radius :4px;
}
.add-button{
  /* margin-top:auto; */
  position:absolute;
  bottom:20%;
  width:40%;
  height:10%;
  text-align:center;
  border:none;
  /* background-color: rgb(255, 255, 255); */
  outline:none;
  border-radius :4px;
  
}
.add-button button{
  transition: all 0.2s ease-in-out;
  width:100%;
  height:100%;
  text-align:center;
  border:none;
  background-color: var(--color-contrast);
  color:var(--text-color-contrast);
  outline:none;
  border-radius :4px;
  align-self: flex-end ;
}
.add-button button:disabled{
  opacity:0.5;
}

.translucent > *{
  opacity: 0.5;
}
.line select:hover {
  opacity: 1 !important;
}
</style>