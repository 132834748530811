<template>
<div class="document-viewer" v-if="$viewFile.file">
  <button class="close" v-on:click="closeViewer()">X</button>
  <iframe height="100%" width=100% :src="'https://docs.cubeitdone.com/web/viewer.html' +'?file=' + this.$viewFile.file" loading="lazy" ></iframe>
</div>
</template>

<script>

var corsProxy = "http://192.168.0.180:8080/"
export default {
  name: 'PDFJSViewer',
 data(){
     return {
         fileName:undefined,
         path:'http://localhost:8080/lib/web/viewer.html'
     }
 },
  computed:{ 
    getFilePath: function () {
      if(this.fileName!=='' || this.$viewFile.file !==''){
          return 'lib/web/viewer.html' +'?file=' + corsProxy + this.$viewFile.file
      }
      return this.path 
    }
  },
  methods:{
    closeViewer(){
      this.$viewFile.file=null
    }
  }
}
</script>
<style scoped>
.document-viewer{
    background-color: rgba(0, 0, 0, 0.736);
    position:absolute;
    height:100vh;
    width:100vw;
    z-index:99999;
    backdrop-filter: blur(10px);
}
iframe{
    border-width:0px;
}
.close{
  position: inherit;
  height:32px;
  width:50px;
}
</style>