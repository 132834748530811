<template>
  <div class="not-found-container">
      <div class="not-found">
            <h1>Hmmmmmm.......404?</h1>
            <h2>We dont seem to be able to find this page.</h2>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.not-found-container {
    height:90vh;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.not-found h2{
    color:white;
}
.not-found{
    backdrop-filter: blur(5px);
    background: linear-gradient(47deg, #a34bcf7a, #4b79cf, #4bc4cf62);
    background-size: 600% 600%;
    -webkit-animation: AnimationName 15s ease infinite;
    -moz-animation: AnimationName 15s ease infinite;
    -o-animation: AnimationName 15s ease infinite;
    animation: AnimationName 15s ease infinite;
    border-radius:2px;
    box-shadow: 0 0 10px -5px;
    width:50%;
    height:25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@-webkit-keyframes AnimationName {
    0%{background-position:0% 74%}
    50%{background-position:100% 27%}
    100%{background-position:0% 74%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 74%}
    50%{background-position:100% 27%}
    100%{background-position:0% 74%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 74%}
    50%{background-position:100% 27%}
    100%{background-position:0% 74%}
}
@keyframes AnimationName {
    0%{background-position:0% 74%}
    50%{background-position:100% 27%}
    100%{background-position:0% 74%}
}
</style>