<template>
  <div class="quick-links-container">
      <div class="quick-links" >
          <div class="link-info" v-if="links.length === 0">
              <h5>Add links you frequently use, here.</h5>
          </div>
          <template v-for="(link,index) in links">
              <div class="link" v-bind:key="index" v-on:click.stop="openLink(link.link)" >
                  <button v-if="Edit" class="delete-link exit" v-on:click.stop="deleteQuickLink(link,index)"></button>
                  <div class="link-favicon-container">
                        <img class="link-favicon" :src="link.src" alt="">
                    </div>
                  <div class="link-content">
                      <h4>{{link.link_title}}</h4>
                      <h5>{{link.link_description}}</h5>
                  </div>
              </div>
          </template>
          <!-- <SwipeList
	ref="links"
	:disabled="!enabled"
	:items="links"
	item-key="id"
>
    <template v-slot="{ item, index }">
              <div class="link" v-bind:key="index" v-on:click.stop="openLink(link.link)" >
                  <div class="link-favicon-container">
                        <img class="link-favicon" :src="item.src" alt="">
                    </div>
                  <div class="link-content">
                      <h4>{{item.title}}</h4>
                      <h5>{{item.link_description}}</h5>
                  </div>
              </div>
    </template>
    
    <template v-slot:right="{ item, close }">
		<div class="swipeout-action" title="remove" v-on:click="remove(item)">
			<button v-on:click="removeQuickLink()">Del</button>
		</div>
		<div class="swipeout-action" title="remove" v-on:click="remove(item)">
			<button>edit</button>
		</div>
		<div class="swipeout-action" title="remove" v-on:click="close">
			<button>close</button>
		</div>
	</template>
</SwipeList> -->
          <template v-if="links.length < 5">
            <div class="link" v-on:click.stop="addLinkActive = true">
                <transition-group tag="div" class="slides" :name="back? 'slideback' : 'slide'" v-if="addLinkActive">
                <!-- <div v-if="addLinkActive" class="slides" :name="back? 'slideback' : 'slide'"> -->
                    <div class="add-link-content" v-if="addLinkPage == 0" key="page1">
                        <button v-on:click.stop="addLinkActive = false" class="exit"></button>
                        <div class="add-link-input">
                            <input type="text" placeholder="Link" v-model="toSave.link" class="link-input">
                        </div>
                        <button v-on:click="back = false;addLinkPage = 1" :disabled="!toSave.link">></button>
                    </div>
                    <div class="add-link-content" v-if="addLinkPage == 1" key="page2">
                        <button v-on:click="back = true;addLinkPage = 0">&#60;</button>
                        <div class="add-link-input">
                            <input type="text" placeholder="Title" v-model="toSave.link_title">
                            <input type="text" placeholder="Description" v-model="toSave.link_description">
                        </div>
                        <button v-on:click.stop="addQuickLink()" :disabled="!toSave.link_title">+</button>
                    </div>
                <!-- </div> -->
                </transition-group>
                <div class="add-link" v-else>
                    +
               </div>
            </div>
          </template>
      </div>
  </div>
</template>

<script>
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
export default {
    components:{
        SwipeList,
        SwipeOut
    },
    props:["Edit"],
    data(){
        return{
            enabled: true,
            addLinkActive:false,
            back: false,
            currentIndex: 0,
            addLinkPage:0,
            toSave:{
                link:null,
                link_title:null,
                link_description:null,
                src:null
            },
            links:[
                // {
                //     link:"https://www.amazon.com",
                //     link_title:"Amazon",
                //     link_description:"Stuff",
                //     src:null
                // },
                // {
                //     link:"https://microsoft.com",
                //     link_title:"Microsoft",
                //     link_description:"Stuff",
                //     src:null
                // },
                // {
                //     link:"https://www.dell.com/support/order-status/en-in/order-collection?v=pZSnv67J7DAGiKKi2XnDGGcddPhK0bRpgHp8q5Y0bxGcDh1ls%2bnlLSH1Nim5izUlhWfxe8S6gtaCq%2bCo%2fm2bX69nYGQyjJanU5R9uBk0DbDJNC5yY4Stt9b8pvodaVyj&ref=secUrlPurchase&lwp=rt&t=e",
                //     link_title:"DELL-01",
                //     link_description:"Dell rack server",
                //     src:null
                // },
                // {
                //     link:"https://www.dell.com/support/order-status/en-in/order-collection?v=pZSnv67J7DAGiKKi2XnDGGcddPhK0bRpgHp8q5Y0bxGcDh1ls%2bnlLSH1Nim5izUlhWfxe8S6gtaCq%2bCo%2fm2bX69nYGQyjJanU5R9uBk0DbDJNC5yY4Stt9b8pvodaVyj&ref=secUrlPurchase&lwp=rt&t=e",
                //     link_title:"DELL-02",
                //     link_description:"Dell rack server",
                //     src:null
                // },
                // {
                //     link:"https://www.dell.com/support/order-status/en-in/order-collection?v=pZSnv67J7DAGiKKi2XnDGGcddPhK0bRpgHp8q5Y0bxGcDh1ls%2bnlLSH1Nim5izUlhWfxe8S6gtaCq%2bCo%2fm2bX69nYGQyjJanU5R9uBk0DbDJNC5yY4Stt9b8pvodaVyj&ref=secUrlPurchase&lwp=rt&t=e",
                //     link_title:"DELL-02",
                //     link_description:"Dell rack server",
                //     src:null
                // },
            ]
        }
    },
    async mounted(){
        this.$api.get(`${process.env.VUE_APP_QUICK_LINK_API}/api/quicklinks`).then((data) => {var links = data.data.links;
        links.forEach(link=>link.src = null)
        this.links = links || [];
        this.getFavicon()})
        // this.getFavicon()

    },
    methods:{
        reset(){
            this.addLinkActive=false
            this.back=false
            this.currentIndex=0
            this.addLinkPage=0
            this.toSave={
                link:null,
                link_title:null,
                link_description:null,
                src:null
            }
        },
        getFavicon(){
            this.links.forEach( link => {
                //  this.$axios.get(`${process.env.VUE_APP_FAVICON_API}/allicons.json?url=${link.link}`).then((data) => {link.src = data.data.icons[0]?.url})
                 this.$axios.get(`${process.env.VUE_APP_FAVICON_API}/allicons.json?url=${link.link}`).then((data) => {link.src = data.data.icons[0]?.url})
            })

        },
        openLink(link){
            if(/^(http|https):/igm.exec(link)){
                window.open(link,'_blank').focus()
            }
            else{
                window.open("//"+link,'_blank').focus()
            }
        },
        addQuickLink(){
            this.$api.post(`${process.env.VUE_APP_QUICK_LINK_API}/api/quicklinks`,{
                link_title:this.toSave.link_title,
                link_description:this.toSave.link_description,
                link:this.toSave.link
            }).then((data) => {
                // console.log(data)
                var save = this.toSave
                save.q_id = data.data
                this.links.push(save)
                this.reset()
            })
        },
        deleteQuickLink(link,index){
            this.$api.delete(`${process.env.VUE_APP_QUICK_LINK_API}/api/quicklinks`,{data:{
                link}
            }).then(() => {this.links.splice(index,1)})
            
        }
    }
}
</script>

<style>
.quick-links-container{
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
}

.quick-links{
    height:90%;
    width:90%;
    display:grid;
    grid-template-rows: repeat(5,1fr);
    gap:5%;


}
.link-info{
    color:var(--text-color);
    transition:color 1s ease-in-out;
    max-height:60px;
}
.link{
    border-radius:2px;
    background-color: rgba(167, 167, 167, 0.438);
    min-height:60px;
    display:flex;
    justify-content: center;
    align-items: center;
    overflow-y:hidden ;
    position: relative;
}
.delete-link{
    position: absolute;
    top:0px;
    left:0;
    background-color: rgb(197, 55, 55);
    color:white;
    width:10%;
    aspect-ratio: 1 / 1;
    border:none;
    border-radius: inherit;
    
}
.link-favicon-container{
    width:20%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;

}
.link-favicon{
    height:32px;
}
.link-content{
    width:80%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
        overflow-y:hidden ;
}
.link-content h4, .link-content h5{
    margin:0;
    padding-right:5%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:90%;
    text-align: right;
}

.add-link{
    color:var(--text-color);
    transition:color 1s ease-in-out;
}

.slides{
    height:100%;
    width:100%;
    overflow: hidden;
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center; 
}

.slides *{
    outline:none;
    border:none;
}
.add-link-content{
    display: flex;
    justify-content: center;
    overflow-y:hidden ;
    position: absolute;
    width:100%;
    height:100%;
}

.add-link-content .link-input{
    height:100%;
}

.add-link-content .exit{
    background-color:rgb(197, 55, 55);
}

.add-link-content button{
    color: white;
    background-color: black;
    transition: all 0.2s ease-in-out;
}

.add-link-input{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10%;


}

.add-link-input > input{
    padding:0;
    text-align: center;
    width:100%;

    height:50%;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s ease-in-out;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 0.5s ease-in-out;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}


.swipeout-list{
    display:grid;
    grid-template-rows: repeat(5,1fr);
    gap:5%;
    height:100%;
}
.swipeout {
	position: relative;
	overflow: hidden;
    height:fit-content;
}

.swipeout-list{
    height:fit-content;
}

.swipeout-left,.swipeout-right {
	position: absolute;
	display: flex;
	z-index: 1;
}
.swipeout-left button,.swipeout-right button {
	height: 60px;
    outline:none;
    border:none;
}

.swipeout-left {
	left: 0;
	transform: translateX(-100.5%);
    
}

.swipeout-right {
	right: 0;
	transform: translateX(100.5%);
}

.swipeout-content,
.swipeout-action {
	transition: transform .2s;
	will-change: transform;
}

.swipeout.swipeout--no-transition .swipeout-content,
.swipeout.swipeout--no-transition .swipeout-action {
  transition: none !important;
}

.swipeout-content {
	width: 100%;
}

.swipeout-non-selectable {
	user-select: none !important;
}

.swipeout-no-pointer-events {
	pointer-events: none !important;
}







</style>