<template>
  <div class="flow-externals">
    <button v-on:click="show = !show">Share</button>
    <p>
      Shared with {{ persons.length }}
      {{ persons.length == 1 ? "person" : "people" }}
    </p>
    <pop-up v-if="show" v-bind:show.sync="show" v-on:show="show = $event">
      <div class="share-view">
        <h3>Share with</h3>
        <p class="with">
          Shared with {{ persons.length }}
          {{ persons.length == 1 ? "person" : "people" }}
        </p>
        <div class="input-container">
          <input
            type="search"
            v-model="query"
            placeholder="Search for people you know"
          />
          <div class="search-values-container" v-if="a.length > 0">
            <div class="table-container">
              <table>
                <tbody>
                  <tr v-for="(person, index) in a" v-bind:key="'p' + index">
                    <td>
                      <div>
                        <h5>{{ person.fullname }}</h5>
                        <p>{{ person.username }}</p>
                      </div>
                    </td>
                    <td>
                      <button
                        class="add"
                        v-on:click="addShare(person, Flow, index)"
                      ></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="shared-with">
          <div v-if="!persons.length" class="nothing">
            <h3>Looks like you dont share this flow with anyone.</h3>
          </div>
          <div class="table-container" v-else>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>change</th>
                  <th>remove</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(person, index) in persons" v-bind:key="index">
                  <td>
                    <div>
                      <h5>{{ person.fullname }}</h5>
                      <p>{{ person.username }}</p>
                    </div>
                  </td>
                  <td>
                    <label class="toggleButton">
                      <input type="checkbox" :checked="person.write" v-on:click="changePermissions(person,Flow,index,!person.write,person.delete)"/>
                      <div class="check">
                        <svg viewBox="0 0 44 44">
                          <path
                            d="M14,24 L21,31 L39.7428882,11.5937758 "
                            transform="translate(-2.000000, -2.000000)"
                          ></path>
                        </svg>
                      </div>
                    </label>
                  </td>
                  <td>
                    <label class="toggleButton">
                      <input type="checkbox" :checked="person.delete" v-on:click="changePermissions(person,Flow,index,person.write,!person.delete)"/>
                      <div class="check">
                        <svg viewBox="0 0 44 44">
                          <path
                            d="M14,24 L21,31 L39.7428882,11.5937758"
                            transform="translate(-2.000000, -2.000000)"
                          ></path>
                        </svg>
                      </div>
                    </label>
                  </td>
                  <td>
                    <div class="nothing">
                      <button
                        class="exit"
                        style="height: 20px"
                        v-on:click="removeShare(person.user_id, Flow, index)"
                      ></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import PopUp from "../../components/Reusealbe/PopUp.vue";
export default {
  components: { PopUp },
  props: ["Flow"],
  mounted() {
    this.init();
  },
  data() {
    return {
      show: false,
      persons: [],
      query: null,
      a: [],
    };
  },
  watch: {
    query: function () {
      // console.log(this.query);
      this.query == "" ? (this.a = []) : this.search();
    },
  },
  methods: {
    init() {
      this.$api.get(`/api/flows/${this.Flow}/sharing`).then((data) => {
        // console.log(data.data);
        this.persons = data.data;
      });
    },
    search() {
      this.$people.peopleApiSearch
        .get("known?&q=" + this.query)
        .then((data) => {
          var people = data.data.filter((object1) => {
            return !this.persons.some((object2) => {
              return object1.user_id == object2.user_id;
            });
          });
          this.a = people;
        });
    },
    addShare(person, flow_id, index) {
      this.$api
        .post("/api/flows/addshare", {
          share_to_id: person.user_id,
          flow_id,
        })
        .then((data) => {
          // console.log(data);
          this.a.splice(index, 1);
          person.write=false
          person.delete=false
          this.persons.push(person);
        });
    },
    removeShare(share_to_id, flow_id, index) {
      this.$api
        .delete("/api/flows/removeshare", {
          data: {
            share_to_id,
            flow_id,
          },
        })
        .then((data) => {
          // console.log(data);
          this.persons.splice(index, 1);
        });
    },
    changePermissions(person, flow_id, index,write,del){
        this.$api.post("/api/flows/permission",{
          share_to_id: person.user_id,
          flow_id,
          write: write,
          delete: del
        })
        .then(()=>{
            // console.log(person, flow_id, index,write,del,this.persons)
            this.persons[index].write = write
            this.persons[index].delete = del
        })
    },
  },
};
</script>

<style>
.flow-externals {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.flow-externals > p {
  margin: 0;
  width: auto;
  color: #ffffff;
}

.share-view {
  background-color: rgba(2, 3, 3, 0.748);
  height: 90%;
  width: 30em;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: space-around;
  justify-content: space-around;
}
.input-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  position: relative;
}
.input-container input {
  height: 50%;
  width: 90%;
  border: none;
  border-radius: 5px;
}
.search-values-container {
  position: absolute;
  max-height: 500px;
  min-height: 50px;
  width: 90%;
  top: 80%;
  background-color: rgb(64, 64, 64);
  z-index: 1000;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0px 0px 100px rgb(0, 0, 0);
}

.search-values-container button {
  height: 50%;
  width: 100%;
}

.search-values-container .table-container {
  width: 100%;
  z-index: 300;
  overflow-y: hidden;
}

.nothing h3 {
  font-size: 1.2rem;
  transition: color 1s ease-in-out;
}

.with {
  color: rgba(255, 255, 255, 0.346);
  text-align: right;
  width: 90%;
}

.shared-with {
  height: 60%;
}

.table-container {
  /* border-collapse: collapse; */
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}
.share-view table {
  /* border-collapse: collapse; */
  width: 95%;
  height: 100%;
  margin: auto;
  color: white;
  position: relative;
}
.share-view thead {
  position: sticky;
  top: 0px;
  z-index: 500;
}

.share-view thead th {
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid rgba(167, 167, 167, 0.209);
  color: rgb(147, 147, 147);
  backdrop-filter: blur(5px);
}

.share-view td {
  padding: 8px;
}

.share-view thead th {
  /* border: 1px solid #dddddd; */
  text-align: center;
  padding: 8px;
}

.share-view tr:nth-child(even) > td {
  border-radius: 5px;
  background-color: #2f2f2f46;
}
</style>
.<style lang="scss" scoped>
$color: rgb(238, 238, 238);
$width: 24px;
$height: 24px;

.toggleButton {
  cursor: pointer;
  display: block;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transition: transform 0.14s ease;
  height: 100%;
  width: 100%;
  &:active {
    transform: rotateX(30deg);
  }
  input {
    display: none;
    & + .check {
      // border: 3px solid rgba($color, .2);
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      svg {
        fill: none;
        stroke-width: 3.6;
        stroke: $color;
        stroke-linecap: round;
        stroke-linejoin: round;
        width: $width;
        height: $height;
        display: block;
        // position: absolute;
        z-index: 1;
        stroke-dashoffset: 162.6 - 38;
        stroke-dasharray: 0 162.6 133 (162.6 - 133);
        transition: all 0.4s ease 0s;
      }
      &:before,
      &:after {
        content: "";
        width: 3px;
        height: 16px;
        background: $color;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 5px;
      }
      &:before {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
        animation: bounceInBefore 0.3s linear forwards 0.3s;
      }
      &:after {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
        animation: bounceInAfter 0.3s linear forwards 0.3s;
      }
    }
    &:checked + div {
      svg {
        stroke-dashoffset: 162.6;
        stroke-dasharray: 0 162.6 28 (162.6 - 28);
        transition: all 0.4s ease 0.2s;
        width: $width;
        height: $height;
        stroke: green;
      }
      &:before {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
        animation: bounceInBeforeDont 0.3s linear forwards 0s;
      }
      &:after {
        opacity: 0;
        transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
        animation: bounceInAfterDont 0.3s linear forwards 0s;
      }
    }
  }
}

@keyframes bounceInBefore {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
  }
  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes bounceInAfter {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
  }
  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes bounceInBeforeDont {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes bounceInAfterDont {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }
}
</style>