<template>
  <div>
      <div class="edit-cube">
          <input class="edit-cube-input" type="text" placeholder="Change cube name" v-model="newTitle">
      </div>
      <div class="save-cube">
          <button v-on:click="editCube()" :disabled="!newTitle || newTitle == '' || newTitle == cube.title">Save</button>
      </div>
  </div>
</template>

<script>
export default {
    props:['cube'],
    data(){
        return{
            newTitle:null
        }
    },
    methods:{
        editCube(){
            this.$api.put(`/api/cubes/update`, {
            id: this.cube.cube_id,
            title: this.newTitle
          }).then(()=>{
              this.$emit('title',this.newTitle)
          })
        }
    }
}
</script>

<style>
.edit-cube{
    height:30px;
    margin-bottom:5px;
}
.edit-cube-input{
    text-align: center;
    height:100%;
    width:100%;
    border:0;
    padding:0;
    border-radius:2px;
    outline:none;
    
}
.save-cube{
    height:30px;
}
.save-cube button{
    height:100%;
    width:100%;
    color:white;
    background-color:black;
    border:none;
    border-radius:5px;
}
</style>