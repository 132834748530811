<template>
  <div class="add-attachemnt">
<transition name="fade-attachment" mode="out-in">
    <div class="files-drop-zone" id="drop_zone" v-on:drop="dropHandler($event)" v-on:dragover="dragOverHandler($event);" v-on:dragleave="dragLeaveHandler($event);" v-if="!files.length" key="add-files">
      <h4>Drag and drop files here to add attachments.</h4>
    </div>
    <div class="file-list" v-else key="files">
        <template v-for="(file,index) in files" >
            <div  v-bind:key="index" class="file-to-add">
              <p>{{file.name}}</p>
              <div class='progress' :ref="file.name"></div>    
            </div>
        </template>
        <progress id="file" max="100" :value="progress"></progress>
        <div class="add-attachemnt-buttons">
            <button v-on:click="uploadFiles()">Attach documents</button>
            <button v-on:click="files = []" :disabled="disableCancle">Cancle</button>
            
        </div>
    </div>
</transition>
  </div>
</template>

<script>
export default {
    props:["itemId","reset"],
    watch:{
        reset: function (){
            if (this.files.length){
                this.files = []
            }
        }
    },
    data(){
        return{
            files:[],
            progress:0,
            progressBars:null,
            disableCancle: false
        }
    },
    methods:{
        dropHandler(ev) {
  // console.log('File(s) dropped');
  // ev.target.style.minHeight="47px"

  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();

  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[i].kind === 'file') {
        var file = ev.dataTransfer.items[i].getAsFile();
        // console.log('... file[' + i + '].name = ' + file.name);
        this.files.push(file)
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
      // console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
      this.files.push(ev.dataTransfer.files[i])
    }
  }
},
dragOverHandler(ev) {
  // console.log('File(s) in drop zone');
  // console.log(ev)

  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
  ev.target.style.backgroundColor="white"
  // ev.target.style.minHeight="105px"
  ev.target.firstElementChild.style.color="black"
},
dragLeaveHandler(ev) {
  // console.log('File(s) out of drop zone');

  // Prevent default behavior (Prevent file from being opened)
  // ev.preventDefault();
  ev.target.style.backgroundColor="transparent"
  // ev.target.style.minHeight="47px"
  ev.target.firstElementChild.style.color="white"
},
uploadFiles(){
            this.files.forEach((file)=>{
            if (file.size > 1000000000){
              this.files = []
              return false
            }
          })
  this.disableCancle = true
  this.progressBars = document.getElementsByClassName("progress")
  let events = []
  var source = new EventSource(`${process.env.VUE_APP_FILES_SHARING_API}/upload/progress`);
        source.addEventListener("uuid", (event)=>{
          events.push(event.data)
          // console.log("event",event)
          const data = new FormData()
          data.append('flowId',this.$route.params.id)
          data.append('itemId',this.$props.itemId)
          this.files.forEach((file)=>{
            // console.log(file)
            data.append(file.name,file)
          })
          const progress = {
            onUploadProgress: (progressEvent) => {
                // console.log(progressEvent)
	        			const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
	        			// console.log("onUploadProgress", totalLength);
	        			if (totalLength !== null) {
	        				this.progress = Math.round( (progressEvent.loaded * 100) / totalLength );
	        				// console.log(Math.round( (progressEvent.loaded * 100) / totalLength ));
	        			}
	        		}
          }
          this.$fileStore.fileStoreApiShared.post(`/uploader/${event.data}`,data,progress).then(()=>{
            // console.log("!!!FILE UPLOADED")
            const index = events.indexOf(event.data);
            // console.log(event.data)
            if (index > -1) {
              events.splice(index, 1); // 2nd parameter means remove one item only
            }
            if (events.length == 0){
            this.disableCancle = false
            this.progress = 0
            this.files = []
            this.$emit("updateFiles")
            source.close()
            }
          }).catch(()=>{
            this.disableCancle = false
            source.close()
          })
        });
        source.onmessage = (event)=> {
            this.progress = Object.values(JSON.parse(event.data))[0]
            this.$refs[Object.keys(JSON.parse(event.data))[0]][0].style.width=Object.values(JSON.parse(event.data))[0] + "%"
            // if (event.data == 100.0) {
            //     source.close()
            // }
    }
}
    }
}
</script>

<style>
.add-attachemnt{
    /* transition: all 1s ease-in-out; */
    min-height:75px;
    /* height:75px; */
    width:100%;
    background-color: black;
    border-radius:5px;
    margin-bottom:5px;
    display:flex;
    justify-content: center;
    align-items: center;
    padding-top:2.5%;
    padding-bottom:2.5%;
}
.files-drop-zone{
    width:100%;
    height:100%;
    min-height:47px;
    display:flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.files-drop-zone h4{
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}

.file-list{
    width:100%;
    height:auto;
    color:white;
    display:grid;
    grid-auto-rows: 50px;
    grid-gap:5px;
    align-items: center;
    justify-items: center;
}
.file-to-add{
    background-color: rgba(82, 82, 82, 0.527);
    width:95%;
    border-radius:2px;
    height:100%;
    display:flex;
    align-items: center;
    position:relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/* @-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:50% 50%}
    100%{background-position:100% 50%}
} */
/* @-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:50% 50%}
    100%{background-position:100% 50%}
} */
@keyframes AnimationNam {
    0%{background-position:200% 50%}
    /* 50%{background-position:50% 50%} */
    100%{background-position:0% 50%}
}
.progress{
  position: absolute;
  width:0%;
  height:100%;
  /* background: linear-gradient(to left, #C1EBE1, #93B4AC); */
  background-size: 200% 100% ;
  background-repeat: repeat;
  /* background: linear-gradient(to right, #036564, #013f3e); */
  background-image: linear-gradient(to left, #036564, #033d3b);
    /* -webkit-animation: AnimationName 2s ease infinite;
    -moz-animation: AnimationName 2s ease infinite; */
    animation: AnimationNam 2s ease infinite;
  border-radius:inherit ;
}

.file-to-add p{
    margin:0;
    padding:2%;
    z-index:1;
}

.add-attachemnt-buttons{
  width:90%;
  height:60%;
  display: flex;
  justify-content: center;
  gap:2.5%;
}
.add-attachemnt-buttons button{
  width:45%;
  height:100%;
  border:none;
  border-radius:2px;
  
}

.fade-attachment-enter-active {
  transition: max-height 0.5s,opacity 0.5s 0.5s;
  max-height:600px;
}

.fade-attachment-leave-active{
    transition: opacity 0.5s,max-height 0.5s 0.5s;
   max-height:600px; 
}

.fade-attachment-enter,
.fade-attachment-leave-to
{
  opacity: 0;
  max-height: 75px;
}
</style>