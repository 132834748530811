// export default {
//     bind: function (el, binding, vnode) {
//         el.clickOutsideEvent = function (event) {
//           // here I check that click was outside the el and his children
//           if (!(el == event.target || el.contains(event.target)) && !(event.target.classList.contains("item-options-button"))) {
//             // and if it did, call method provided in attribute value
//             event.stopPropagation()
//             console.log(el.classList)
//             document.querySelectorAll(".item-options-container").forEach(element => {if(element.classList.contains("show")) {element.classList.remove("show");element.style.pointerEvents="auto";}})
//           }
//         };
//         document.body.addEventListener('click', el.clickOutsideEvent)
//       },
//       unbind: function (el) {
//         document.body.removeEventListener('click', el.clickOutsideEvent)
//       },
// }


let handleOutsideClick
export default { 
    bind (el, binding, vnode) {
        
        // Here's the click/touchstart handler
        // (it is registered below)
        handleOutsideClick = (e) => {
            
          e.stopPropagation()
        //   console.log(vnode.data.directives.some(directive => directive.name == binding.name))
        //   console.log(binding)
        //   console.log(el.classList.contains("show"))
        //   const sameBinding = 
          // Get the handler method name and the exclude array
          // from the object used in v-closable
          const { handler, include ,exclude, arg } = binding.value
          // This variable indicates if the clicked element is excluded
          let clickedOnExcludedEl = false
          exclude.forEach(refName => {
            // We only run this code if we haven't detected
            // any excluded element yet
            if (!clickedOnExcludedEl) {
                // console.log(vnode.context.$refs[refName])
                // console.log(e.target)
              // Get the element using the reference name
              const excludedEl = vnode.context.$refs[refName][0]
              // See if this excluded element
              // is the same element the user just clicked on
            //   console.log(excludedEl.contains(e.target))
              clickedOnExcludedEl = excludedEl?.contains(e.target)     
            }
          })
          // We check to see if the clicked element is not
          // the dialog element and not excluded
          if (!el.contains(e.target) && !clickedOnExcludedEl && el.classList.contains("show")) {
            // If the clicked element is outside the dialog
            // and not the button, then call the outside-click handler
            // from the same component this directive is used in
            // console.log("run")
            vnode.context[handler](arg)
          }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
      },
      unbind () {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick)
        document.removeEventListener('touchstart',handleOutsideClick)
      }
}