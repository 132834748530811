<template>
    <div class="flows-view-container">
        <transition-group tag="div" class="flows-grid" name="list">
            <div class="flow-container" key="quick-links">
              <div class="flow" key="quick-links">
                <QuickLinks v-bind:Edit="editQuickLinks"></QuickLinks>
              </div>
              <div class="flow-buttons">
                <div class="edit-flow-container">
                    <button  class="edit-flow-button" v-on:click="editQuickLinks = !editQuickLinks">Edit</button>          
                </div>
              </div>
            </div>
            <template v-for="(flow,index) in flows">
              <div class="flow-container" v-bind:key="flow.flow_id">
                <div class="flow" key="flow" v-on:click="$router.push({ name: 'flow', params: { id: flow.flow_id, title:flow.title } });"  v-if="!flow.shared">
                    <Notificationarea v-bind:ID="flow.flow_id" v-bind:dateData="notificationData" class="noti"></Notificationarea>
                </div>
                <div class="flow" key="flow" v-on:click="$router.push({ name: 'flowShared', params: { id: flow.flow_id, title:flow.title } });"  v-else>
                    <NotificationareaShared v-bind:ID="flow.flow_id" v-bind:dateData="notificationDataShared" class="noti"></NotificationareaShared>
                </div>
                <div class="flow-name-container">
                    <h3>{{flow.title}}</h3>
                </div>
                
                <div class="flow-buttons">
                      <div class="edit-flow-container">
                          <button v-bind:key="'edit'+flow.flow_id" class="edit-flow-button" v-on:click="$emit('edit',{index:index,flow:flow})">Edit</button>
                          
                      </div>
                      <div class="delete-flow-button-container" v-if="!flow.shared">
                          <button v-bind:key="'delete'+flow.flow_id" class="exit delete-flow-button" v-on:click="$emit('delete',{index:index,flow:flow})"></button>
                      </div>
                      <div class="delete-flow-button-container" v-else>
                          <button v-bind:key="'delete'+flow.flow_id" class="share delete-flow-button" disabled></button>
                      </div>
                </div>
              </div>  
            </template>
            <div class="add-flow" key="add">
              <div class="flow" key="add">
                <AddFlow v-on:add="flows.push($event)"></AddFlow>
              </div>
            </div>
            
        </transition-group>
    </div>
</template>

<script>
import AddFlow from '../../components/AddFlow.vue';
import Notificationarea from '../../components/notificationarea.vue';
import NotificationareaShared from '../../components/notificationareaShared.vue';
import QuickLinks from '../../components/QuickLinks.vue';


export default {
  props:["flows","notificationData","notificationDataShared"],
  components: {
      Notificationarea,
      NotificationareaShared,
      AddFlow,
      QuickLinks
    },
    data(){
        return{
            editQuickLinks:false,
        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>

<style>
.flows-view-container{
    height:100%;
    width:100%;
    flex-shrink:0;
    flex-grow:1;
    display: flex;
    align-items: center;
    /* overflow-x: auto; */
}
.flows-grid{
      /* white-space: nowrap; */
      /* min-height:500px; */
    /* align-items: center;
    height:100%;
    width:100%;
    display: flex;
    flex-shrink: 0;
    flex-grow:0;
    justify-content: flex-start;
    gap:2%;
    padding-left:2%;
    padding-right:2%;
    overflow-x: auto; */


  display: grid;
  padding-left:2%;
  padding-right:2%; 
  height:100%;
  grid-gap: 2%;
  grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
  grid-auto-flow: column;
  overflow: auto;
  flex-grow:1;
}
.flow-container, .add-flow{
    width:250px;
    height:100%;
    position:relative;
    display:flex;
    flex-shrink: 0;
    align-items: center;
    max-height:525px;
    /* justify-content: center; */
}
.flow{
    box-shadow:0px 0px 50px -25px;
    background-color: var(--color-lighter);
    backdrop-filter: blur(0px);
    animation: blur-in 400ms ease-in 500ms forwards; /* Important */
    transition: background-color 1s ease-in-out, box-shadow 0.5s ease-in-out;
    /* transition: box-shadow 0.5s ease-in-out;  */
    /* margin-left:2%; */
    height:80%;
    width:100%;
    flex-shrink:0;
    border-radius:4px;
}

.flow:hover{
    cursor:pointer;
    box-shadow:0px 0px 50px -15px;
    background-color: var(--color-light);
}
.flow-name-container{
    pointer-events:none;
    position: absolute;
    bottom:2.5%;
    /* background-image: linear-gradient( 45deg, var(--color-light) -80%,transparent); */
    width:100%;
    border-radius:2px;
    /* display:none; */
    transition:all 1s ease-in-out;
        /* overflow: hidden; */
    white-space: nowrap;
  text-overflow: ellipsis;
}
.flow-name-container h3{
    margin:0;
    width:auto;
    padding-left:2%;
    justify-content: flex-start;
    /* color: var(--text-color); */
    color:rgb(var(--c), var(--c), var(--c));
    transition:all 1s ease-in-out;
    /* font-size: 2.5ch; */
}
.flow-container:hover .flow-buttons{
    /* display:block; */
    opacity:1;
    pointer-events:auto;
}

.flow-container:hover  > .flow-buttons > *{
    /* display:block; */
    pointer-events:auto;
}

.flow-container:hover .side-button{
  opacity:1;
}

.flow-buttons{
    height:7%;
    width:40%;
    display:flex;
    position:absolute;
    right:0;
    top:0%;
    opacity:0;
    transition:all 0.2s ease-in-out;
}
</style>