<template>
<div class="link-main" v-if="link.length" >
    <div class="link" v-for="link in LinkData" :key="link.link_id" @mouseover="hovers=link.link_id" @mouseleave="hovers=false">
        <button class="remove-link" :style="hovers===link.link_id?{opacity:1,height:'2vh'}:{height:'0vh',padding:0}" v-on:click="removelink(link.link_id)"><p :style="hovers===link.link_id?{}:{display:'none'}">Remove Link</p></button>
        <img :src="link.img" class="link-image"  :style="hovers===link.link_id?{height:'10vh',width:'100%'}:{height:'0vh'}">
        <div class="link-content">
        <div class="title">{{link.Title}}</div>
        <div class="domain">{{link.domain}}</div>
        <div class="description" v-if="link.Description" :style="hovers===link.link_id?{height:'50px'}:{height:'0px'}"><p>{{link.Description}}</p></div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  props:['link','updatelinks'],
  methods:{
       async init(){
        //    console.log(this.link.length)
         if(this.link.length){
        await this.$api.post('https://services.cubeitdone.com/api/search',{
              link:this.link}
          ).then((data)=>{
            //   console.log(data.data)
              this.LinkData=data.data
          }).catch(error=>console.log(error))
          }
      },
      removelink(id){
          this.$api.delete(`/api/link/remove`,{data:{linkId:id}}).then(()=>{
              this.LinkData.splice(this.LinkData.findIndex(element=>element.link_id===id),1)
              this.$emit('removelink',id)   
          })
      }

  },
  mounted(){
      this.init()
  },
  watch:{
      link:function(){
          this.init()
      }
  },
  computed: {

  },
  data(){
    return{
        hovers:false,
        LinkData:[{img:null,Title:null,domain:null,Description:null}]
    }
  }
}
</script>
<style scoped>
p{
    margin:0;
}
.link-main{
    width:100%;
}
.title{
    font-weight: bold;
    padding:1%;
}
.domain{
    text-align: right;
    padding:1%;

}
.description{
    width:100%;
    height:0px;
    max-height:50px;
    word-wrap: break-word;
    overflow: hidden;
    padding:1%;
    transition: all 0.5s ease-in-out;
}
.link-image{
    display:block;
    height: 0vh;
    width:0%;
    object-fit: cover;
    border-radius:5px 5px 0px 0px;
    transition: all 0.5s ease-in-out;
}
.link{
  cursor:pointer;
  width:100%;
  padding:1%;
}
.link-content{
    background-color: rgb(209, 209, 209);
    backdrop-filter: blur(9px);
    width:100%;
    border-radius:0px 0px 5px 5px;
}
.remove-link{
    opacity:1;
    border-radius: 2px;
    transition: all 0.1s ease-in-out;
    border:none;
    background-color: black;
    letter-spacing: 1px;
    color:white;
}
.remove-link:hover{
    background-color: rgb(126, 27, 24);
    cursor:pointer;
}

.b {
  word-wrap: break-word;
}
</style>