<template>
<button class="download-button" v-on:click.stop="download($event)">
<div class="dl">
  <div class="dl-box">
    <div class="dl-button">
      <div class="arrow"></div>
      <div class="basket">
        <div class="basket-top"></div>
      </div>
    </div>
    <div class="dl-progress">
      <div class="check">
        <svg>
          <use xlink:href="#check" />
        </svg>
      </div>
    </div>
  </div>
  <div class="dl-status">
    <div class="text text-file">Status</div>
    <div class="text text-number"></div>
  </div>
</div>
  
<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
  <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130" id="check">
      <polyline points="100.2,40.2 51.5,88.8 29.8,67.5 "/> 
  </symbol>
</svg>    
  </button>
</template>

<script>
var $ = (s, o = document) => o.querySelector(s);



export default {
  props:["fileName","itemId","shared"],
    methods:{
      download(event){
            var el = event.target.firstChild
              el.classList.add("run");
  setTimeout(() => el.classList.add("done"), 4000);
  setTimeout(() => el.classList.remove("done"), 5500);
  setTimeout(() => el.classList.remove("run"), 5500)
  if(this.shared){
      this.$fileStore.fileStoreApiShared.get(`/url/${this.$props.fileName}/${this.$route.params.id}/${this.$props.itemId}`).then((data)=>{
    // console.log(data.data) 
    const link = document.createElement("a");
    link.href = data.data;
    link.download = this.$props.fileName;
    link.click();
    link.remove()
  })
  }
  else{
    this.$fileStore.fileStoreApi.get(`/url/${this.$props.fileName}/${this.$route.params.id}/${this.$props.itemId}`).then((data)=>{
      // console.log(data.data) 
      const link = document.createElement("a");
      link.href = data.data;
      link.download = this.$props.fileName;
      link.click();
      link.remove()
    })
  }

        }
    }
}
</script>
<style>
.download-button{
    height:auto;
    width:auto;
    background-color: transparent;
    border:none;
    border-radius:5px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0px 0px rgba(0,0,0, 0.2);
    display:flex;
    justify-content: center;
    align-items: center;
}
.download-button:hover{
    background-color: rgba(0, 0, 0, 0.13);
    /* box-shadow: 0 10px 30px rgba(0,0,0, 0.2);   */
}
</style>
<style lang="scss">
@keyframes status-animation { 
  @for $i from 1 through 100 {
    $status: $i + "%";
    #{$status} {
      content: $status;
    }
  }
}

.dl {
  --background: var(--color-lighter);
  --primary: var(--text-color); 
  --border-radius: 3px;
  --square-size: 55px;
  --progress-color: linear-gradient(to right top, #00A7FC, #0070ED);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height:30px; // causes over-flow otherwise : small effect on position of child elements
  pointer-events: none;
  .dl-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    .dl-button {
      width: 40px;
      height: 30px;
      position: relative;
      display: flex;
      justify-content: center;
    //   background: var(--primary);
      cursor: pointer;
      transition: width .2s ease-in .4s, height .2s ease-in .4s, background .2s ease-in .4s;
      .arrow {
        position: absolute;
        top: 5px;
        width: 2px;
        height: 12px;
        background: var(--primary);
        border-radius: var(--border-radius);
        transition: background .2s ease-in .4s;
        &:before,
        &:after {
          content: "";
          display: block;
          width: 2px;
          height: 7px;
          background: var(--primary);
          position: absolute;
          bottom: 0px; 
          transform-origin: 50% 100%;
          border-radius: var(--border-radius);
          transition: background .2s ease-in .4s;
        }
        &:before {
          transform: rotate(-45deg) translateX(25%);
        }
        &:after {
          transform: rotate(45deg) translateX(-25%);
        }
      }
      .basket {
        position: absolute;
        width: 55px;
        height: 10px;
        bottom: 18px;
        border-radius: var(--border-radius);
        transition: width 0.1s ease-in-out;
        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: -10px;
          height: 10px;
          width: 20px;
          display: block;
          opacity: var(--opacity, 1);
          transition: opacity .15s linear;
          border-bottom: solid 2px var(--primary);
          transition: border .2s ease-in .4s;
          box-sizing: border-box;
        }
        &:before {
          left: calc(40px / 2);
          border-right: solid 2px var(--primary);;
          border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
        &:after {
          right: calc(40px / 2);
          border-left: solid 2px var(--primary);;
          border-radius: var(--border-radius) 0 0 var(--border-radius);
        }
        .basket-top {
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 0px;
            height: 0;
            width: 0;
            display: block;
          }
          &:before {
            left: 2px;
            border-radius: 0 var(--border-radius) var(--border-radius) 0;
          }
          &:after {
            right: 2px;
            border-radius: var(--border-radius) 0 0 var(--border-radius);
          }
        }
      }
    }
    .dl-progress {
      width: 0px;
      height: 100%;
      position: relative;
      transition: width .2s ease-in, height 0s linear .2s;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        bottom: 0;
        left: 0;
        width: 100%;
        background: var(--progress-color);
        transition: top 0s ease .4s; 
      }
      .check {
        position: relative;
        z-index: 1;
        pointer-events: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 45px;
          width: 45px;
          fill: none;
          stroke: white;
          stroke-width: 6;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 100 100;
          stroke-dashoffset: 100;
        }
      }
    }
  }
  .dl-status {
    margin-left: 0;
    flex-basis: 0;
    width: 0;
    height:0;
    transition: all .2s ease-out .2s;
    clip-path: inset(0 var(--square-size) 0 0);
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .text {
      transition: color 0.2s ease-in-out;

    //   transition: font-size 0.2s ease-in-out 0.7s;
    //   text-align: left;
      text-transform: uppercase;
      color:hsla(var(--light-text-hsl-hue),var(--light-text-hsl-saturation),var(--light-text-hsl-lightness),0.3);
      font-weight: 500;
      letter-spacing: .5px;
      line-height: 23px;
      font-size:10px;
      &.text-number {

          transition: color 0.2s ease-in-out;
        font-weight: 600;
        color: hsla(var(--light-text-hsl-hue),var(--light-text-hsl-saturation),var(--light-text-hsl-lightness), 0.6);
        &::after {
          content: "0%";
        }
      }
    }
  }
  &.run {
    .dl-box {
      .dl-button {
        width: var(--square-size);
        height: var(--square-size);
        background:var(--background);
        transition: width .2s ease-out .3s, height .2s ease-out .3s, background .2s ease-out .3s;
        .arrow {
          background: var(--primary);
          transition: background .3s ease-in;
          animation: arrow-main .75s ease-in forwards infinite;
          &:before,
          &:after {
            transition: background .3s ease-in;
            background: var(--primary);
          }
          &:before {
            animation: arrow-left .75s ease-in forwards infinite;
          }
          &:after {
            animation: arrow-right .75s ease-in forwards infinite;
          }
        }
        .basket {
                         width:40px;
                        // opacity:0;
          &:before,
          &:after {
            --opacity: 1;
            transition: opacity 0s linear .3s;
            animation: btn-border .3s forwards ease-in normal .5s;
             border-bottom: solid 2px var(--primary);

          }
          &:before{
             border-right:solid 2px var(--primary);
          }
          &:after{
            border-left:solid 2px var(--primary);
          }
          .basket-top {
            &:before,
            &:after {
              border-top: solid 2px var(--primary);
              animation: btn-border-top .3s forwards ease-in normal .8s;
            }
          }
        }
      }
      .dl-progress { 
        width: var(--square-size);
        height: var(--square-size);
        transition: width .3s ease-in .7s, height .3s ease-in .7s;
        background: var(--background);
        &::after {
          top: 0px;
          transition: top 2.5s ease 1.5s;
        }
      }
    }
    .dl-status {
      width: var(--square-size);
      height: var(--square-size);
      clip-path: inset(0 0 0 0);
      transition: all .3s ease-in .7s;
      .text {
          
        &.text-number {
          &::after {
            animation: status-animation 2.5s ease 1.5s forwards;
          }
        }
      }
    }
  }
  &.done {
    .dl-box {
      .dl-button {
        .arrow {
          animation-play-state: paused;
          &:before {
            animation-play-state: paused;
          }
          &:after {
            animation-play-state: paused;
          }
        }
      }
      .dl-progress {
        .check {
          svg {
            animation: check .3s ease-in-out forwards;
          }
        }
      }
    }
  }
}

@keyframes arrow-main {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-5px);
    }
    60% {
        transform: translateY(12px);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes arrow-left {
    0% {
        transform: rotate(-45deg) translateX(25%);
    }
    30% {
        transform: rotate(-55deg) translateX(25%);
    }
    80% {
        transform: rotate(-35deg) translateX(25%);
    }
    100% {
        transform: rotate(-45deg) translateX(25%);
    }
}
@keyframes arrow-right {
    0% {
        transform: rotate(45deg) translateX(-25%);
    }
    30% {
        transform: rotate(55deg) translateX(-25%);
    }
    80% {
        transform: rotate(35deg) translateX(-25%);
    }
    100% {
        transform: rotate(45deg) translateX(-25%);
    }
}

@keyframes btn-border {
  0% {
    width: 0;
    height: 0;
  }
  50% {
    width: calc(40px / 2);
    height: 0;
  }
  100% {
    width: calc(40px / 2);
    height: 20px;
  }
}

@keyframes btn-border-top {
  0% {
    width: 0px;
  }
  100% {
    width: 10px;
  }
}

@keyframes check {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 200;
  }
}

</style>