<template>
<div class="note-area">
  <div class="note-content">
    <template v-for="(n,index) in news" v-key="index">
      {{n.a}}
      <a v-if="n.link" v-bind:key="'link'+index" v-on:click="click(n.link)" class="links">{{n.link}}</a>
    </template>
  </div>
</div>
</template>

<script>

export default {
  props:['notestuff'],
  methods:{
    click(link){
      if(/^(http|https):/igm.exec(link)){
        window.open(link,'_blank').focus()
      }
      else{
        window.open("//"+link,'_blank').focus()
      }
    }
  },
  mounted(){
    this.news
  },
  computed: {
    news:function(){
      if (this.notestuff){
        var result=[]
        const expressions = /(?:(?:https?|ftp|file):\/\/|www\.(?!)|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])|(?:[-a-zA-Z0-9@:%_.~#?&=]*\.com[^\s]*)/igm;
        var match
        var tmp = 0
        while(match = expressions.exec(this.notestuff.note))
        {
            result.push({a:this.notestuff.note.slice(tmp,match.index),link:match[0]})
            tmp = match[0].length+match.index
        }
        result.push({a:this.notestuff.note.slice(tmp,350)})
      }
      return result
    }
  },
  data(){
    return{
        items:null
    }
  }
}
</script>
<style scoped>
.note-area{
  display:flex;
  word-wrap: break-word;
  white-space:pre-wrap;
  /* padding:2%; */
  line-height: 1.6;
  max-height:300px;
}
.note-content{
  /* background-color: black; */
  /* color:var(--text-color); */
  height:auto;
  width:100%;
  overflow: auto;
  padding-left:1.5%;
}
.links{
  color:white;
  background-color:grey;
  padding:0.1em;
  border-radius:3px;
}
.links:hover{
  color:black !important;
  background-color: white !important;
  box-shadow: 0px 0px 20px;
  cursor:pointer;
}
</style>