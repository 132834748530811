import axios from 'axios';


const peopleApi = axios.create({
  baseURL: `${process.env.VUE_APP_PEOPLE_API}`
})

const peopleApiSearch = axios.create({
    baseURL: `${process.env.VUE_APP_PEOPLE_API_SEARCH}`
  })

  const peopleApiRequests = axios.create({
    baseURL: `${process.env.VUE_APP_PEOPLE_API_REQUESTS}`
  })



export default {peopleApi,peopleApiSearch,peopleApiRequests}