<template>
    <div class="wallpaper-settings">
        <div class="wallpaper-search-container">
            <button v-on:click.stop="$emit('show')" class="exit"></button>
            <input type="text" placeholder="Search for a Wallpaper on Unsplash" v-model="imageSearch">
        </div>
        <div class="wallpaper-topics-container">
            <div class="topics">
                <button class="side-button" v-on:click="topicPreviousPage()">{{"["}}</button>
                <template v-for="topic in topics">
                    <div class="topic" v-bind:key="topic.id"><button v-on:click="clickTopic(topic.id)" :style="topic.id == selectedTopic ? {backgroundColor:'black',color:'white'} : {}">{{topic.title}}</button></div>
                </template>
                <button class="side-button" v-on:click="topicNextPage()">{{"]"}}</button>
            </div>
        </div>
        <div class="wallpaper-images-container">
            <div  class="wallpaper-images-grid-container" v-on:scroll="imageScroll($event)">
                <transition-group tag="div" name="list" class="wallpaper-images-grid" v-on:scroll="imageScroll($event)">
                <template v-for="(image,index) in images" >
                    
                    <div v-bind:key="image.id" :style="colorAndAspectRatio(image.color,image.width,image.height)" v-if="!removeDuplicates(image.id,index)" v-on:click="clickImage(image.id,image.color,image.urls.full,image.user.username,image.user.links.html,image.width,image.height)" class="wallpaper-image">
	                    <img :src="image.urls.thumb" width="538" height="" alt="346" title="" />
                        <div class="wallpaper-author" v-on:click.stop>
                            <img :src="image.user.profile_image.small" alt="">
                            <!-- <a :href="image.user.links.html" target="_blank" rel="noopener noreferrer">{{removeDuplicates(image.id,index)}}{{image.id}}</a> -->
                            <a :href="image.user.links.html" target="_blank" rel="noopener noreferrer">{{image.user.username}}</a>
                        </div>
                    </div>
                    
                </template>
              </transition-group>
            </div>
            <div class="current-wallpaper-details">
                <p>Your current wallapaper is by <a :href="$settings.wallpaperUsernameLink" target="_blank" rel="noopener noreferrer">{{$settings.wallpaperUsername}}</a></p>
            </div>
            
        </div>
    </div>
    <!-- <a class="wallpaper-text">Change wallpaper</a>
    <div class="row">
      <div class="column">
        <img
          v-for="image in images"
          :key="image"
          :src="image"
          style="width: 100%"
          v-on:click="clickimage(image)"
        />
        <div>Some images from pixbay</div>
      </div>
    </div> -->

</template>

<script>


import DarkThemeSwitch from "../../components/DarkThemeSwitch.vue"
class ImgObject  {
  constructor(FirstName,LastName,ProfileLink,ProfilePicture,Id,thumb,color) {
    Author:{
        this.Author.FirstName      = FirstName
        this.Author.LastName       = LastName
        this.Author.ProfileLink    = ProfileLink
        this.Author.ProfilePicture = ProfilePicture
    }
    Image:{
        this.Id     = Id
        this.thumb  = thumb
        this.color  = color
    }
  }
}

class TopicObject  {
  constructor(Id,Title,Slug,Links,CoverPhoto) {
    Photo:{
        this.cover_photo      = CoverPhoto
    }
    Topic:{
        this.id     = Id
        this.title  = Title
        this.slug  = Slug
        this.links = Links
    }
  }
}
export default {
    components:{
        DarkThemeSwitch
    },
    data(){
        return {
            // images:[{
            //     Author:{
            //         FirstName:String,
            //         LastName:String,
            //         ProfileLink:URL,
            //         ProfilePicture:URL
            //     },
            //     Image:{
            //         Id:String,
            //         thumb:URL,
            //         color:String
            //     }
            // }],
            // images:[],
            images:
                [
    {
        "id": "nK1SKIdfL9U",
        "created_at": "2021-06-07T01:17:42-04:00",
        "updated_at": "2021-06-27T05:25:43-04:00",
        "promoted_at": "2021-06-07T19:54:02-04:00",
        "width": 2500,
        "height": 3335,
        "color": "#f3c0a6",
        "blur_hash": "LJFN_7%2M{t757t6~BIo5QNG9tR*",
        "description": "To achieve this painterly look in my flower photographs, I take several processing steps: (1) Increase the clarity slider (2) Decrease the texture slider (almost all the way down) (3) Move sharpness to the middle (3) Decrease noise (Color and Light). It seems like all of these adjustments will cancel each other out, however when you try, you will see that the result is smooth, silky and painterly with crisp edges.",
        "alt_description": "white leaves on black textile",
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/nK1SKIdfL9U",
            "html": "https://unsplash.com/photos/nK1SKIdfL9U",
            "download": "https://unsplash.com/photos/nK1SKIdfL9U/download",
            "download_location": "https://api.unsplash.com/photos/nK1SKIdfL9U/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 87,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "VBgcT1RrsN8",
            "updated_at": "2021-06-27T10:33:04-04:00",
            "username": "universaleye",
            "name": "Munro Studio",
            "first_name": "Munro",
            "last_name": "Studio",
            "twitter_username": null,
            "portfolio_url": null,
            "bio": "I create visual content, art, commercial images & brand identity with my clients. Start your projects using the photos I've donated. Contact me to purchase fully retouched versions, enlarged prints or to retain my professional services.",
            "location": "United States",
            "links": {
                "self": "https://api.unsplash.com/users/universaleye",
                "html": "https://unsplash.com/@universaleye",
                "photos": "https://api.unsplash.com/users/universaleye/photos",
                "likes": "https://api.unsplash.com/users/universaleye/likes",
                "portfolio": "https://api.unsplash.com/users/universaleye/portfolio",
                "following": "https://api.unsplash.com/users/universaleye/following",
                "followers": "https://api.unsplash.com/users/universaleye/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1615170883424-223c49bef768image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1615170883424-223c49bef768image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1615170883424-223c49bef768image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": null,
            "total_collections": 15,
            "total_likes": 75,
            "total_photos": 97,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": null,
            "model": null,
            "exposure_time": "1/60",
            "aperture": "10",
            "focal_length": "90.0",
            "iso": 400
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 1061765,
        "downloads": 10718
    },
       {
        "id": "nK1SKIdfL9U",
        "created_at": "2021-06-07T01:17:42-04:00",
        "updated_at": "2021-06-27T05:25:43-04:00",
        "promoted_at": "2021-06-07T19:54:02-04:00",
        "width": 2500,
        "height": 3335,
        "color": "#f3c0a6",
        "blur_hash": "LJFN_7%2M{t757t6~BIo5QNG9tR*",
        "description": "To achieve this painterly look in my flower photographs, I take several processing steps: (1) Increase the clarity slider (2) Decrease the texture slider (almost all the way down) (3) Move sharpness to the middle (3) Decrease noise (Color and Light). It seems like all of these adjustments will cancel each other out, however when you try, you will see that the result is smooth, silky and painterly with crisp edges.",
        "alt_description": "white leaves on black textile",
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623042881382-cf8b93f284d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/nK1SKIdfL9U",
            "html": "https://unsplash.com/photos/nK1SKIdfL9U",
            "download": "https://unsplash.com/photos/nK1SKIdfL9U/download",
            "download_location": "https://api.unsplash.com/photos/nK1SKIdfL9U/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 87,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "VBgcT1RrsN8",
            "updated_at": "2021-06-27T10:33:04-04:00",
            "username": "universaleye",
            "name": "Munro Studio",
            "first_name": "Munro",
            "last_name": "Studio",
            "twitter_username": null,
            "portfolio_url": null,
            "bio": "I create visual content, art, commercial images & brand identity with my clients. Start your projects using the photos I've donated. Contact me to purchase fully retouched versions, enlarged prints or to retain my professional services.",
            "location": "United States",
            "links": {
                "self": "https://api.unsplash.com/users/universaleye",
                "html": "https://unsplash.com/@universaleye",
                "photos": "https://api.unsplash.com/users/universaleye/photos",
                "likes": "https://api.unsplash.com/users/universaleye/likes",
                "portfolio": "https://api.unsplash.com/users/universaleye/portfolio",
                "following": "https://api.unsplash.com/users/universaleye/following",
                "followers": "https://api.unsplash.com/users/universaleye/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1615170883424-223c49bef768image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1615170883424-223c49bef768image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1615170883424-223c49bef768image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": null,
            "total_collections": 15,
            "total_likes": 75,
            "total_photos": 97,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": null,
            "model": null,
            "exposure_time": "1/60",
            "aperture": "10",
            "focal_length": "90.0",
            "iso": 400
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 1061765,
        "downloads": 10718
    },
    {
        "id": "CFIZtzfOJRk",
        "created_at": "2021-06-08T10:55:23-04:00",
        "updated_at": "2021-06-26T23:24:26-04:00",
        "promoted_at": "2021-06-08T18:33:02-04:00",
        "width": 2581,
        "height": 3872,
        "color": "#262626",
        "blur_hash": "LHDm1^-.9Ft7~m-os;j=IBxt%MoJ",
        "description": "Happy dog",
        "alt_description": "white and black short coated dog on green grass during daytime",
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623163688930-1652904cfc1a?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623163688930-1652904cfc1a?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623163688930-1652904cfc1a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623163688930-1652904cfc1a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623163688930-1652904cfc1a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/CFIZtzfOJRk",
            "html": "https://unsplash.com/photos/CFIZtzfOJRk",
            "download": "https://unsplash.com/photos/CFIZtzfOJRk/download",
            "download_location": "https://api.unsplash.com/photos/CFIZtzfOJRk/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 31,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "jrCUu0aF6YQ",
            "updated_at": "2021-06-27T09:32:56-04:00",
            "username": "malcoo",
            "name": "Tomáš Malík",
            "first_name": "Tomáš",
            "last_name": "Malík",
            "twitter_username": null,
            "portfolio_url": "https://www.instagram.com/tomas_malco_malik",
            "bio": "If you like my work, follow me on IG or you can donate on Paypal tomasskoo@gmail.com\r\n Contact: tomasskoo@gmail.com",
            "location": "Slovakia, Bratislava",
            "links": {
                "self": "https://api.unsplash.com/users/malcoo",
                "html": "https://unsplash.com/@malcoo",
                "photos": "https://api.unsplash.com/users/malcoo/photos",
                "likes": "https://api.unsplash.com/users/malcoo/likes",
                "portfolio": "https://api.unsplash.com/users/malcoo/portfolio",
                "following": "https://api.unsplash.com/users/malcoo/following",
                "followers": "https://api.unsplash.com/users/malcoo/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-fb-1539459502-03cef6b596c8.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-fb-1539459502-03cef6b596c8.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-fb-1539459502-03cef6b596c8.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "tomas_malco_malik",
            "total_collections": 0,
            "total_likes": 1,
            "total_photos": 238,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": null,
            "model": null,
            "exposure_time": null,
            "aperture": null,
            "focal_length": null,
            "iso": null
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 164389,
        "downloads": 804
    },
    {
        "id": "yYI4OVImGo0",
        "created_at": "2021-06-08T17:59:28-04:00",
        "updated_at": "2021-06-26T21:23:34-04:00",
        "promoted_at": "2021-06-13T14:00:01-04:00",
        "width": 3463,
        "height": 5194,
        "color": "#d9d9d9",
        "blur_hash": "LlK^snNxOTsA?^smn+kBLMs.r@S4",
        "description": null,
        "alt_description": null,
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623189184559-b23b1867c388?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623189184559-b23b1867c388?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623189184559-b23b1867c388?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623189184559-b23b1867c388?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623189184559-b23b1867c388?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/yYI4OVImGo0",
            "html": "https://unsplash.com/photos/yYI4OVImGo0",
            "download": "https://unsplash.com/photos/yYI4OVImGo0/download",
            "download_location": "https://api.unsplash.com/photos/yYI4OVImGo0/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 41,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "Y8riNmDq4SU",
            "updated_at": "2021-06-27T08:52:53-04:00",
            "username": "introspectivedsgn",
            "name": "Erik Mclean",
            "first_name": "Erik",
            "last_name": "Mclean",
            "twitter_username": "introspectivenl",
            "portfolio_url": "http://erikallen920.redbubble.com",
            "bio": "Donations are welcome! paypal.me/Introspectivedsgn \r\n& Feel free to reach out if you wish to purchase selling rights.  Give me a follow on instagram @introspectivedsgn",
            "location": null,
            "links": {
                "self": "https://api.unsplash.com/users/introspectivedsgn",
                "html": "https://unsplash.com/@introspectivedsgn",
                "photos": "https://api.unsplash.com/users/introspectivedsgn/photos",
                "likes": "https://api.unsplash.com/users/introspectivedsgn/likes",
                "portfolio": "https://api.unsplash.com/users/introspectivedsgn/portfolio",
                "following": "https://api.unsplash.com/users/introspectivedsgn/following",
                "followers": "https://api.unsplash.com/users/introspectivedsgn/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1605586339247-f9d24f56b74eimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1605586339247-f9d24f56b74eimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1605586339247-f9d24f56b74eimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "introspectivedsgn",
            "total_collections": 60,
            "total_likes": 298,
            "total_photos": 10360,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": "Canon",
            "model": "Canon EOS 6D",
            "exposure_time": "1/2500",
            "aperture": "2.5",
            "focal_length": "50.0",
            "iso": 200
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 198294,
        "downloads": 1135
    },
    {
        "id": "gMmY_UBaLbM",
        "created_at": "2021-06-09T15:40:44-04:00",
        "updated_at": "2021-06-27T05:25:47-04:00",
        "promoted_at": "2021-06-11T07:27:02-04:00",
        "width": 7952,
        "height": 5304,
        "color": "#262626",
        "blur_hash": "LADvl-xut-%#,C_3xuNG~BRPIAxZ",
        "description": "DJI Mavic Mini",
        "alt_description": null,
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623267497131-4922e9d70fb8?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623267497131-4922e9d70fb8?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623267497131-4922e9d70fb8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623267497131-4922e9d70fb8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623267497131-4922e9d70fb8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/gMmY_UBaLbM",
            "html": "https://unsplash.com/photos/gMmY_UBaLbM",
            "download": "https://unsplash.com/photos/gMmY_UBaLbM/download",
            "download_location": "https://api.unsplash.com/photos/gMmY_UBaLbM/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 10,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "X0BZWKSrP2A",
            "updated_at": "2021-06-23T18:23:49-04:00",
            "username": "arvndvisual",
            "name": "Aravind Kumar",
            "first_name": "Aravind",
            "last_name": "Kumar",
            "twitter_username": null,
            "portfolio_url": null,
            "bio": "Photographer I Motion Graphics Artist I Instagram : @arvndvisual",
            "location": null,
            "links": {
                "self": "https://api.unsplash.com/users/arvndvisual",
                "html": "https://unsplash.com/@arvndvisual",
                "photos": "https://api.unsplash.com/users/arvndvisual/photos",
                "likes": "https://api.unsplash.com/users/arvndvisual/likes",
                "portfolio": "https://api.unsplash.com/users/arvndvisual/portfolio",
                "following": "https://api.unsplash.com/users/arvndvisual/following",
                "followers": "https://api.unsplash.com/users/arvndvisual/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1523858052312-7e4549ee66d9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1523858052312-7e4549ee66d9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1523858052312-7e4549ee66d9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "arvndvisual",
            "total_collections": 0,
            "total_likes": 30,
            "total_photos": 35,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": null,
            "model": null,
            "exposure_time": null,
            "aperture": null,
            "focal_length": null,
            "iso": null
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 121996,
        "downloads": 710
    },
    {
        "id": "u1edt5V0ruU",
        "created_at": "2021-06-10T13:14:11-04:00",
        "updated_at": "2021-06-26T21:23:37-04:00",
        "promoted_at": "2021-06-11T09:45:02-04:00",
        "width": 5392,
        "height": 3033,
        "color": "#0c8cd9",
        "blur_hash": "LsGc1gIoofe.1Rs,jZjZ$djuV@kB",
        "description": "Crazy colorful cloud during sunset in Funchal, Madeira",
        "alt_description": null,
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623345109565-270bd49cd033?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623345109565-270bd49cd033?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623345109565-270bd49cd033?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623345109565-270bd49cd033?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623345109565-270bd49cd033?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/u1edt5V0ruU",
            "html": "https://unsplash.com/photos/u1edt5V0ruU",
            "download": "https://unsplash.com/photos/u1edt5V0ruU/download",
            "download_location": "https://api.unsplash.com/photos/u1edt5V0ruU/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 54,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "GS6-v-CrRLE",
            "updated_at": "2021-06-27T10:13:09-04:00",
            "username": "daniele_franchi",
            "name": "Daniele Franchi",
            "first_name": "Daniele",
            "last_name": "Franchi",
            "twitter_username": null,
            "portfolio_url": "https://danielefranchi.com/on-film",
            "bio": "I prefer to shoot than be shooted  📸📸📸\r\nFree pics for your project! 🌅🌇🎇  Small donation, huge appreciation paypal.me/DanieleFranchi   👋",
            "location": "Funchal, Portugal ",
            "links": {
                "self": "https://api.unsplash.com/users/daniele_franchi",
                "html": "https://unsplash.com/@daniele_franchi",
                "photos": "https://api.unsplash.com/users/daniele_franchi/photos",
                "likes": "https://api.unsplash.com/users/daniele_franchi/likes",
                "portfolio": "https://api.unsplash.com/users/daniele_franchi/portfolio",
                "following": "https://api.unsplash.com/users/daniele_franchi/following",
                "followers": "https://api.unsplash.com/users/daniele_franchi/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1573310612738-1989536f6864image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1573310612738-1989536f6864image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1573310612738-1989536f6864image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": null,
            "total_collections": 0,
            "total_likes": 545,
            "total_photos": 150,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": null,
            "model": null,
            "exposure_time": null,
            "aperture": null,
            "focal_length": null,
            "iso": null
        },
        "location": {
            "title": "Funchal, Portugal",
            "name": "Funchal, Portugal",
            "city": "Funchal",
            "country": "Portugal",
            "position": {
                "latitude": 32.666933,
                "longitude": -16.924055
            }
        },
        "views": 222362,
        "downloads": 1880
    },
    {
        "id": "pi_omHu5ujM",
        "created_at": "2021-06-11T01:00:29-04:00",
        "updated_at": "2021-06-26T19:23:45-04:00",
        "promoted_at": "2021-06-15T16:21:01-04:00",
        "width": 2491,
        "height": 3736,
        "color": "#0c2626",
        "blur_hash": "L66axF#R0eEfY5IoOswdE1i_?Ijs",
        "description": null,
        "alt_description": null,
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623387417661-c7d8fa7e1820?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623387417661-c7d8fa7e1820?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623387417661-c7d8fa7e1820?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623387417661-c7d8fa7e1820?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623387417661-c7d8fa7e1820?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/pi_omHu5ujM",
            "html": "https://unsplash.com/photos/pi_omHu5ujM",
            "download": "https://unsplash.com/photos/pi_omHu5ujM/download",
            "download_location": "https://api.unsplash.com/photos/pi_omHu5ujM/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 24,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "msXc_PQ7Y9s",
            "updated_at": "2021-06-27T05:27:47-04:00",
            "username": "nakie",
            "name": "Nakie Hammock",
            "first_name": "Nakie",
            "last_name": "Hammock",
            "twitter_username": "",
            "portfolio_url": "https://www.nakie.co/?ref=unsplash",
            "bio": "🌍  Putting Nature First\r\n♻️  Worlds First Recycled Hammock",
            "location": null,
            "links": {
                "self": "https://api.unsplash.com/users/nakie",
                "html": "https://unsplash.com/@nakie",
                "photos": "https://api.unsplash.com/users/nakie/photos",
                "likes": "https://api.unsplash.com/users/nakie/likes",
                "portfolio": "https://api.unsplash.com/users/nakie/portfolio",
                "following": "https://api.unsplash.com/users/nakie/following",
                "followers": "https://api.unsplash.com/users/nakie/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1599957870316-d7c159e541ceimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1599957870316-d7c159e541ceimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1599957870316-d7c159e541ceimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "nakie.co",
            "total_collections": 0,
            "total_likes": 0,
            "total_photos": 47,
            "accepted_tos": true,
            "for_hire": false
        },
        "exif": {
            "make": "SONY",
            "model": "ILCE-7M3",
            "exposure_time": "1/1250",
            "aperture": "2.2",
            "focal_length": "16.0",
            "iso": 640
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 116321,
        "downloads": 569
    },
    {
        "id": "cgGMg_PAjM4",
        "created_at": "2021-06-12T22:25:31-04:00",
        "updated_at": "2021-06-26T18:59:35-04:00",
        "promoted_at": "2021-06-13T05:21:02-04:00",
        "width": 6666,
        "height": 10000,
        "color": "#590c0c",
        "blur_hash": "LB7S+~R,1K$h^2S3EPxEt4jtR,W:",
        "description": null,
        "alt_description": null,
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623551072239-8fe67fac9e58?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623551072239-8fe67fac9e58?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623551072239-8fe67fac9e58?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623551072239-8fe67fac9e58?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623551072239-8fe67fac9e58?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/cgGMg_PAjM4",
            "html": "https://unsplash.com/photos/cgGMg_PAjM4",
            "download": "https://unsplash.com/photos/cgGMg_PAjM4/download",
            "download_location": "https://api.unsplash.com/photos/cgGMg_PAjM4/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 74,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "r-7a3pv5t5U",
            "updated_at": "2021-06-27T10:28:07-04:00",
            "username": "allecgomes",
            "name": "Allec Gomes",
            "first_name": "Allec",
            "last_name": "Gomes",
            "twitter_username": "AllecGomes",
            "portfolio_url": "https://www.instagram.com/allecgomes/",
            "bio": "Photographer & Tech Designer.\r\nMinimalism, colors, textures and poetries are my passions.",
            "location": "Goiânia, Brazil",
            "links": {
                "self": "https://api.unsplash.com/users/allecgomes",
                "html": "https://unsplash.com/@allecgomes",
                "photos": "https://api.unsplash.com/users/allecgomes/photos",
                "likes": "https://api.unsplash.com/users/allecgomes/likes",
                "portfolio": "https://api.unsplash.com/users/allecgomes/portfolio",
                "following": "https://api.unsplash.com/users/allecgomes/following",
                "followers": "https://api.unsplash.com/users/allecgomes/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1590413354417-c1befb996909image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1590413354417-c1befb996909image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1590413354417-c1befb996909image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "allecgomes",
            "total_collections": 2,
            "total_likes": 866,
            "total_photos": 342,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": null,
            "model": null,
            "exposure_time": null,
            "aperture": null,
            "focal_length": null,
            "iso": null
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 140163,
        "downloads": 880
    },
    {
        "id": "21lXbcRzbEQ",
        "created_at": "2021-06-13T09:02:20-04:00",
        "updated_at": "2021-06-26T11:24:05-04:00",
        "promoted_at": "2021-06-13T15:06:01-04:00",
        "width": 3648,
        "height": 5472,
        "color": "#26260c",
        "blur_hash": "LPGI4MIUEM-U9aozW.bI0KofjERk",
        "description": null,
        "alt_description": null,
        "urls": {
            "raw": "https://images.unsplash.com/photo-1623589322406-7d468696964e?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1623589322406-7d468696964e?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1623589322406-7d468696964e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1623589322406-7d468696964e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1623589322406-7d468696964e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/21lXbcRzbEQ",
            "html": "https://unsplash.com/photos/21lXbcRzbEQ",
            "download": "https://unsplash.com/photos/21lXbcRzbEQ/download",
            "download_location": "https://api.unsplash.com/photos/21lXbcRzbEQ/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 36,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "61p6_Zc2KZs",
            "updated_at": "2021-06-27T10:32:57-04:00",
            "username": "_jadoca",
            "name": "Jade Scarlato",
            "first_name": "Jade",
            "last_name": "Scarlato",
            "twitter_username": null,
            "portfolio_url": null,
            "bio": "23, freelancer photographer \r\n @_jadoca  ",
            "location": "Brazil",
            "links": {
                "self": "https://api.unsplash.com/users/_jadoca",
                "html": "https://unsplash.com/@_jadoca",
                "photos": "https://api.unsplash.com/users/_jadoca/photos",
                "likes": "https://api.unsplash.com/users/_jadoca/likes",
                "portfolio": "https://api.unsplash.com/users/_jadoca/portfolio",
                "following": "https://api.unsplash.com/users/_jadoca/following",
                "followers": "https://api.unsplash.com/users/_jadoca/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1595541276418-14c9860ecddfimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1595541276418-14c9860ecddfimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1595541276418-14c9860ecddfimage?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "_jadoca",
            "total_collections": 0,
            "total_likes": 21,
            "total_photos": 158,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": "Canon",
            "model": "Canon EOS 6D",
            "exposure_time": "1/40",
            "aperture": "4.0",
            "focal_length": "41.0",
            "iso": 400
        },
        "location": {
            "title": "São Paulo, São Paulo, Brasil",
            "name": "São Paulo, São Paulo, Brasil",
            "city": "São Paulo",
            "country": "Brasil",
            "position": {
                "latitude": -23.5477336,
                "longitude": -46.6371437
            }
        },
        "views": 268863,
        "downloads": 873
    },
    {
        "id": "-F9tzzUdZEM",
        "created_at": "2021-06-19T03:28:56-04:00",
        "updated_at": "2021-06-27T09:24:47-04:00",
        "promoted_at": "2021-06-20T06:18:03-04:00",
        "width": 6240,
        "height": 4160,
        "color": "#d9d9d9",
        "blur_hash": "LLK-d]00_NRjMwIBaxxvoe9F8_Ri",
        "description": null,
        "alt_description": "white round ceramic plate with white round food",
        "urls": {
            "raw": "https://images.unsplash.com/photo-1624087727464-899613c3811f?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1624087727464-899613c3811f?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1624087727464-899613c3811f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1624087727464-899613c3811f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1624087727464-899613c3811f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/-F9tzzUdZEM",
            "html": "https://unsplash.com/photos/-F9tzzUdZEM",
            "download": "https://unsplash.com/photos/-F9tzzUdZEM/download",
            "download_location": "https://api.unsplash.com/photos/-F9tzzUdZEM/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 13,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "RcPJJXtZp0E",
            "updated_at": "2021-06-27T04:52:41-04:00",
            "username": "lllllatiao",
            "name": "Qinghong Shen",
            "first_name": "Qinghong",
            "last_name": "Shen",
            "twitter_username": "SHEN227",
            "portfolio_url": null,
            "bio": null,
            "location": null,
            "links": {
                "self": "https://api.unsplash.com/users/lllllatiao",
                "html": "https://unsplash.com/@lllllatiao",
                "photos": "https://api.unsplash.com/users/lllllatiao/photos",
                "likes": "https://api.unsplash.com/users/lllllatiao/likes",
                "portfolio": "https://api.unsplash.com/users/lllllatiao/portfolio",
                "following": "https://api.unsplash.com/users/lllllatiao/following",
                "followers": "https://api.unsplash.com/users/lllllatiao/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1606744382392-ca3951d8df91image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1606744382392-ca3951d8df91image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1606744382392-ca3951d8df91image?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "lllllatiao",
            "total_collections": 0,
            "total_likes": 121,
            "total_photos": 183,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": "FUJIFILM",
            "model": "X-T30",
            "exposure_time": "1/600",
            "aperture": "1.6",
            "focal_length": "35.0",
            "iso": 160
        },
        "location": {
            "title": null,
            "name": null,
            "city": null,
            "country": null,
            "position": {
                "latitude": null,
                "longitude": null
            }
        },
        "views": 190699,
        "downloads": 781
    },
    {
        "id": "cDYarwsH4h4",
        "created_at": "2021-06-25T14:59:13-04:00",
        "updated_at": "2021-06-27T10:30:24-04:00",
        "promoted_at": "2021-06-26T14:54:01-04:00",
        "width": 4000,
        "height": 6000,
        "color": "#f3f3f3",
        "blur_hash": "LdL|$8xu%Mt7~qt7NGog_NofM{WC",
        "description": "Couple on the beach.",
        "alt_description": "man and woman standing on beach during daytime",
        "urls": {
            "raw": "https://images.unsplash.com/photo-1624646803808-9c5a9de7aa3f?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1",
            "full": "https://images.unsplash.com/photo-1624646803808-9c5a9de7aa3f?crop=entropy&cs=srgb&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=85",
            "regular": "https://images.unsplash.com/photo-1624646803808-9c5a9de7aa3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=1080",
            "small": "https://images.unsplash.com/photo-1624646803808-9c5a9de7aa3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=400",
            "thumb": "https://images.unsplash.com/photo-1624646803808-9c5a9de7aa3f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg&ixlib=rb-1.2.1&q=80&w=200"
        },
        "links": {
            "self": "https://api.unsplash.com/photos/cDYarwsH4h4",
            "html": "https://unsplash.com/photos/cDYarwsH4h4",
            "download": "https://unsplash.com/photos/cDYarwsH4h4/download",
            "download_location": "https://api.unsplash.com/photos/cDYarwsH4h4/download?ixid=MnwyNDI1MTd8MHwxfHJhbmRvbXx8fHx8fHx8fDE2MjQ4MDQzODg"
        },
        "categories": [],
        "likes": 12,
        "liked_by_user": false,
        "current_user_collections": [],
        "sponsorship": null,
        "user": {
            "id": "j3qWzvA8WV4",
            "updated_at": "2021-06-27T09:12:54-04:00",
            "username": "allentaylorjr",
            "name": "Allen Taylor",
            "first_name": "Allen",
            "last_name": "Taylor",
            "twitter_username": null,
            "portfolio_url": "http://allentaylor.me",
            "bio": null,
            "location": "California",
            "links": {
                "self": "https://api.unsplash.com/users/allentaylorjr",
                "html": "https://unsplash.com/@allentaylorjr",
                "photos": "https://api.unsplash.com/users/allentaylorjr/photos",
                "likes": "https://api.unsplash.com/users/allentaylorjr/likes",
                "portfolio": "https://api.unsplash.com/users/allentaylorjr/portfolio",
                "following": "https://api.unsplash.com/users/allentaylorjr/following",
                "followers": "https://api.unsplash.com/users/allentaylorjr/followers"
            },
            "profile_image": {
                "small": "https://images.unsplash.com/profile-1534786977927-0d1d6ffe3caf?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
                "medium": "https://images.unsplash.com/profile-1534786977927-0d1d6ffe3caf?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64",
                "large": "https://images.unsplash.com/profile-1534786977927-0d1d6ffe3caf?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128"
            },
            "instagram_username": "allentaylorjr",
            "total_collections": 0,
            "total_likes": 0,
            "total_photos": 18,
            "accepted_tos": true,
            "for_hire": true
        },
        "exif": {
            "make": "SONY",
            "model": "ILCE-7M3",
            "exposure_time": "1/160",
            "aperture": "3.2",
            "focal_length": "50.0",
            "iso": 250
        },
        "location": {
            "title": "Carmel Valley, CA, USA",
            "name": "Carmel Valley, CA, USA",
            "city": "Carmel Valley",
            "country": "United States",
            "position": {
                "latitude": 36.479902,
                "longitude": -121.732793
            }
        },
        "views": 135407,
        "downloads": 233
    }
]
            ,
            tmpImageStore:null,
            totalTopicsPages:32,
            topicsPerPage:5,
            topicCurrentPage:1,
            totalSelectedTopicPages:null,
            selectedTopicImagesPerPage:25,
            selectedTopicCurrentPage:1,
            topics:[],
            scrollCount:1,
            selectedTopic:null,
            imageSearch:null,
            Status:"loading..."
            
        }
    },
    mounted(){
        // this.images = this.importimages(require.context('@/assets/wallpapers', false, /\.(png|jpe?g|svg)$/));



        this.getTopics(this.topicCurrentPage).then(data=>this.topics=data).catch(error=>{this.Status="There seems to be an error in loading the wallpaper settings."})

        this.getRandomImages().then(data=>{this.images=[...this.images,...data];})
        // console.log(this.images)
        
        // this.imageScroll()


    },
    watch:{
        imageSearch:function(){
            // console.log(this.imageSearch)
            if(this.imageSearch && !this.tmpImageStore){
                this.tmpImageStore = this.images
            }
            if(this.imageSearch){
                // console.log(this.imageSearch)
                this.searchImages()
            }
            else{
                this.images=this.tmpImageStore
                this.tmpImageStore=null
            }
        }
    },
    methods:{
        colorAndAspectRatio(color,width,height){
            if (width > height) {
                // console.log('landscape');
                return {
                    backgroundColor:color,
                    // gridColumn:"span 2 / auto",
                    // gridRow:"span 2 / auto"
                }

            } else if (width < height) {
                // console.log('portrait');
                return {
                    backgroundColor:color,
                    // gridRow:"span 2 / auto"
                }
            } else {
                console.log('Problem with wallpaper aspect ratio');
                // return "cover"
            }
        },
        searchImages(){
            // console.log("click")
            this.unsplash.search.getPhotos({
              query: this.imageSearch,
              page: 1,
              perPage: 20
            }).then((data)=>{
                var array = []
                data.response.results.forEach((img) => {
                        array.push(img)
                    });
                // console.log(data)
                this.images = array
            })
        },
        getRandomImages(){
            return this.unsplash.photos.getRandom({
            count: 25,
            // orientation:'landscape'
            }).then(data=>{
                console.table(data)
                var array = []
                data.response.forEach((img) => {
                        // var obj = new ImgObject(img.user.first_name,img.user.last_name,img.user.links.html,img.user.profile_image,img.id,img.urls.thumb,img.color)
                        // console.log(obj)
                        
                        array.push(img)
                    });
                    return array
                })
        },
        getTopics(page){
            return this.unsplash.topics.list({
                page: page,
                perPage: this.topicsPerPage
            })
            .then((data)=>{
                // console.log(data);
                if(!this.totalTopicsPages && (this.totalTopicsPages == data.response.results.total)){
                    this.totalTopicsPages = data.response.results.total
                }
                var array=[];
                data.response.results.forEach((topic)=>{
                    // console.log(topic)
                    // console.log(new TopicObject(topic.id,topic.title,topic.slug,topic.links,topic.coverPhoto))
                    array.push(new TopicObject(topic.id,topic.title,topic.slug,topic.links,topic.cover_photo))
                })
                return array
            })
            
        },
        topicNextPage(){
            if ((Math.floor(this.totalTopicsPages/this.topicsPerPage)+1) > this.topicCurrentPage){
                this.topicCurrentPage = this.topicCurrentPage + 1;
                this.getTopics(this.topicCurrentPage).then(data=>this.topics=data)
            }
        },
        topicPreviousPage(){
            if (this.topicCurrentPage >= 2){
                this.topicCurrentPage = this.topicCurrentPage - 1;
                this.getTopics(this.topicCurrentPage).then(data=>this.topics=data)
            }
        },
        clickTopic(topicId){
            // console.log(topicId)
            if (!this.selectedTopic){
                this.selectedTopic = topicId
                this.tmpImageStore = this.images
                this.getTopicPhotos(topicId,1).then(data=>{this.images=data})   
            }
            else if(this.selectedTopic == topicId){
                this.images =  this.tmpImageStore
                this.tmpImageStore = null
                this.selectedTopic = null
                this.selectedTopicCurrentPage = 1
            }
            else if (topicId){
                this.selectedTopic = topicId
                this.totalSelectedTopicsPages = null
                this.selectedTopicCurrentPage = 1
                this.getTopicPhotos(topicId,1).then(data=>{this.images=data})
            }
        },
        getTopicPhotos(topicId,page){
            // console.log(topicId)
            return this.unsplash.topics.getPhotos({
                    topicIdOrSlug: topicId,
                    page:page,
                    perPage: this.selectedTopicImagesPerPage
                }).then(data=>{
                    console.table(data)
                    var array = []
                    if(!this.totalSelectedTopicsPages || (this.totalSelectedTopicsPages != data.response.total)){
                        this.totalSelectedTopicsPages = data.response.total
                    }
                    
                    data.response.results.forEach((img) => {
                        // var obj = new ImgObject(img.user.first_name,img.user.last_name,img.user.links.html,img.user.profile_image,img.id,img.urls.thumb,img.color)
                        // console.log(obj)
                        
                        array.push(img)
                    });
                    return array
                });
        },
        imageScroll(event){
            // console.log("scroll")
            if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && this.scrollCount <= 10) && !this.selectedTopic) {
                    // console.log("hi")
                        this.getRandomImages().then(data=>{
                            // var tmp = this.images
                            // this.images = tmp;
                            // this.images=null
                            // console.log(data)
                            this.scrollCount = this.scrollCount + 1
                            this.images = [...this.images,...data]
                            // console.log(this.images)
                            })
            }
            else if((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) && this.selectedTopic){
                // console.log(this.selectedTopic)
                // console.log(this.totalSelectedTopicsPages)
                // console.log(this.selectedTopicImagesPerPage)
                // console.log(this.selectedTopicCurrentPage)
                // console.log((Math.floor(this.totalSelectedTopicsPages/this.selectedTopicImagesPerPage)+1) > this.selectedTopicCurrentPage)
                if((Math.floor(this.totalSelectedTopicsPages/this.selectedTopicImagesPerPage)+1) > this.selectedTopicCurrentPage){
                    this.selectedTopicCurrentPage = this.selectedTopicCurrentPage + 1;
                    this.getTopicPhotos(this.selectedTopic,this.selectedTopicCurrentPage).then(data=>{this.images = [...this.images,...data]})
                }
            }

            // document.querySelector('.wallpaper-images-grid').onscroll = (event) =>{
            //    
            // }
        },
        removeDuplicates(id,index){
            return this.images.findIndex(img => img.id == id) != index
        },
        importimages(r) {
          return r.keys().map(r);
        },
        clickImage(imageId,imageColor,imageUrl,imageUsername,imageUsernameLink,imageWidth,imageHeight){
          this.$api.put(`/api/settings/update/wallaper`,{wallpaperId:imageId,wallpaperColor:imageColor})
          .then(() => {
                this.$settings.wallpaperId = imageId;
                this.$settings.wallpaperUrl = imageUrl;
                this.$settings.wallpaperUsername = imageUsername;
                this.$settings.wallpaperUsernameLink = imageUsernameLink;
                this.$settings.backgroundColor = imageColor;
                this.$settings.backgroundWidth = imageWidth;
                this.$settings.backgroundHeight = imageHeight
            })
        }
    }
};
</script>

<style>
.sidenav a {
  text-decoration: none;
  font-size: 250px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.wallpaper-text{
  background-color: #111;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.column img {
  vertical-align: middle;
  width: 100%;
}


.wallpaper-settings{
    width:100%;
    height:100%;
    /* background-color: #818181; */
}

.wallpaper-search-container{
    display:flex;
    align-items: center;
    justify-content: center;
    /* background-color: aliceblue; */
    width:100%;
    height:10%;
}

.wallpaper-search-container button{
    height:60%;
}

.wallpaper-search-container input{
    width:50%;
    height:60%;
    border:none;
    border-radius:2px;
    text-align: center;
    outline:none;
    margin-right:auto;
    margin-left:auto;
}
.wallpaper-images-container{
    height:80%;
}
.wallpaper-images-grid-container{
    height:90%;
    overflow-y: auto;
}
.wallpaper-images-grid{
    height:100%;
    padding-left:2%;
    padding-right:2%;
    /* padding-top:3%; */
    display:grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    /* vertical-align: middle; */
    justify-content: center;
    justify-items: center;
    align-content: flex-start;
    /* align-content: center; */
    align-items: center;
    gap:1%;
    /* overflow-y: hidden; */
    grid-auto-rows: 23%;
    position:relative;
}

.wallpaper-image{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:2px;
    box-shadow: black 0 0 20px -10px;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.wallpaper-image img{
    width:auto;
    height:100%;
    max-width: 100%;
    max-height: 100%;
    
}

.wallpaper-author{
    display:inline-flex;
    flex-direction: row;
    align-items: center;
    /* background: linear-gradient(transparent,rgba(207, 105, 22, 0.479)); */
    background: linear-gradient(transparent,rgba(22, 118, 207, 0.479));
    width:100%;
    height:30%;
    position: absolute;
    align-self: flex-end;
}

.wallpaper-author img{
    border-radius: 50%;
    margin-left:5%;
    height:70%;
}

.wallpaper-author a{
    padding:0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left:3%;
    color:white;
    font-size:100%; 
}

.wallpaper-author a:hover{
    text-decoration: underline; 
}

.wallpaper-topics-container{
    width:100%;
    height:10%;
    display:flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgb(167, 167, 167); */
}
.topics{
    display:flex;
    flex-shrink:0;
    flex-grow:1;
    align-items: center;
    justify-content: center;
    width:100%;
    height:60%;
}
.topics .side-button{
    height:100%;
}

.topic{
    display:flex;
    align-items: center;
    justify-content: center;
    /* background-color: black; */
    width:100%;
    height:100%;
}
.topic button{
    border:none;
    border-radius:2px;
    width:90%;
    height:80%;
    transition: all 0.5s;
    box-shadow:0 0 20px -10px;
}
.topic button:hover{
    background-color: #818181;
    color:white;
    border:none;
    border-radius:2px;
    width:90%;
    height:80%;
    box-shadow: black 0 0 20px -10px;
}

.current-wallpaper-details{
    box-shadow: black 0px 0px 10px -5px;
    height:10%;
    width:100%;
    color:var(--text-color)
}

.current-wallpaper-details p{
    display:inline-flex;
    margin:0;
    align-items: center;
    /* justify-content: center; */
    width:100%;
    height:100%;
    margin-left:1%;
}

.current-wallpaper-details p a{
    padding:0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left:0.5%;
    font-weight:bold;
    /* color:white; */
    font-size:100%; 
}

.current-wallpaper-details p a:hover{
    text-decoration: underline; 
}

.list-enter-active, .list-leave-active {
  transition: all 0.5s;
}
.list-enter, .list-leave-to {
    opacity: 0;
}



</style>