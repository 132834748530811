<template>
<button class="view-pdf-button" v-on:click.stop="view($event)">  
<svg viewBox="0 0 52 32" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
  <path d="M 14.477 3.641 H 37.523 A 6.442 6.442 0 0 1 43.965 10.083 V 21.918 A 6.442 6.442 0 0 1 37.523 28.36 H 14.477 A 6.442 6.442 0 0 1 8.035 21.918 V 10.083 A 6.442 6.442 0 0 1 14.477 3.641 Z M 11.003 11.48 V 20.521 A 4.958 4.958 0 0 0 15.961 25.479 H 36.039 A 4.958 4.958 0 0 0 40.997 20.521 V 11.48 A 4.958 4.958 0 0 0 36.039 6.522 H 15.961 A 4.958 4.958 0 0 0 11.003 11.48 Z" bx:shape="frame 8.035 3.641 35.93 24.719 2.968 2.881 6.442 6.442 6.442 6.442 1@4b443db5"></path>
</svg>
</button>
</template>

<script>
var $ = (s, o = document) => o.querySelector(s);



export default {
  props:["fileName","itemId","shared"],
    methods:{
      view(event){
        if(this.shared){
            this.$fileStore.fileStoreApiShared.get(`/url/${this.$props.fileName}/${this.$route.params.id}/${this.$props.itemId}`).then((data)=>{
            // console.log("view",data.data)
            this.$viewFile.file = data.data
            // const link = document.createElement("a");
            // link.href = data.data;
            // link.download = this.$props.fileName;
            // link.click();
            // link.remove()
          })
        }
        else{
            this.$fileStore.fileStoreApi.get(`/url/${this.$props.fileName}/${this.$route.params.id}/${this.$props.itemId}`).then((data)=>{
              // console.log("view",data.data)
              this.$viewFile.file = data.data
              // const link = document.createElement("a");
              // link.href = data.data;
              // link.download = this.$props.fileName;
              // link.click();
              // link.remove()
            })
        }
          

        }
    }
}
</script>
<style>
.view-pdf-button{
    height:32px;
    width:52px;
    background-color: transparent;
    border:none;
    border-radius:5px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0px 0px rgba(0,0,0, 0.2);
    display:flex;
    justify-content: center;
    align-items: center;
}
.view-pdf-button:hover{
    background-color: rgba(0, 0, 0, 0.13);
    /* box-shadow: 0 10px 30px rgba(0,0,0, 0.2);   */
}
.view-pdf-button svg{
    height:100%;
    width:100%;
    stroke:transparent;
    fill:var(--text-color);
}
</style>
