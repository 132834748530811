import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Header from "./layout/Header.vue";
import HeaderNoAuth from "./layout/HeaderNoAuth.vue";
import Flow from "./views/Flow.vue";
import FlowShared from "./views/FlowShared.vue";
import about from "./views/aboutPage.vue"
import Chat from "./views/Chat.vue"
import People from "./views/People/People.vue"
import NotFound from "./views/404.vue"
import ComingSoon from "./views/ComingSoon.vue"




Vue.use(Router);


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      components: { default: Home, header: Header }
    },
    {
      path: "/flow/:id",
      name: "flow",
      components: { default: Flow, header: Header },
      props: true,
      beforeEnter: (to, from, next) => {
          Vue.prototype.$api.get(`/api/flows/`+to.params.id).then((data)=>{
            if (data.data.length){
              to.params.title = data.data[0].title;
              next()
            }
            else{
              next({name:'NotFound',params:{catchAll:'404'}})
            }
          });
      }
    },
    {
      path: "/flow/shared/:id",
      name: "flowShared",
      components: { default: FlowShared, header: Header },
      props: true,
      beforeEnter: (to, from, next) => {
        Vue.prototype.$apiShared.get(`/api/sharing/flows/`+to.params.id).then((data)=>{
          if (data.data.length){
            // console.log()
            to.params.title = data.data[0].title;
            next()
          }
          else{
            next({name:'NotFound',params:{catchAll:'404'}})
          }
        });
      }
    },
    {
      path: "/chat",
      name: "chat",
      meta:{ authOptional:true },
      components: { default: Chat, header: Header, headerNoAuth: HeaderNoAuth },
    },

    {
      path: "/calender",
      name: "calender",
      components: { default: ComingSoon, header: Header } 
    },
    {
      path: "/about",
      name: "about",
      meta:{ authOptional:true },
      components: { default: about }
    },
    {
      path: "/people",
      name: "people",
      meta:{ authOptional:false },
      components: { default: People, header:Header }
    },
    { 
      path: '/:catchAll(.*)', 
      name: 'NotFound',
      components: {default: NotFound}
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(!Vue.prototype.$loggedIn){
    if(to.meta.authOptional){
      next()
    }
    else{
      router.push("about")
    }
  }
  else{
    next()
  }
})

export default router