// import "vue-material/dist/vue-material.min.css";
// import "@/assets/scss/material-kit.scss";
import "@/assets/scss/flow.scss";
import "@/assets/css/main.css";
import "@/assets/css/helpers.css";
import "@/assets/css/transitions.css";
import globalDirectives from "./globalDirectives";
import globalMixins from "./globalMixins";
import globalComponents from "./globalComponents";

export default {
  install(Vue) {
    Vue.use(globalDirectives);
    Vue.use(globalMixins);
    Vue.use(globalComponents);
  }
};
