//TODO : set loading indicator by use of array of requests.


import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const apiShared = axios.create({
    baseURL: `${process.env.VUE_APP_API_SHARE}`
  })

// // const requestList = []
// var requestsPending = 0


// api.interceptors.request.use(function (config) {
//     // console.log("before", config)
//     const notification = document.getElementById("user-notification")
//     // console.log(notification)
//     notification ? notification.classList.add("loading"): {}
//     config?.indicator?.classList.add("loading")
//     config.id = uuidv4();
//     config.status = "processing"
//     // console.log("after", config)
//     // requestList.push(config)
//     requestsPending++
//     return config;
//   }, function (error) {
//     // console.log("error", error)
//     const notification = document.getElementById("user-notification")
//     // console.log(notification)
//     notification ? notification.classList.remove("loading") : {}
//     error.config?.indicator?.classList.remove("loading")
//     notification ? notification.style.backgroundColor = "red" : "";
//     error.config?.indicator?.style.backgroundColor("red")
    
//     return Promise.reject(error);
//   });


//   api.interceptors.response.use(function (response) {
//     requestsPending--
//     // console.log(requestList[3].id == response.config.id)
//     // console.log(response.config.id)
//     // var found = requestList.find((request) => {return response.config.id == request.id})
//     // console.log(found)
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     // console.log("response", response)
//     if(!requestsPending){
//     const notification = document.getElementById("user-notification")
//     // console.log(notification)
//     notification ? notification.classList.remove("loading") : {}
//     response.config.indicator?.classList.remove("loading")
//     }

//     return response;
//   }, function (error) {
//     requestsPending--
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     // console.log("error", error)
//     const notification = document.getElementById("user-notification")
//     const err = error.config?.indicator
//     // console.log(notification)
//     notification ? notification.classList.remove("loading") : {}
//     error.config?.indicator?.classList.remove("loading")
//     notification ? notification.style.backgroundColor = "red" : {}
//     err ? err.style.backgroundColor = "red" : {}
//     return Promise.reject(error);
//   });

  export default apiShared;