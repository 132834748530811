<template>
<div class="Storage">
    <div class="Storage-inside">
    <div class="circle-big">
        <div class="text">{{storageUsed}} Gib<div class="small">9.8 Gib</div>
		</div>
	</div>
    <div class="circle-big">
		<div class="text">
			{{$storageInfo.fileCount}} /<div class="small">1000 files</div>
		</div>
	</div>
    <div class="circle-big">
		<div class="text" style="display:flex;gap:10px;align-items:center;">
		   <div class="small">{{"Storage available : "}}</div>  {{(9.8 - storageUsed).toFixed(2) + " Gib"}}
		</div>
		<div class="text" style="display:flex;gap:10px;align-items:center;">
		   <div class="small">{{"File capacity available : "}}</div>  {{1000 - $storageInfo.fileCount}}
		</div>
	</div>
    </div>
</div>
</template>

<script>
export default {
    computed:{
        storageUsed:function(){
            return (this.$storageInfo.storageUsed / 1.074e+9).toFixed(2)
        }
    }
}
</script>

<style>


.Storage {
  width: 100%;
  height:100%;
  border-radius: 5px;
  box-shadow: .5rem .5rem 1rem rgba(0, 0, 0, 0.6);
  background: #413951;
  background: linear-gradient(145deg, #413951 0%, #201c29 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#413951', endColorstr='#201c29',GradientType=0 );
  color: #fff;
  font-family: 'montserrat', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Storage h2{
    margin:0;
}

.Storage-inside{
    width:95%;
    height:100%;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap:30px;
}
</style>