<template>
  <div class="theme-switch">

        <input type="checkbox" id="toggle" class="toggle--checkbox">
        <label for="toggle" class="toggle--label"  v-on:click="toggleTheme($event)">
          <span class="toggle--label-background"></span>
        </label>
        <div class="background"></div>

  </div>
</template>
<script>

export default {
    mounted(){
        // console.log(this.$settings.theme)
        document.querySelector(".toggle--checkbox").checked = (this.$settings.theme == "dark")
        document.documentElement.setAttribute('data-theme', this.$settings.theme);;
    },
    methods:{
        toggleTheme() {
            this.$settings.theme = this.$settings.theme == 'dark' ? 'light' : 'dark'; //toggles theme value
            this.$api.put(`/api/settings/update/theme`,{theme:this.$settings.theme}).then(()=> document.documentElement.setAttribute('data-theme', this.$settings.theme)) // updates the data-theme attribute 
            
        }
    }
}
</script>

<style>
.theme-switch{
    display: flex;
    justify-content: center;
    align-items: center;
    width:960px;
    height:87.2px;
    /* aspect-ratio: 10/1; */
}
.switch{
    height:100%;
    width:100%;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(-90deg, #111 50%,rgb(238, 237, 237) 50%);
  background-position: 0% 0%;
  background-size: 200% 200%;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-in;
}

.toggle--checkbox {
  display: none;
}
.toggle--checkbox:checked {
    color:transparent; /**just a placeholder**/
  /** This will all flip from sun to moon **/
  /** Change the label color **/
}
.toggle--checkbox:checked ~ .background {

  background-position: 100% 0%;
}
.toggle--checkbox:checked + .toggle--label {
  background: var(--indigo-color);
  border-color: var(--indigo-border);
  /** Change the cloud to stars **/
  /** Change the sun into the moon **/
  /** Show the dimples on the moon **/
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background {
  left: 25%;
  width: 5%;
  height:10%;
  top:50%;
  border-radius:2px;
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background:before {
  width: 40%;
  height: 40%;
  top: -15%;
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background:after {
  width: 65%;
  height: 50%;
  left: -30%;
  top: -300%;
}
.toggle--checkbox:checked + .toggle--label:before {
  background: var(--white);
  border-color: var(--gray-border);
  animation-name: switch;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
}
.toggle--checkbox:checked + .toggle--label:after {
  transition-delay: 350ms;
  opacity: 1;
}
.toggle--label {
  /** Placeholder element, starting at blue **/
  width: 10%;
  height: 50%;
  background: var(--blue-color);
  border-radius: 100px;
  display: flex;
  position: relative;
  transition: all 350ms ease-in;
  /** The sun cloud and moon stars **/
  /** Sun/Moon element **/
  /** Gray dots on the moon **/
}
.toggle--label-background {
  width: 7%;
  height: 6%;
  border-radius: 5px;
  position: relative;
  background: var(--white);
  left: 70%;
  top: 45%;
  transition: all 150ms ease-in;
}
.toggle--label-background:before {
  content: "";
  position: absolute;
  top: -200%;
  width: 450%;
  height: 100%;
  border-radius: 5px;
  background: var(--white);
  left: -250%;
  transition: all 150ms ease-in;
}
.toggle--label-background:after {
  content: "";
  position: absolute;
  top: 170%;
  width: 300%;
  height: 150%;
  border-radius: 5px;
  background: var(--white);
  left: -200%;
  transition: all 150ms ease-in;
}
.toggle--label:before {
  animation-name: reverse;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  transition: all 350ms ease-in;
  content: "";
  width: 31%;
  height: 70%;
  border: .5vh solid var(--yellow-border);
  top: 4%;
  position: absolute;
  border-radius: 82px;
  background: var(--yellow-background);
}
.toggle--label:after {
  transition-delay: 0ms;
  transition: all 250ms ease-in;
  position: absolute;
  content: "";
  box-shadow: var(--gray-dots) -0.75vw 0 0 0.2vh, var(--gray-dots) -0.21vw 0.5vw 0 0.01vh;
  left: 85%;
  top: 23%;
  width: 6%;
  height: 12%;
  background: transparent;
  border-radius: 50%;
  opacity: 0;
}

@keyframes switch {
  0% {
    left: 1%;
  }
  60% {
    left: 4%;
    width: 90%;
  }
  100% {
    left: 57%;
    width: 31%;
  }
}
@keyframes reverse {
  0% {
    left: 55%;
    /* width: 32px; */
  }
  60% {
    left: 4%;
    width: 90%;
  }
  100% {
    left: 1%;
  }
}
</style>