<template>
  <div>
        <section class="worklet">
            <div class="gain-controls">
              <audio-channel v-model="leftGain" :level="leftChannelLevel" @update="updateLeftGain" label="Left" />
              <audio-channel v-model="rightGain" :level="rightChannelLevel" @update="updateRightGain" label="Right" />
            </div>
            <div class="startStop">
              <button
                class="button"
                :disabled="!audioWorkletEnabled || !webAudioEnabled || micRunning"
                @click.prevent="fileRunning ? stopAudio() : startAudio(createFileSource)"
              >
                {{ fileRunning ? 'Stop Audio Test' : 'Start Audio Test' }}
              </button>
              <button
                class="button"
                :disabled="!audioWorkletEnabled || !webAudioEnabled || !getUserMediaEnabled || fileRunning"
                @click.prevent="micRunning ? stopAudio() : startAudio(createMicSource)"
              >
                {{ micRunning ? 'Stop Mic Test' : 'Start Mic Test' }}
              </button>
            </div>
          </section>
      </div>
</template>

<script>
import audioFileUrl from '../../assets/audio/he6_getready_break.ogg'
import GainWorklet from '../../worklet/GainWorklet.js'
import AudioChannel from "../../components/AudioChannel.vue"
export default {
components:{
    AudioChannel,
},
data(){
    return{
        audioContext: null,
        analysers: null,
        gainWorkletNode: null,
        visualizationEnabled: true,
        rightGain: 0.5,
        rightChannelLevel: 0,
        leftGain: 0.5,
        leftChannelLevel: 0,
        source: null,
        loop: true
    }
},
computed: {
    audioRunning() {
      return this.audioContext ? this.audioContext.state === 'running' : false
    },
    fileRunning() {
      return this.audioRunning && this.source && this.source[Symbol.toStringTag] === 'AudioBufferSourceNode'
    },
    micRunning() {
      return this.audioRunning && this.source && this.source[Symbol.toStringTag] === 'MediaStreamAudioSourceNode'
    }
  },
created() {
    this.audioWorkletEnabled = this.detectAudioWorklet()
    this.webAudioEnabled = this.detectWebAudio()
    this.getUserMediaEnabled = this.detectGum()
},
methods:{
    async startAudio(sourceFunction) {
      this.resetLevels()
      const context = new AudioContext()
      let source = null
      try {
        source = await sourceFunction(context)
      } catch (error) {
        // console.log('error creating audio source', error)
        return
      }
      try {
        await this.setupAudioGraph(context, source)
      } catch (error) {
        // console.log('error creating audio graph', error)
        return
      }
      if (source[Symbol.toStringTag] === 'AudioBufferSourceNode') {
        source.loop = this.loop
        source.start(0)
      }
      // console.log(source)
      this.audioContext = context
      this.source = source
    },
    stopAudio() {
      if (this.audioContext) {
        try {
          this.audioContext.close()
        } catch (error) {
          // console.log('error closing context', error)
        }
        this.audioContext = null
      }
      if (this.animationLoopId) {
        cancelAnimationFrame(this.animationLoopId)
      }
      // console.log(this.source)
      if (this.source[Symbol.toStringTag] === 'MediaStreamAudioSourceNode') {
      this.source.mediaStream.getTracks().forEach(track => track.stop());
      }
      this.rightChannelLevel = 0
      this.leftChannelLevel = 0
      this.analysers = null
      this.source = null
    },
    resetLevels() {
      // reset gain to protect eardrums
      this.rightGain = 0.5
      this.leftGain = 0.5
    },
    async updateLeftGain() {
      if (!this.gainWorkletNode) {
        return
      }
      let gain = await this.gainWorkletNode.parameters.get('gainChannel_0')
      gain.setValueAtTime(this.leftGain, this.audioContext.currentTime)
    },
    async updateRightGain() {
      if (!this.gainWorkletNode) {
        return
      }
      let gain = await this.gainWorkletNode.parameters.get('gainChannel_1')
      gain.setValueAtTime(this.rightGain, this.audioContext.currentTime)
    },
    async createFileSource(context) {
      // Load the audio file
      const audioData = await this.loadAudioClip(audioFileUrl)
      let decodedAudioData = null
      decodedAudioData = await context.decodeAudioData(audioData)
      const fileSource = context.createBufferSource()
      fileSource.buffer = decodedAudioData
      return fileSource
    },
    async createMicSource(context) {
      const constraints = { audio: true }
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      return context.createMediaStreamSource(stream)
    },
    async setupAudioGraph(context, source) {
      // console.log("done")

      // create worklet processor and worklet node
      let gainWorkletNode = null
      await context.audioWorklet.addModule(GainWorklet)
      // console.log("done")

      gainWorkletNode = new AudioWorkletNode(context, 'gain-worklet')
      gainWorkletNode.port.onmessage = event => {
        // console.log('Worklet Message:', event.data.msg)
      }
      // console.log("done")
      this.gainWorkletNode = gainWorkletNode
      // Connect the source node to the worklet
      source.connect(gainWorkletNode)
      // Create a splitter for the visualization
      const splitter = context.createChannelSplitter(source.channelCount)
      // Connect the worklet to the splitter
      gainWorkletNode.connect(splitter)
      // Connect the worklet to the destination output (this is what you hear)
      gainWorkletNode.connect(context.destination)
      // Add visualization that shows the gain for each channel
      if (this.visualizationEnabled) {
        this.analysers = new Map()
        for (let i = 0; i < source.channelCount; i++) {
          let analyser = context.createAnalyser()
          analyser.fftSize = 128
          analyser.minDecibels = -70
          analyser.maxDecibels = -25
          analyser.smoothingTimeConstant = 0.8
          this.analysers.set(i, analyser)
          splitter.connect(analyser, i, 0)
        }
        // Start the animations
        this.updateLevels()
      }
    },
    updateLevels() {
      for (let [key, analyser] of this.analysers) {
        let buffer = new Uint8Array(analyser.frequencyBinCount)
        analyser.getByteFrequencyData(buffer)
        let maxVal = 0
        for (let i = 0; i < analyser.frequencyBinCount; i++) {
          maxVal = Math.max(maxVal, buffer[i])
        }
        if (key === 0) {
          this.leftChannelLevel = maxVal
        } else {
          this.rightChannelLevel = maxVal
        }
      }
      this.animationLoopId = requestAnimationFrame(this.updateLevels)
    },
    async loadAudioClip(url) {
      const response = await fetch(url)
      return await response.arrayBuffer()
    },
    detectAudioWorklet() {
      let context = new OfflineAudioContext(1, 1, 44100)
      return Boolean(context && context.audioWorklet && typeof context.audioWorklet.addModule === 'function')
    },
    detectWebAudio() {
      return !!(window.webkitAudioContext || window.AudioContext)
    },
    detectGum() {
      return 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices
    }
}
}
</script>

<style>
.gain-controls{
    display:grid;
    grid-template-columns: repeat(2,1fr);
    /* background-color:black; */
    justify-items: center;
    width:100%;
}
</style>