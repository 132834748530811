<template>
  <div class="add-flow-container">
      <h3>Add a flow named,</h3>
      <div class="inline">
          <input type="text" placeholder="name" v-model="flowToAdd" v-on:keyup.enter="$refs.add.click()">
          <h3>.</h3>
      </div>
      <div class="add-flow-button-container">
          <button :disabled="!flowToAdd" ref="add" v-on:click="addFlow()">Add Flow</button>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            flowToAdd:null
        }
    },
    methods:{
        addFlow(){
            this.$api.post(`/api/flows/add`,{
                title:this.flowToAdd
            }).then(data=>{
                // console.log(data)
                this.flowToAdd=null
                this.$emit("add",data.data)
            })
        }
    }
}
</script>

<style>

.add-flow-container{
    width:100%;
    height:100%;
    /* background-color: aqua; */
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position:relative;
}
.add-flow-container h3{
    margin:0;
    color:var(--text-color);
    transition:all 1s ease-in-out;
}
.inline{
    margin-top:2%;
    height:auto;
    display:inline-flex;
    align-items: center;
    
}

.inline input{
    text-align: center;
    border:none;
    height:60%;
    margin-right:1.5%;
    outline:none;
    border-bottom: 2px solid black;
    background-color: transparent;
    color:var(--text-color);
}
.add-flow-button-container{
    position: absolute;
    bottom:25%;
    height:7%;
    width:60%;
}
.add-flow-button-container button{
    transition: all 0.2s ease-in-out;
    width:100%;
    height:100%;
    text-align:center;
    border:none;
    background-color: rgb(255, 255, 255);
    outline:none;
    border-radius :4px;
    align-self: flex-end ;

}


</style>