<template>
    <div id="about">
      <div class="about-top" v-if="!$loggedIn">
          <h3 class="login hov" v-on:click="signin()">Sign In</h3>
          <h3 style="width:10%;font-weight:bold">/</h3>
          <h3 class="login hov" v-on:click="signup()">Sign Up</h3>
      </div>
      <div class="about-top logged-in" v-else>
          <button v-on:click="$router.go(-1)" class="back-button"></button>
      </div>
      <div class="banner"  >
        <br>
        <div class="stuff flex" >
          <h1 class="banner-stuff" v-scrollanimation>cubeItDone</h1>
          <img class="image" src="../assets/img/logo.png" v-scrollanimation>
       </div>
      </div>
      <div class="banner2"  >
        <br>
          <div class="stuff" >
          <br>
          <div class="banner-stuff2">
            <h1 v-scrollanimation>A platform built, just for you.</h1>
            <h3 v-scrollanimation>cubeItDone is being built to be a more responsible platfrom that is solely focused on balance and productivity for everyone.</h3>
          </div>

       </div>
      </div>
      <!-- <div class="banner2"  >
        <br>
          <div class="stuff" >
          <h3>cubeItDone is being built to be a more responsible platfrom that is solely focused on balance and productivity for everyone.</h3>
       </div>
      </div> -->
      
      <div class="infos" >
        <div class="stuff" >
        <button class="info-side" v-on:click="index = index - 1" v-scrollanimation><img src="../assets/img/arrow-left.png" alt=""></button>
        <div class="info-stuff" v-scrollanimation>
          <div class="sliders"><img class="slider-images" :src="image[index]"><div class="slider-text-contain"><h2 class="slider-text" >{{text[index]}}</h2><h3>*design subject to change</h3 ></div></div>
        </div>
        <button class="info-side" v-on:click="index = index + 1" v-scrollanimation><img src="../assets/img/arrow-right.png" alt=""></button>
        </div>
        </div>
      <div class="signup" v-if="!$loggedIn">
        <div class="stuff" >
          <div class="signup-stuff">
        <h1 class="signup-stuff-text" v-scrollanimation>signup to give it a go!</h1>
        <div class="signup-stuff-buttons"><div v-scrollanimation ><button class="signup-stuff-button" v-on:click="signup()">Sign up</button></div></div>
          </div>
        </div>
      </div>
    </div>
</template>
 <script>
import  img1 from "@/assets/about/img1.png";
import  img2 from "@/assets/about/img2.png";
import  img3 from "@/assets/about/img3.png";
 export default {
   methods:{
     signup(){
       window.location.href='https://login.cubeitdone.com/auth/realms/cubeItDone/protocol/openid-connect/registrations?client_id=website&response_type=code&scope=openid%20email&redirect_uri=https://cubeitdone.com'
     },
     signin(){
      //  console.log(`${this.$loginURL}`)
       window.location.href=`${this.$loginURL}` 
     }
   },
   watch:{
     index:function(){
       if (this.index == -1){
         this.index = 2
       }
       if (this.index == 3){
         this.index = 0
       }
     }
   },
    data(){
      return{
      index:0,
      text:['Your everyday at a glance','Keep track of projects','Color code items'],
      image:[img1,img2,img3]
      }
    }

}
 </script>
<style scoped>
#about{
  height:100vh;
  /* background-image: url("../assets/about/landscape.jpg"); */
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  /* overflow-x: hidden; */
  overflow-y:auto;
  width:100vw;
  top:0;
  position: absolute;
}
::-webkit-scrollbar-track {
    background-color: rgb(93, 93, 93);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(167, 167, 167);
    border-radius: 10px;
}
.about-top{
  height:10vh;
  width:100%;
  position: sticky;
  top:0;
  z-index:1000;
  display:flex;
  background-color: rgb(0, 0, 0);
  justify-content: center;
  align-items: center;
  gap:10em;
}

.about-top.logged-in{
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.205);
}

.about-top h3{
  cursor:pointer;
  border-radius:5px;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  width:25%;
  height:50%;
}
.about-top .hov:hover{
  cursor:pointer;
  background-color: rgba(122, 200, 255, 0.285);
}
input{
  min-width:300px;
  margin-top:3vh;
}
h1{
  font-family: Arial, Helvetica, sans-serif;
  margin:0px;
  font-size:5vmin;
  letter-spacing: 0.1em;
}
h2{
  margin:0px;
  font-size:5vmin;
}
.image{
  position: absolute;
  width:10vh;
  min-width:70px;
  height:auto;
  bottom:20vh;
  /* inset:auto auto 20% auto; */
}
.stuff{
  width:100%;
  height:100%;
  border-radius: 5px;
}
.flex{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner{
  position: relative;
  color:white;
  text-align: center;
  height:100vh;
  width :100%;
  background-color: rgba(0, 0, 0, 0.205);
}
.banner2{
  background-color: rgba(0, 0, 0, 0.596);
  /* background-image: url("../assets/about/landscape.jpg"); */
  background-blend-mode: multiply;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  position: relative;
  color:white;
  text-align: center;
  height:100vh;
  width :100%;

}
.banner-stuff{
  width:100%;
  bottom:50vh;
}
.banner-stuff2{
  width:100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.infos{
  background-color: rgba(0, 0, 0, 0.205);
  background-image: url("../assets/img/wallpaper.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  height:100vh;
  width :100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.infos .stuff{
  display:flex;
  justify-content: center;
  align-items: center;

}
.info-side{
  background-color: rgba(100, 148, 237, 0);
  width:5%;
  height:100%;
 border:none;
 opacity:0.5;
}
.info-stuff{
  width:89%;
  height:100%;
}
.sliders{
  width:100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.dots{
 height:10%;
 background-color: rgba(0, 255, 255, 0);
}
.signup{
  background-color: rgba(0, 0, 0, 0.205);
  background-image: url("../assets/about/landscape.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  position:relative;
  color:white;
  text-align: center;
  height:100vh;
  width :100%;

}
.signup-stuff{
  width:100%;
}
.signup-stuff-text{
  position:absolute;
  width:100%;
  bottom:30vh;
}
.signup-stuff-buttons{
  position:absolute;
  width:100%;
  bottom:50vh;
}
.signup-stuff-button{
  width:50vmin;
  height:5vmin;
  margin-top:10px;
  font-size: 3vmin;
  background-color: black;
  color:white;
  border:none;
  border-radius: 5px;
}
.slider-images{
  display:block;
  /* margin:auto; */
  width:45vw;
  height:auto;
  border-radius: 40px;

}
.slider-text-contain{
  position:absolute;
  bottom:5vw;
  width:100%;
  margin:0px auto;
}
.slider-text{
  text-align: center;
  color:white;
  font-size:5vmin;

}
.before-enter{
opacity:0;
transform:translateY(5vh);
transition: all 1s ease-in-out;
}
.enter{
opacity:1;
transform:translateY(0px);
transition: all 1s ease-in-out;
}
@media (min-width:370px) and (max-width:812px)
{
  .signup-stuff{
    display:inline;
    padding-top:0;
    vertical-align: bottom;
    padding-right: 10px;
  }

}
/* @media (max-width:475px) and (max-height:850px)
{
    .slider-images{
    display:block;
    width:100%;
    height:auto;
    margin: 1% auto;
  }
  .slider-text{
    display:block;
    text-align: center;
    margin: 5% auto;
    
  }
  .slider-text-contain{
    display:block;
    width:100%;
    height:50%;
}
}
@media (min-height:1024px)
{
    .slider-images{
    display:block;
    width:auto;
    height:75%;
    margin: 1% auto;
  }
  .slider-text{
    display:block;
    text-align: center;
    margin: 0 auto;
    
  }
  .slider-text-contain{
    display:block;
    width:100%;
    height:50%;
    margin: 0 auto;
}
}
@media (max-width:1560px) and (max-height:900px)
{
    .slider-images{
    display:block;
    width:auto;
    height:75%;
    margin: 1% auto;
  }
  .slider-text{
    display:block;
    text-align: center;
    margin: 0 auto;
    
  }
  .slider-text-contain{
    display:block;
    width:100%;
    height:50%;
    margin: 0 auto;
}
}

@media (min-width:1024px) and (min-height:1366px)
{
    .slider-images{
    display:block;
    width:auto;
    height:75%;
    margin: 1% auto;
  }
  .slider-text{
    display:block;
    text-align: center;
    margin: 0 auto;
    
  }
  .slider-text-contain{
    display:block;
    width:100%;
    height:50%;
    margin: 0 auto;
}
} */

</style>

 <!--
<template>
   <div>
     <div class="banner">
                 <img class="image" src="../assets/img/logo.png">
                  <h1>Cube It Done</h1>
                  <h4>A personal platform to help manage your day</h4>





     </div>
     <div class="infos"></div>
     <div class="signup"></div>
   </div>
 </template>
<style scoped>
  h1{
  font-family: Arial, Helvetica, sans-serif;
  margin:0px;
  font-size:5vmin;
  color:white;
  text-align: center;
  }
  h4{
    color:white;
  }
.image{
  width:10vh;
  height:auto;
}
.banner{
  background-color: black;
  height:100vh;
  width:100%;
}
.infos{
  background-color: royalblue;
  height:100vh;
}
.signup{
  height:100vh;
}
.scene {
	position: absolute;
	width: 100%;
	transform: translateY(var(--translateY));
	will-change: transform;
}
</style>-->
