<template>

<div class="main-div">
  <div class=" logo-container"><img class="logo" src="../assets/img/logo.png" v-on:click="home()"></div>
  <!--<h2 class="home" v-on:click="home()">Home</h2>-->
  <h2 class="titel"></h2>
  <h2 class="time"> {{ times }}</h2>
  <h3 class="logout" v-on:click="login()">Sign In</h3>
  <!-- <div class="tool" v-on:mouseover.stop="$state.showtool = true" v-on:mouseleave.stop="$state.showtool = false"><div class="tool-anim"><Tooltips v-bind:showtool="$state.showtool"></Tooltips></div></div> -->
</div>
</template>
<script>
import Tooltips from '../components/tooltips.vue';
export default {
    components: {
        Tooltips
  },
  methods: {
    time() {
      var self = this;
      var d;
      setInterval(() => {
        d = new Date();
        this.times = d.toLocaleTimeString("en-us");
      }, 1000);
    },
    login(){
      window.location.href=`${this.$loginURL}`
    },
    home(){
      window.location.href='https://cubeitdone.com/'
    },
    // settings(){
    //   this.showingsettings == true ? document.getElementById("mySidenav").style.width = '23em':document.getElementById("mySidenav").style.width = '0em';
    // }
  },

  mounted() {
    this.time();
  },
  data() {
    return {
      times: "",
      
      showingsettings:false,
      title:""
    };
  }
};
</script>
<style scoped>
.logo-container{
  height:100%;
  width:10%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #111; */
}
.logo{

  width:auto;
  height:70%;
  transition: all 0.2s ease-in-out;
}
h2{
  margin:0px;
}
.time{
  font-weight:bold;
  display:inline-block;
  height:100%;
  margin:0;
  text-align:right;
  width:20%;
  font-size: 3vh;
  transition: all 0.2s ease-in-out;
}
.titel{
margin:auto;
height:70%;
display:inline-block;
color:white;
text-align:center;
font-size: 5vh;
height:100%;
width:50%;
transition: all 0.2s ease-in-out;

}
.home{
display:inline-block;
text-align:center;
width:10%;

}
.home:hover{
cursor:pointer;
}
.logout{
margin:0px;
display:inline-block;
text-align:center;
width:15%;
height:100%;
font-size: 3vh;
transition: all 0.2s ease-in-out;
}
.logout:hover{
cursor:pointer;
}

.settings{
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;
  margin:0px;
  display:inline-block;
  text-align:center;
  width:7.5%;
  height:100%;
  font-size: 3vh;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.settings:hover{
  cursor:pointer;
  background-color: rgba(122, 122, 122, 0.479)!important;
}
.profile{
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;
  margin:0px;
  display:inline-block;
  text-align:center;
  width:7.5%;
  height:100%;
  font-size: 3vh;
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.profile:hover{
  cursor:pointer;
   background-color: rgba(122, 122, 122, 0.479)!important;
}
.settings-menu{
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  position:absolute;
  right:0px;
  bottom:0px;
  height:90vh;
  width:0;
  z-index:1000;
  transition: all 0.2s ease-in-out;
}

.main-div{
  display:flex;
  width:100%;
  margin: 0px;
  height:10vh;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 0) 100%
  );
  color: white;
}
.tool{
display:inline-block;
width:1%;
border-radius:5px 0px 0px 5px;
margin:0px;
/* background-color: rgb(255, 255, 255); */
background-color:#FEE715FF;
transform:10px 20px;
z-index:20001;
height:100%;
}
.tool-img{
position:absolute;
width:10px;
height:auto;
margin-left:80px;
margin-right:80px;
margin-bottom:10px;
opacity:0.1;
}
.tool-anim{
  pointer-events: none;
  display:unset;
  opacity:0;
  transition: all 0.2s ease;
  background-color: rgba(0, 0, 0, 0.377);
  border-radius:5px;
  width:40vw;
  height:90vh;
  top:10vh;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  right:0px;
  position:fixed;
}
.tool:hover .tool-anim{
  /* width:40vw; */
  opacity:1;
}
.showing{
  width:30%;
  opacity:1;
} 
.filler{
  color:rgba(0, 0, 0, 1);
  position: absolute;
  margin:0px;
}
/* The side navigation menu */
.sidenav {
  height: 90vh;
  /* width: 50%; */
  width:960px;
  position: fixed;
  z-index: 1;
  bottom: 0; 
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  /* transition: 0.5s; */
  z-index:1000;
  box-shadow: 0px 0px 50px black;
  transform: translate(0%);
}

.slide-in-enter-active {
  transition: all 0.5s ease 0s;
}
.slide-in-leave-active {
  transition: all 0.5s ease 0s;
}
.slide-in-enter,
.slide-in-leave-to{
  transform: translate(100%);
}

/* ipad pro Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
          .titel{
    font-size: 2.5vh;
    padding-top:3.6vh;
  }
  .time{
    font-size:1vh;
    padding:0px;
    padding-top:4.3vh;
    text-align: center;
  }
  .logout{
    font-size:2vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .settings{
    font-size:2vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .logo{
    width:auto;
    height:70%;
    /* padding-top:2vh; */
  }

}

/*Ipad Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
      .titel{
    font-size: 2.5vh;
    padding-top:3.6vh;
  }
  .time{
    font-size:2vh;
    padding:0px;
    padding-top:4.3vh;
    text-align: center;
  }
  .logout{
    font-size:2vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .settings{
    font-size:2vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .logo{
   width:auto;
    height:70%;
  }
  }
  /* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
  .titel{
    font-size: 2vh;
    padding-top:4vh;
  }
  .time{
    font-size:1.5vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .logout{
    font-size:1.5vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .settings{
    font-size:1.5vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .logo{
    width:auto;
    height:70%;
  }

}


@media screen and (max-height: 450px) {
  .sidenav a {font-size: 18px;}
}/*
@media (max-width:1140px) {
  .titel{
    font-size: 3vh;
    padding-top:4vh;
  }
  .time{
    font-size:3vh;
    padding:0px;
    padding-top:4vh;
    text-align: center;
  }
  .logout{
    font-size:3vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .settings{
    font-size:3vh;
    padding:0px;
    padding-top:4.5vh;
  }
  .logo{
    width:10vh;
    height:auto;
    padding-top:2vh;
  }
}*/
@media (max-width:610px) {
  .titel{
    font-size: 2vh;
    /* padding-top:4vh; */
  }
  .time{
    font-size:1.5vh;
    padding:0px;
    /* padding-top:4.5vh; */
  }
  .logout{
    font-size:1.5vh;
    padding:0px;
    /* padding-top:4.5vh; */
  }
  .settings{
    font-size:1.5vh;
    padding:0px;
    /* padding-top:4.5vh; */
  }
  .logo{
    width:auto;
    height:70%;
  }
}
</style>
