<template>
<!-- <transition appear name="appear"> -->
  <div class="Flow-main" draggable="true" v-on:drag.stop.prevent v-on:dragstart.stop.prevent>
    <div class="cube-container" id="cube-container" draggable="true" v-on:mousemove="scroll($event)">
      <div class="cube-dropzone cube-outside" v-on:dragenter="cubeDragOver($event)" v-on:dragleave="stopDragOver($event)" v-on:drop="dropCube($event,0,null)" @dragover.prevent></div>
      <template v-for="(cube,cubeIndex) in Cubes">
        <div class="cube" v-bind:key="cube.cube_id" draggable="true" v-on:dragstart="dragCube($event,cube)" v-on:dragend="stopDrag($event)" v-on:drag="cloneCube($event)" :style="cubeToDeleteIndex == cubeIndex ? {backgroundColor:'maroon'}:{}">
        <div class="cube-title-box">
          <!-- <div class="cube-title"><h2>{{cube.title}} {{cube.cube_id}} {{cube.sort}} {{cubeIndex}} {{cubeIndex+1 == Cubes.length}}{{cubeToDeleteIndex}}<button v-on:click="deleteCube(cubeIndex,cube.cube_id)">test delete</button></h2> </div> -->
          <div class="cube-title"><h2>{{cube.title}}</h2> </div>
          <div class="cube-options-button-container">
                    <button class="cube-options-button" v-on:click="toggleCubeOptions($event)" :ref="'cubeToggle'+cube.cube_id">
                    </button>
                    <div class="cube-options-container" v-component-click-outside="{ exclude: ['cubeToggle'+cube.cube_id], handler: 'toggleCubeOptionsWithFunction', arg: cube.cube_id }">
                      <CubeOptions 
                      v-bind:cube="cube"
                      v-on:title="cube.title = $event"
                      :reset="resetElements"></CubeOptions>
                    </div> 
          </div>
        </div>
        <div class="item-container">
          <div class="item-dropzone item-outside" v-on:dragenter="itemDragOver($event)" v-on:dragleave="stopDragOver($event)" v-on:drop="dropItem($event,cube,0)" @dragover.prevent></div>
          <template v-for="(item) in filterItems(cube.cube_id)">
              <div v-bind:key="'itemOptions'+item.id" class="item-options-container-parent">
                <div class="item-options-container" id="item-options-container" v-component-click-outside="{ exclude: ['itemToggle'+item.id ], handler: 'toggleOptionsWithFunction', arg: item.id }">
                      <ItemOptions
                        :id="item.id" 
                        :title="item.title" 
                        :date="item.due_on" 
                        :color="item.color" 
                        :noteData="item.note ? item.note.note : null"
                        :reset="resetElements"
                        v-on:title="item.title = $event"
                        v-on:date="item.due_on = $event; item.due_done = 0;"
                        v-on:color="item.color = $event"
                        v-on:toggle="toggleOptionsWithFunction(item.id)"
                        v-on:note="item.note = $event"
                        v-on:deleteNote="item.note = null"
                        v-on:updateFiles="getFiles()"></ItemOptions> 
              </div>
              </div>
              <div class="item" v-bind:key="item.id" draggable="true" v-on:dragstart="dragItem($event,item)" v-on:dragend="stopDrag($event)" v-on:drag="cloneItem($event)" :style="Items[itemToDeleteIndex] ? Items[itemToDeleteIndex].id == item.id ? {backgroundColor:'maroon'}:{}: {}">
                <div class="item-area">
                  <div class="item-color" :style="(item.note || item.links.length)?{backgroundColor:item.color,color:item.color}:{backgroundColor:item.color,color:item.color,borderRadius:'var(--item-border-radius) 0 0 var(--item-border-radius)'}"></div>
                  <!-- <div class=item-title><h3 :style="Items[itemToDeleteIndex] ? Items[itemToDeleteIndex].id == item.id ? {color:'white'} :{} : {}">{{item.title}} {{item.sort}} {{cubeIndex}} {{item.id}} {{"toDelete "+itemToDeleteIndex}}</h3></div> -->
                  <!-- Stupid ass ternary operator evaluates index of 0 to false -->
                  <div class=item-title><h3 :style="Items[itemToDeleteIndex] ? Items[itemToDeleteIndex].id == item.id ? {color:'white'} :{} : {}">{{item.title}}</h3></div>
                  <div class=item-date :style="item.due_done?{opacity:0.3}:{opacity:1}"><h4 v-if="item.due_on" v-on:click="clickDate(item.id,item.due_done)">{{item.due_on}}</h4></div>
                  <div class="item-options-button-container">
                    <button class="item-options-button" v-on:click="toggleOptions($event)" :ref="'itemToggle'+item.id"></button>
                  </div>
                </div>
                <div class="item-attachments-container" v-bind:key="'attachment'+item.id" v-if="item.note || item.links.length || true">
                  <ItemAttachments :note="item.note" :files="Files[item.id]" :itemId="item.id" v-on:deleteFile="removeFile($event)" :del="true"></ItemAttachments>
                </div>
              </div>
              <div class="item-dropzone" v-bind:key="'itemDropzone'+item.id"  v-on:dragenter="itemDragOver($event)" v-on:dragleave="stopDragOver($event)" v-on:drop="dropItem($event,cube,item.sort)" @dragover.prevent></div>
          </template>
        </div>
        <div class="add-item">
          <AddItem v-on:addItem="addItem($event,cube.cube_id)"></AddItem>
        </div>
      </div>
      <div class="cube-dropzone" v-bind:key="cube.cube_id+'cubeDropzone'" v-on:dragenter="cubeDragOver($event)" v-on:dragleave="stopDragOver($event)" v-on:drop="dropCube($event,cube.sort,null)" @dragover.prevent></div>
      </template>
      <div  class="cube" style="position:sticky;top:0px;">
        <div class="add-cube">
          <AddCube :Cubes="Cubes" v-on:addCube="addCube($event)"></AddCube>
        </div>
      </div>
    </div>
    <div class="flow-actions-container">
      <DashExternals v-bind:Flow="$route.params.id"></DashExternals>
      <DashActions 
      v-on:deleteCube="deleteCube(null,$event.cube_id)" 
      v-on:deleteItem="deleteItem(null,$event.item_id)"
      v-on:toDeleteCube="cubeToDeleteIndex = $event" 
      v-on:toDeleteItem="itemToDeleteIndex = $event" 
      v-bind:Cubes="Cubes" 
      v-bind:Items="Items"></DashActions>
    </div>
  </div>
<!-- </transition> -->
</template>

<script>
var orderBy = require('lodash.orderby');
var cloneDeep = require('lodash.clonedeep');
import ItemAttachments from '../Subviews/ItemAttachment.vue';
import ItemOptions from '../Subviews/Itemoptions.vue';
import CubeOptions from '../Subviews/Cubes/CubeOptions.vue';
import AddCube from '../components/AddCube.vue';
import AddItem from '../components/AddItem.vue';
import DashActions from '../Subviews/DashActions.vue';
import DashExternals from '../Subviews/DashExternals/DashExternals.vue';
export default {
  components:{
    ItemOptions,
    ItemAttachments,
    CubeOptions,
    AddCube,
    AddItem,
    DashActions,
    DashExternals
  },
  data(){
    return{
      Cubes:[],
      Items:[],
      Files:[],
      cubeToDeleteIndex:null,
      itemToDeleteIndex:null,
      optionsActive:null,
      cubeOptionsActive:null,
      resetElements:false,
      x:null,
      y:null,
      isSafari:false
    }
  },
  mounted(){
    // console.log(this.$router)
    // console.log(this.$route)
    this.initialize();
  },
  methods:{
    initialize(){
      this.isSafari = window.safari !== undefined
      // console.log(process)
      Promise.all([
        this.$api.get(`/api/cubes`, { headers: { flow_id: this.$route.params.id } }),
        this.$api.get(`/api/items`, { headers: { flow_id: this.$route.params.id } })
        ]).then((responses) => {
          this.Cubes = orderBy(responses[0].data, ["sort"]);
          // console.log(this.Cubes)
          this.Items = orderBy(responses[1].data, ["sort"]) ;
          // console.log(this.Items)

        })
        this.getFiles()

        
      // this.$api
      //   .get(`/api/cubes`, { headers: { flow_id: this.$route.params.id } })
      //   .then(result => {
      //     this.Cubes = _.orderBy(result.data, ["sort"]);
      //     // console.log(this.Cubes)
      //   });
      // this.$api
      //   .get(`/api/items`, { headers: { flow_id: this.$route.params.id } })
      //   .then(result => {
      //     result.data.forEach((element) => {element.shownote=false, element.showlink=true})
      //     this.Items = _.orderBy(result.data, ["sort"]) ;
      //     // console.log(this.Items)
      //   });
        
    },
    redrawCubes(){
      this.Cubes = orderBy(this.Cubes, ["sort"]);
      // console.table(this.Items,["sort"])
    },
    redrawItems(){
      this.Items = orderBy(this.Items, ["sort"]);
      // console.table(this.Items,["sort"])
    },
    filterItems(cubeId){
      // console.log('filter ' + cubeId)
      var result = this.Items.filter(item => {
        return item.cube_id == cubeId;
      });
      return orderBy(result, ["sort"]);
    },
    filter(items,cubeId){
      // console.log('filter ' + cubeId)
      var result = items.filter(item => {
        return item.cube_id == cubeId;
      });
      return orderBy(result, ["sort"]);
    },
    dragCube(event,cube){
      event.stopPropagation();
      var dropzone = document.querySelectorAll(".cube-dropzone");
      dropzone.forEach((zone)=>{
        zone.style.width="5em";
        
        })
      event.target.previousElementSibling.style.width="0em"
      event.target.nextElementSibling.style.width="0em"
      // console.log(event)
      var clone = event.target.cloneNode(true)
      var image = new Image();
      if (!this.isSafari) event.dataTransfer.setDragImage(image,0,0);
      event.dataTransfer.setData("cubeId",cube.cube_id)
            console.log(event)
      this.x = event.layerX
      this.y = event.layerY
      event.target.style.opacity="0.3" 
      clone.style.position="absolute";
      clone.style.display="none";
      clone.style.pointerEvents="none";
      clone.classList.add("clone")
      document.querySelector(".cube-container").appendChild(clone);
    },
    dragItem(event,item){
      event.stopPropagation();
      var dropzone = document.querySelectorAll(".item-dropzone");
      dropzone.forEach((zone)=>{
        zone.style.height="25px";
      })
      if(event.target.previousElementSibling.previousElementSibling){
        event.target.previousElementSibling.previousElementSibling.style.height="0px"
      }
      else{
        event.target.previousElementSibling.style.height="0px"
      }
      // console.log(event.target.previousElementSibling.previousElementSibling)
      event.target.nextElementSibling.style.height="0px"
      event.target.style.opacity="0.3"
      // console.log(event)
      var clone = event.target.cloneNode(true)
      // console.log(clone)
      var image = new Image();
      if (!this.isSafari) event.dataTransfer.setDragImage(image,0,0);
      event.dataTransfer.setData("cubeId",item.cube_id)
      event.dataTransfer.setData("itemId",item.id)
      this.x = event.layerX
      this.y = event.layerY
      clone.style.position="absolute";
      // console.log(document.querySelector(".item").offsetWidth);
      clone.style.width=document.querySelector(".item").offsetWidth+"px";
      clone.style.opacity="0";
      clone.style.pointerEvents= "none";
      // clone.style.zIndex = "1000";
      clone.classList.add("clone")
      // document.querySelector(".cube").appendChild(clone);
      document.querySelector(".Flow-main").appendChild(clone);
    },
    cloneCube(event){
      event.stopPropagation();
      if (event.pageX == 0 || this.isSafari) return
      document.querySelector(".clone").style.left = event.pageX + document.querySelector(".Flow-main").scrollLeft - 20 - this.x + "px";
      document.querySelector(".clone").style.top = event.pageY + document.querySelector(".Flow-main").scrollTop - 20 - this.y + "px";
      // document.querySelector(".clone").style.left = event.pageX + document.querySelector(".Flow-main").scrollLeft - 300 + "px";
      // document.querySelector(".clone").style.top = event.pageY + document.querySelector(".Flow-main").scrollTop - 50 + "px";
      document.querySelector(".clone").style.display="block" //this prevents weird glitches
    },
    cloneItem(event){
      event.stopPropagation();
      if (event.pageX == 0 || this.isSafari) return
      document.querySelector(".clone").style.left = event.pageX + document.querySelector(".Flow-main").scrollLeft - this.x + "px";
      document.querySelector(".clone").style.top = event.pageY + document.querySelector(".Flow-main").scrollTop - this.y + "px";
      // document.querySelector(".clone").style.left = event.pageX + document.querySelector(".Flow-main").scrollLeft - 250 + "px";
      // document.querySelector(".clone").style.top = event.pageY + document.querySelector(".Flow-main").scrollTop - 25 + "px";
      document.querySelector(".clone").style.opacity="1" //this prevents weird glitches
    },
    stopDrag(event){
      event.stopPropagation();
      // document.querySelector(".clone").style.display="none";
      document.querySelector(".clone").remove();
      event.target.style.opacity="1"
      var cubeDropzone = document.querySelectorAll(".cube-dropzone");
      cubeDropzone.forEach((zone)=>{
        zone.style.width="0em";
        })
      var itemDropzone = document.querySelectorAll(".item-dropzone");
      itemDropzone.forEach((zone)=>{
        zone.style.height="0px";
        })
    },
    cubeDragOver(event){
      event.preventDefault()
      event.target.style.width = "500px"
    },
    itemDragOver(event){
      event.preventDefault()
      event.target.style.height = (document.querySelector(".clone").offsetHeight + 5)+"px";
    },
    stopDragOver(event){
      if (event.target.classList.contains("item-dropzone")){
        event.target.style.height = "25px";
        return
      }
      event.target.style.width = "5em"
    },
    dropCube(event,position,cubeToAdd){
      var tmp = [...this.Cubes];
      //got to do the above because vue is waaaaayyyy too reactive, the DOM will still update if the below axios request fails
      //better to process this server side
      const cubeId = event.dataTransfer.getData("cubeId")
      const index = this.Cubes.findIndex(cube => cube.cube_id == cubeId )
      var cube = this.Cubes[index]
      tmp.splice(index,1)
      // console.log(tmp)
      // console.log(this.Cubes)
      if ( index < position-1 ){
        tmp.splice(position-1,0,cube)
      }
      else{
        tmp.splice(position,0,cube)
      }
      var i = 1
      tmp.forEach(cube => {
        cube.sort = i
        i = i + 1
      })
      this.$api.put(`/api/cubes/updatesort/all`, { array: orderBy(tmp, ["cube_id"]) }).then(()=>{this.Cubes = tmp});
    },
    dropItem(event,cube,position){
      const itemCubeId = event.dataTransfer.getData("cubeId")
      const itemId = event.dataTransfer.getData("itemId")
      // console.log(itemCubeId)
      // console.log(itemId)
      var tmp = cloneDeep(this.Items)
      if (cube.cube_id != itemCubeId){
        tmp.find(item => item.id == itemId).cube_id = cube.cube_id
      }      
      var items = cloneDeep(this.filter(tmp,cube.cube_id))
      //var items = JSON.parse(JSON.stringify(this.filterItems(cube.cube_id)))
      //got to do the above because vue is waaaaayyyy too reactive, the DOM will still update if the below axios request fails
      //better to process this server side 
      const index = items.findIndex(item => item.id == itemId)
      var item = items[index]
      // console.log(index + ' -> ' + position)
      items.splice(index,1)
      if (index < position-1){
        items.splice(position-1,0,item)
      }
      else{
        items.splice(position,0,item)
      }
      var i = 1
      items.forEach(item => {
        item.sort = i
        i = i + 1
      })
      this.$api.put(`/api/items/updatesort/all`, { array: orderBy(items, ["id"]) }).then((data)=>{data.data.forEach(item => {
        var i = this.Items.find(items => items.id == item.id)
        i.sort = item.sort
        i.cube_id = item.cube_id
      })});
    },
    toggleOptions(event){

      // console.log(event)

      // console.log(event.target.parentNode.parentNode.parentNode.nextElementSibling.nextElementSibling.firstChild)
      // console.log(event.target.parentNode.parentNode.parentNode.nextElementSibling)
      event.stopPropagation()
      var target = event.target.parentNode.parentNode.parentNode.previousElementSibling.firstChild
      this.resetElements = !this.resetElements
      if ( target != this.optionsActive){
        document.querySelectorAll(".item-options-container").forEach(element => {if(element.classList.contains("show")) {element.classList.remove("show");element.style.pointerEvents="auto";}})
      }
      target.classList.toggle("show")
      this.optionsActive = target  
    },
    toggleOptionsWithFunction(id){
      this.$refs['itemToggle'+id][0].click()
    },
    toggleCubeOptions(event){
      event.stopPropagation()
      this.resetElements = !this.resetElements
      if(this.optionsActive){
          // console.log("Active")
          document.querySelectorAll(".item-options-container").forEach(element => {if(element.classList.contains("show")) {element.classList.remove("show");element.style.pointerEvents="auto";}})
          this.optionsActive = null
      }
      if ( event.target != this.cubeOptionsActive ){
      document.querySelectorAll(".cube-options-container").forEach(element => {if(element.classList.contains("show")) {
        element.classList.remove("show")
        var sibling = element.parentElement.parentElement.nextElementSibling;


	      while (sibling) {
	      	sibling.classList.toggle("blur")
	      	sibling = sibling.nextElementSibling
	      }
        }})
      }
      // console.log(event.target.nextElementSibling)
      event.target.nextElementSibling.classList.toggle("show")
      // this.resetElements = !this.resetElements
      // console.log(event)
      var sibling = event.target.parentElement.parentElement.nextElementSibling;

	    while (sibling) {
	    	sibling.classList.toggle("blur")
	    	sibling = sibling.nextElementSibling
	    }
      this.cubeOptionsActive = event.target  
    },
    toggleCubeOptionsWithFunction(id){
      this.$refs['cubeToggle'+id][0].click()
    },
    clickDate(itemId,done){
      // console.log(done)
      // console.log(!done << 0)
      const index = this.Items.findIndex(item => item.id == itemId)
      const val = !done << 0
      this.$api.put(`/api/items/update/duedone`,{
          id:itemId,
          duedone: val
      }).then(()=>{
          this.Items[index].due_done = val
      })
    },
    addCube(event){
      // console.log(event)
      var tmp = [...this.Cubes]
      let newCube =  {
                    title:event.name,
                    sort:this.Cubes.length+1,
                    flow_id:this.$route.params.id
                  }
      tmp.splice(event.position,0,newCube)
      // console.log(event.position != this.Cubes.length+1)
      if (event.position != this.Cubes.length+1){
        var i = 1
        tmp.forEach(cube => {
          cube.sort = i
          i = i + 1
        })
        this.$api.post(`/api/cube/add/sorted`,{
          array:tmp
        }).then((data)=>{
            this.Cubes = data.data
            // console.log(this.Cubes)
          })
        return
      }
      this.$api.post(`/api/cube/add`,{
        cube:newCube
      }).then((data)=>{
        this.Cubes.splice(event.position,0,data.data)
        })
    },
    deleteCube(index,id){
      if(!index){
        index = this.Cubes.findIndex(cube => cube.cube_id == id )
      }
      this.$api.delete(`/api/cubes/remove`, { data: { id: id } })
        .then(()=>{
            this.Cubes.splice(index, 1)
          })
    },
    addItem(event,cubeId){
      // console.log(cubeId)
      // console.log('add')
      let newItem =  {
                        title: event.itemName,
                        cube_id: cubeId,
                        Priority: this.filterItems(cubeId).length + 1,
                        sort: this.filterItems(cubeId).length + 1,
                        flow_id: this.$route.params.id
                      }
      this.$api.post(`/api/items/add`,{item:newItem}).then((data)=>{
        // console.log("splice")
        data.data.links = []
        this.Items.splice(this.filterItems(cubeId).length-1,0,data.data)
      })
    },
    deleteItem(index,id){
      if(!index){
        index = this.Items.findIndex(item => item.id == id )
      }
      this.$api.delete(`/api/items/remove`, { data: { id: id } })
        .then(()=>{
            this.Items.splice(index, 1)
          })
    },
    addNote(index,id,event){
      // console.log(event)
      this.Items[index].note = event 
    },
    getFiless(itemId){
      // console.log("func",this.Files)
      const regex = /([^/]*)\/([^/]*)\/([^/]*)/gm;
      var files = []
      

      this.Files.forEach((file)=>{
        // console.log(file.Key)
        let m;
        while ((m = regex.exec(file.Key)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
              regex.lastIndex++;
          }

          // The result can be accessed through the `m`-variable.
          m.forEach((match, groupIndex) => {
              console.log(`Found match, group ${groupIndex}: ${match}`);
          });
          if(m[2]==itemId){
            files.push(file)
          }
      }
      })
      console.log(files)
      return files

    },
    getFiles(){
        this.$fileStore.fileStoreApi.get('/files/'+this.$route.params.id).then((data)=>{
          this.Files=data.data
          // console.log(this.Files)
        })
    },
    removeFile(event){
      let index = Object.values(this.Files[event.itemId.toString()]).findIndex(file => file.name === event.fileName)
      let key = Object.keys(this.Files[event.itemId.toString()])
      let objKey = key[index]
      this.$delete(this.Files[event.itemId.toString()],objKey.toString())
      if(!Object.keys(this.Files[event.itemId.toString()]).length){
        this.$delete(this.Files,event.itemId.toString())
      }
    },
    scroll(event){
      if (event.which == 1 && event.target.id==="cube-container"){
        // console.log(event)
        // console.log(event.movementX + " , " + event.movementY)
        // console.log((event.target.offsetWidth + event.target.scrollLeft) > event.target.scrollWidth)
        if (!((event.target.offsetWidth + event.target.scrollLeft) > event.target.scrollWidth)){
          event.target.scrollLeft = event.target.scrollLeft + (event.movementX * -1)
        }
        else if((event.movementX * -1) < 0){
          event.target.scrollLeft = event.target.scrollLeft + (event.movementX * -1)
        }
      }
    }
  }
}
</script>

<style>
.show{
  display:block !important;
  opacity:1 !important; 
}
.blur{
  /* display:hidden !important; */
  filter:grayscale(100%) opacity(30%);
  pointer-events: none;
}
.Flow-main{
    height:90vh;
    width:100vw;
    display:flex;
    align-items: bottom;
    justify-content: flex-end;
    flex-shrink: 0;
    flex-grow: 0;
    flex-direction: column;
    /* overflow: auto; */
}
.cube-container{
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  display: flex;
  width:100%;
  height:90%;

}
.cube-dropzone{
  transition: all 0.2s ease-in-out;
  margin:1% 0;
  display:block;
  background-color: var(--color);
  width:0em;
  height:500px;
  flex-shrink:0;
  border-radius:var(--cube-border-radius);
}
.item-dropzone{
  transition: all 0.2s ease-in-out;
  margin:1% 0;
  display:block;
  background-color: var(--color);
  width:95%;
  height:0px;
  flex-shrink:0;
  border-radius:var(--item-border-radius);
}

.cube{
  background-color: var(--color-lightest);
  margin:1%;
  width:600px;
  height: min-content;
  flex-shrink:0;
  animation: blur-in 400ms ease-in 500ms forwards; /* Important */
  border-radius:var(--cube-border-radius);
  transition: background-color 1s ease-in-out,color 1s ease-in-out,opacity 0.5s ease-in-out;
}


.cube-title-box{
  display:flex;
  background-color: var(--color);
  color:var(--text-color);
  padding:1%;
  flex-grow:0;
  flex-shrink:0;
  width:98%;
  border-radius:var(--cube-border-radius);
  transition: all 1s ease-in-out;
  position:sticky;
  top:0px;
  z-index:200;
  backdrop-filter: blur(5px);
}
.cube-title{
  width:90%;
  /* width: 90% !important; */
}
.cube-title h2{
  margin:0;
  justify-content:flex-start;
  
  /* width: 90% !important; */
}

.item-container{
  transition: all 0.5s;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index:0;
  /* position:relative; */
}
.item{
  
  background-color: var(--color);
  /* background-color: rgba(128, 128, 128, 0.5); */
  /* background-color: rgb(22,22,24); */
  backdrop-filter: blur(5px);
  width:95%;
  margin:0;
  border-radius:var(--item-border-radius);
  z-index:0;
  color:var(--text-color);
  transition: background-color 1s ease-in-out,color 1s ease-in-out,opacity 0.2s ease-in-out;
  /* position:relative; */
  
}

.item-area{

  /* color:white; */
  display:inline-flex;
  /* margin:1% 1% 0% 1%; */
  /* backdrop-filter: blur(2px); */
  width:100%;
  min-height:50px;
  border-radius:var(--item-border-radius);
  z-index:0;
  /* height:50px; */
}

.item-color{
  content:"";
  width:2%;
  max-height: 50px;
  border-radius:var(--item-border-radius) 0 0 0;
  /* border-radius: inherit; */
}
.item-color::before{
  content:"|";
  opacity: 0;
}

.item-title{
  width:60%;
}

.item-title h3{
  white-space:pre-line;
  word-break:keep-all;
  margin:0;
  justify-content:flex-start;
  width: 100% !important;
  height:100%;
  margin-left:5px;
  margin-right:5px;
  color:var(--text-color);
  transition: color 1s ease-in-out;
}

.item-date{
  width:30%;
  display:flex; 
  align-items: center;
  justify-content: flex-start;
  max-height:50px;
}
.item-date:hover{
  cursor:pointer;
}

.item-date h4{
  background-color: black;
  color:var(--text-color-white);
  margin:0;
  display:flex; 
  align-items: center;
  justify-content: center;
  width: fit-content  !important;
  height:60%;
  padding:2%;
  border-radius:2px;
}
.cube-options-button-container{
  flex:1;
  position: relative; 
  
}
.cube-options-button{
  width: 100%;
  height:100%;
  border:none;
  border-radius:5px;
  background-color: var(--button);
  display:flex;
  align-items: center;
  background: url('../assets/img/options.png'),var(--button);
  background-size: 60% auto;
  background-repeat:no-repeat;
  background-position: center;
  transition: background-color 1s ease-in-out,color 1s ease-in-out,box-shadow .2s ease-in-out;
  filter:grayscale(100%);
}
.cube-options-button:hover{
  box-shadow: 0 0 15px -5px;
}

.item-options-button-container{
  position: relative;
  /* background-color: var(--color-lighter); */
  width:8%;
  max-height: 50px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--item-border-radius);
}
.cube-options-container{
  display:none;
  position:absolute;
  background-color: rgba(37, 37, 37, 0.63);
  backdrop-filter: blur(2px);
  width:590px;
  right:-10%;
  padding:5px;
  top:110%;
  height:fit-content;
  z-index:100;
}

.item-options-button{
  margin:5%;
  width:80%;
  height:80%;
  border:none;
  /* border-radius: 0 var(--item-border-radius) 0 0; */
  border-radius: 2px;
  /* background-color: var(--button); */
  /* mix-blend-mode: difference; */
  background: url('../assets/img/edit.png'),var(--button);
  background-size: 40% auto;
  background-repeat:no-repeat;
  background-position: center;
  transition: background-color 1s ease-in-out,color 1s ease-in-out,box-shadow .2s ease-in-out;
  filter:grayscale(100%)
}

.item-options-button:hover{
  box-shadow: 0 0 15px -5px;
}

.item-options-container{
  display:none;
  position:absolute;
  background-color: rgba(37, 37, 37, 0.63);
  backdrop-filter: blur(5px);
  width:360px;
  right:10%;
  padding:5px;
  /* top:0; */
  height:fit-content;
  z-index:500;
  border-radius: 5px;
  opacity:0;
  transition: all 0.2s;
}
.item-attachments-container{
  /* background-color: rgba(128, 128, 128, 0.5); */
  background-color: var(--color-lightest);
  width:100%;
  height:auto;
  border-radius: 0 0 var(--item-border-radius) var(--item-border-radius);
  color:var(--text-color);
  transition: all 1s ease-in-out;
}
.add-item{
  transition: all 0.5s; 
  display:flex;
  justify-content: center;
  align-items: center;
  height:40px;
  margin:1% 0;
}
.add-cube{
  height:400px;
}
.flow-actions-container{
  width:100%;
  height:5%;
  display:flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background: 
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.365)
    );
}
/* .flow-actions-container > :last-child{
  margin-left:auto;
} */
.clone{
  z-index:300;
}
</style>