<!--TO DO
Get buttons to emit
-->

<template>
  <div class="tooltips-main">
    <div class="animation">
      <lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>
    </div>
      <h4 class="tip">Cubes and items can be moved around</h4>
    <div class="warning-container">
      <div class="warning">

        <h1><img src="../assets/img/warning-yellow-70pxx.png" class="warning-img" alt="">WARNING</h1>
        <h3>cubeItDone is still under development and the current release is the alpha version.</h3>
        <h4>Please use at your own risk</h4>
        <h5>We thank you for your interest in cubeItdone and we hope this platform helps you achieve your goals.</h5>
        <h5>This platform will never serve Ads and will always be free to use.</h5>
        <p>For any queries, feedback, or feature requests please get in touch with us at <a href="mailto:cubeitdone.com">cubeitdone@gmail.com</a></p>
      </div>
    </div>
    <!-- <div class="notification-buttons" v-on:click="clickok()">
      <button class="ok" >Ok</button>
      <button class="dontshow">Don't show this again</button>
    </div> -->
  </div>
</template>
 
<script>
  import Lottie from 'vue-lottie';
  var animationData= require('../assets/lottie/data.json');
 
  export default {
    props:['showtool'],
    components: {
      'lottie': Lottie
    },
    data() {
      return {
        defaultOptions: {animationData: animationData},
        animationSpeed: 1
      }
    },
    watch:{
      showtool:function(newVal, oldVal){
        this.showtool ? this.anim.play() : this.anim.stop()
      }
    },
    methods:{
      handleAnimation: function (anim) {
        this.anim = anim;
        this.anim.stop()
      }
    }
  }
</script>
<style scoped>
.warning-container h3,h4,h5,p{
    text-align: center;
    width:90%;
    /* margin:0px; */
}

.warning-container h1{
  /* color:rgb(240,173,78); */
  /* color:rgb(255,204,78); */
  color:#FEE715FF;
  /* color:yellow; */
  font-weight:bold;
  letter-spacing: 3px;
}

.warning-img{
  height:70px;
  margin-right:10%;
}

a:link {
  color: #95C8D8;
  /* color: #4682B4; */
  background-color: transparent;
}

a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}
.tooltips-main{
    height:100%;
    z-index:10001;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
}
.animation{
    margin:0px;
    background-color: white;
    border-radius:10px;
    width:40%;
    height:auto;
}

.warning{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notification-buttons{
    margin-top:5px;
}
.ok{
    width:48%;
    height:40px;
    border:none;
    margin:1%;
    background-color: black;
    color:white;
}
.dontshow{
color:white;
 width:48%;
 height:40px;
 border:none;
 margin:1%;
 background-color: black;
}
</style>
 