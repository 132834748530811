<template>
  <div class="delete-flow-container">
      <img class="sure-warning-img" src="../assets/img/warning-yellow.png" alt="">
      <h1 class="sure">Are You Sure?</h1>
      <h3>Do you want to delete the flow named <b>{{flow.flow.title}}</b></h3>
      <h4>You will not be able to get your cubes and items in the flow back.</h4>
      <div class="button-container">
        <button v-on:click="yes()"><h3>Yes</h3></button>
        <button v-on:click="no()"><h3>No</h3></button>
      </div>

  </div>
</template>

<script>
export default {
    props:["flow"],
    methods:{
        yes(){
            this.$api.delete(`/api/flows/remove`,{data:{id:this.flow.flow.flow_id}}).then(()=>{
                this.$emit('delete',this.flow.index)
                this.no() 
            })
            
        },
        no(){
            this.$emit('reset')
        }
    }
}
</script>

<style>
.delete-flow-container{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    position: relative;
}
.sure-warning-img{
    height:10%;
}

b{
    margin-left:0.5%;
}
h1 , h3, h4{
    color:white;
}
.button-container{
    display:flex;
    justify-content: center;
    align-items: center;
    width:50%;
    height:5%;
    position: absolute;
    bottom:25%;
    gap:5%;
}
.button-container button{
    height:100%;
    width:40%;
}

.button-container button h3{
    margin:0;
    color:black;

}
</style>