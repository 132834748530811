<template>
<div class="people-container">
<div class="people-groups">
    <div class="groups-grid">
        <div class="people-search">
            <input type="search" v-model="query" placeholder="Search for people you may know">
        </div>
        <div class="soon">
            <h3>Groups</h3>
            <div class="content">
                <h2>available soon</h2>
            </div>
            
            <!-- <button>add group</button> -->
        </div>
        <div class="soon">
            <h3>Organisations</h3>
            <div class="content">
                <h2>available soon</h2>
            </div>
        </div>
    </div>
</div>
<div class="people-view">  
    <DefaultView v-bind:people="people" v-bind:requests="requests" v-bind:errors="{searched:errorStates.searched,requests:errorStates.requests}" v-if="!query" v-on:update="getPeople()"></DefaultView>  
    <SearchView v-bind:search="searched" v-bind:query="query" v-bind:error="errorStates.searched" v-if="query"  v-on:update="getPeople()"></SearchView>
</div>
</div>

</template>

<script>
import DefaultView from './sub-views/DefaultView.vue'
import SearchView from './sub-views/SearchView.vue'
export default {
components:{SearchView, DefaultView},
data(){
    return {
        query:"",
        people:[],
        searched:[],
        requests:[],
        errorStates:{
            people:false,
            searched:false,
            requests:false
        }
    }
},
mounted(){
    this.getPeople()
},
watch:{
    query:function(){
        // console.log(this.query)
        this.search()
    }
},
methods:{
    search(){
        this.$people.peopleApiSearch.get("?&q="+this.query)
        .then((data)=>{
            this.searched=data.data
        })
    },
    getPeople(){
        const people = this.$people.peopleApi.get("/friends")
        const requests = this.$people.peopleApiRequests.get("/requests")
        Promise.allSettled([people,requests])
        .then(data=>{
            // console.log(data)
            if(data[0].status === "fulfilled")
                this.people=data[0].value.data
            else
                this.errorStates.people = true
            if(data[1].status === "fulfilled")
                this.requests=data[1].value.data
            else
                this.errorStates.requests = true
        })
        .catch(err=>console.log(err))
        // .then((data)=>{
        //     this.people=data.data
        // })
    }
}

}
</script>

<style>
.soon{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column;
}
.soon .content{
    flex:1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.214);
}
.people-container{
    /* background-color: aqua; */
    width:100vw;
    height:90vh;
    display:flex;
    justify-content: space-around;
    align-items: center;

}
.people-groups{
    /* background-color: azure; */
    width:20%;
    height:100%;
    max-width:400px
}
.groups-grid{
    margin:10px;
    width:calc(100% - 20px);
    height: calc(100% - 20px);
    display:grid;
    grid-template-rows: repeat(3,minmax(100px,1fr));
    grid-template-columns: repeat(1,minmax(100px,1fr));
    gap:1rem;
    align-items: center;
    justify-content: center;

}
.groups-grid>*{
    width:100%;
    height:100%;
    border-radius:5px;
    /* background-color: var(--color-lighter); */
    color:var(--text-color);
    /* backdrop-filter: blur(10px); */
}
.people-search{
    height:50px;
}
.people-search input{
    height:100%;
    width:100%;
    padding:0;
    background-color: var(--color-lighter);
    border:none;
    color:var(--text-color);
    text-align: center;
    backdrop-filter: blur(10px);
    border-radius:5px;
}
.people-view{
    /* background-color:green; */
    width:80%;
    height:100%;
}
.person-grid{
    display:grid;
    gap:1rem;
    grid-template-columns: repeat(auto-fill,minmax(350px,1fr));
    /* grid-auto-columns: 100px; */
    grid-auto-rows: 200px;
    margin:auto;
    padding-right:10px;
    overflow-y: scroll;
    height: 85%;
    width:98%;
}

</style>