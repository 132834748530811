<template>
<div class="color-picker">
    <div class="selected-color" :style="cssVars">
    </div>
    <div class="colors-container">
        <div class="select-colors" v-for="n in 8" v-bind:key="n" :id="'color'+n" v-on:click="clickcolor(n)"> 
        </div>
    </div>    
</div>    
</template>
<script>
export default {
    props:['color','reset'],
    methods:{
        init(){
            if (this.color){
                this.csscolor=this.color
            }
        },
        clickcolor(n){
            this.csscolor=this.colors[n-1]
            this.$emit("colorInput",this.colors[n-1])    
        }

    },
    mounted(){
        this.init()
    },
    computed: {
    cssVars() {
      return {
        '--csscolor': this.csscolor
      }
    }
  },
  watch:{
      color:function(){
          this.csscolor=this.color
      },
      reset:function(){
          this.csscolor=this.color
      }
  },
    data(){
        return{
            colors:['#E6E6E6','black','grey','red','yellow','royalblue','purple','green'],
            csscolor:'#E6E6E6',
            fromparentcolor:null
        }
    }
}
</script>
<style scoped>
.color-picker{
    width:100%;

}
.selected-color{
    transition: all 0.2s;
    background-color: var(--csscolor);
    margin-bottom:10px;
    height:15px;
    border-radius:3px;
}
.colors-container{
    display:grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    gap:5px;
    min-height: 35px;
}
.select-colors{
    border-radius: 2px;
 
    /* width:100%;
    margin-left:5px;
    min-width:25px; */
    height: 30px;
}
.select-colors:hover{
    border: 2px solid whitesmoke;
    cursor: pointer;
}
#color1{
   background-color:#E6E6E6; 
}
#color2{
   background-color: black; 
}
#color3{
   background-color: grey; 
}
#color4{
   background-color: red; 
}
#color5{
   background-color: yellow; 
}
#color6{
   background-color: royalblue; 
}
#color7{
   background-color: purple; 
}
#color8{
   background-color: green; 
}
</style>