<template>
  <div class="delete-flow-container">
      <h1 class="sure">Are You Sure?</h1>
      <h3>Do you want to rename the flow <b class="title-text">{{flow.flow.title}}</b> to <b :style="newName == null || newName == '' ? {opacity:0.3}:{}">{{newName  || "new name"}}</b>.</h3>
      <input class="new-name" type="text" v-model="newName" placeholder="change flow name" v-on:keyup.enter="$refs.rename.click()">
      <div class="button-container">
        <button v-on:click="yes()" :disabled="newName == flow.flow.title || newName == null || newName == ''" ref="rename"><h3>Rename</h3></button>
        <button v-on:click="no()"><h3>Cancel</h3></button>
      </div>

  </div>
</template>

<script>
export default {
    props:["flow"],
    data(){
        return{
            newName:null
        }
    },
    methods:{
        yes(){
            this.$api.put(`/api/flows/update`,{id:this.flow.flow.flow_id,title:this.newName}).then(()=>{
                this.$emit('edit',{index:this.flow.index,title:this.newName})
                this.no() 
            })
            
        },
        no(){
            this.$emit('reset')
        }
    }
}
</script>

<style>
.delete-flow-container{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    position: relative;
}
.title-text{
    margin-right:0.5%;
}
b{
    transition: all 0.2s ease-in-out;
    margin-left:0.5%;
}
h1 , h3, h4{
    color:white;
}

.new-name{
    width:15%;
    height:3%;
    margin-top:1%;
    border:none;
    outline:none;
    border-radius:2px ;
    text-align: center;
}

.button-container{
    display:flex;
    justify-content: center;
    align-items: center;
    width:50%;
    height:5%;
    position: absolute;
    bottom:25%;
    gap:5%;
}
.button-container button{
    transition: all 0.2s ease-in-out;
    height:100%;
    width:40%;
}

.button-container button h3{
    margin:0;
    color:black;

}
</style>