<template>
<div class="item-options" draggable="true" v-on:drag.stop.prevent v-on:dragstart.stop.prevent>
    <div class="change-item">
        <input :maxlength="20" v-model="newTitle" placeholder="Change item name" class="change-item-input"/>
    </div>
    
    <DatePicker v-bind:date="date" v-bind:reset="reset" v-on:dateInput="newDate = $event" v-on:clear="newDate = 'clear'"></DatePicker>
    <ColorPicker v-bind:color="color" v-bind:reset="reset"  v-on:colorInput="newColor = $event"></ColorPicker>
    <div class="save-item">
        <button v-on:click="editItem(id)" :disabled="isDisabled">Save</button>
    </div>
    <AddAttachmentShared v-bind:reset="reset" v-bind:itemId="id" v-on:updateFiles="$emit('updateFiles')"></AddAttachmentShared>
    <AddNoteShared v-bind:notedata="noteData" v-bind:Flow="Flow" v-bind:reset="reset" v-bind:itemId="id" v-on:note="emitNote($event)" v-on:deleteNote="$emit('deleteNote')"></AddNoteShared>
    <!-- <AddLink v-bind:reset="reset"></AddLink> -->
</div>
    
</template>

<script>
import AddLink from '../components/Addlink.vue'
import AddAttachmentShared from '../components/AddFileAttachmentShared.vue'
import AddNoteShared from '../components/AddNoteShared.vue'
import ColorPicker from '../components/colorpicker.vue'
import DatePicker from '../components/datepicker.vue'
export default {
    components:{
        DatePicker,
        ColorPicker,
        AddAttachmentShared,
        AddNoteShared,
        AddLink
    },
    props:["id","title","date", "color", "noteData", "reset","Flow"],
    data(){
        return{
            newTitle:null,
            newDate:null,
            newColor:null
        }
    },
    methods:{
        editItem(itemId){
            if((this.title != this.newTitle) && (this.newTitle != null)){
                // console.log(this.newTitle)
                this.$apiShared.put(`/api/sharing/items/update`, {
                    id: itemId,
                    title: this.newTitle,
                    flow_id:this.Flow
                }).then(this.$emit("title", this.newTitle))
                
            }
            if((this.date != this.newDate) && (this.newDate != null)){
                // console.log(this.newDate)
                if(this.newDate == 'clear') this.newDate = null;
                this.$apiShared.put(`/api/sharing/items/update/dueon`, {
                    id: itemId,
                    dueon: this.newDate,
                    flow_id:this.Flow
                }).then(this.$emit("date", this.newDate))
            }
            if((this.color != this.newColor) && (this.newColor != null)){
                // console.log(this.newColor)
                this.$apiShared.put(`/api/sharing/items/update/color`, {
                    id: itemId,
                    color: this.newColor,
                    flow_id:this.Flow
                }).then(this.$emit("color", this.newColor))
            }
            this.$emit('toggle',0); 
        },
        emitNote(event){
            // console.log('note '+event)
            this.$emit("note",event) 
        }    
    },
    computed:{
        isDisabled:function(){

           return   [
                        (this.title == this.newTitle) || ((this.newTitle == null)||((this.newTitle == ""))),
                        (((this.date == this.newDate) || (this.newDate == null)) || ((this.date == null) && (this.newDate == 'clear'))),
                        ((this.color == this.newColor) || (this.newColor == null))
                    ].every((el)=>{return el == true})
        } 
    },
    watch:{
        reset:function(){
            this.newTitle=null,
            this.newDate=null,
            this.newColor=null
        }
    },
    mounted(){
        // console.log(this.isDisabled)
    }
}
</script>

<style>
.item-options{
    width:100%;
    height:100%;
}
.change-item{
    margin-bottom: 5px;
    height:30px
}
.change-item-input{
    text-align: center;
    width:100%;
    height:100%;
    padding:0;
    border:none;
    outline:none;
    border-radius:2px;
}
.save-item{
    margin-bottom:5px;
    height:30px
}
.save-item button{
  transition: all 0.2s ease-in-out;
  width:100%;
  height: 100%;
  border:none;
  background-color: black;
  color:white;
  border-radius:5px;
}
.save-item button[disabled=true], button:disabled{
  opacity:0.5;
}
</style>