<template>
  <div class="default-view">
    <h1>Search</h1>
    <div class="nothing" v-if="!search.length ">
        <h1>Looks like there is no one by the name {{query}}</h1>
    </div>
    <div class="person-grid">
      <template v-for="(person, index) in Search">
        <person v-bind:key="person.id" v-bind:person="person">
          <template  #inline v-if="person.status == 'recieved'">
            <button v-on:click="acceptRequest(person.user_id, index)" class="accept"><h5>Accept</h5></button>
            <button v-on:click="declineRequest(person.user_id, index)" class="exit"></button>
          </template>
          <template  #inline v-else-if="person.status == 'sent'"><h4>request sent</h4></template>
          <template  #inline v-else-if="person.status == 'friends'"><h4>friends</h4></template>
          <template  #inline v-else-if="person.status == 'error'"><h4>error</h4></template>
          <template  #inline v-else-if="person.username != $userName"><button v-on:click="sendRequest(person.user_id, index)">Send Request</button></template>
        </person>
      </template>
    </div>
  </div>
</template>

<script>
import person from "../../../GlobalComponents/person.vue";
export default {
  components: { person },
  props: ["search","query"],
  computed:{
    Search:function(){
        // console.log(this.search)
        return this.search
    }
  },
  data() {
    return {};
  },
  methods: {
    sendRequest(sendTo_ID, index) {
      this.$people.peopleApiRequests
        .post("/requests/send-request", {
          request_to: sendTo_ID,
        })
        .then(() => {
          const newData = this.Search[index];
          newData.status = "sent";
          this.$set(this.Search, index, newData);
        })
        .catch(() => {
          const newData = this.Search[index];
          newData.status = "error";
          this.$set(this.Search, index, newData);
        });
    },
    acceptRequest(accept_ID, index) {
      this.$people.peopleApiRequests
        .post("/requests/accept-request", {
          accept_from: accept_ID,
        })
        .then(() => {
          const newData = this.Search[index];
          newData.status = "friends";
          this.$set(this.Search, index, newData);
          this.$emit("update")
        })
        .catch(() => {
          const newData = this.Search[index];
          newData.status = "error";
          this.$set(this.Search, index, newData);
        });
    },
    declineRequest(decline_ID, index){
        // console.log(decline_ID,index)
      this.$people.peopleApiRequests
        .delete("/requests/decline-request", {data:{
          decline_from: decline_ID,
        }})
        .then(() => {
          const newData = this.Search[index];
          newData.status = null;
          this.$set(this.Search, index, newData);
        })
        .catch(() => {
          const newData = this.Search[index];
          newData.status = null;
          this.$set(this.Search, index, newData);
        });
    }
  },
};
</script>

<style scoped>
hr {
  background-color: rgba(250, 235, 215, 0.144);
}
h1 {
  justify-content: flex-end;
  width: 95%;
}
.default-view {
  height: 100%;
    display:flex;
  flex-direction: column;
}
.person-grid {
  overflow-y: scroll;
  height: 86.5%;
}
</style>