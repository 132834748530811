<template>
  <div class="person-container" >
    <div class="person-image" :style="coverImg(person['cover_img'])"><img :src="profileImg(person['profile_img'])" alt="" /></div>
    <div class="person-details">
      <div class="person-name">
        <h4>{{ person.first_name }}</h4>
        <h4>{{ person.last_name }}</h4>
      </div>
      <hr />
      <div class="person-bottom">
        <div class="username"><h5 style='margin:0'>{{ person.username }}</h5></div>
        <div v-if="person.username == $userName" class="inline-slot"><h5 style='margin:0'>You</h5></div>
        <div v-else class="inline-slot">
            <slot name="inline"></slot>
        </div>
        
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    props:["person"],
    data(){
        return{
            profileUrl:process.env.VUE_APP_PROFILE_IMG_API
        }
    },
    methods:{
        profileImg(img){
            if(img){
                return `${this.profileUrl}uploads/avatars/responsive/${img}_lg.png`
            }
            else return 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs'
        },
        coverImg(img){
            if(img){
                 return {
                    backgroundImage:`url(${this.profileUrl}uploads/cover/responsive/${img}_lg.png)`
                }
            } 
        }
    }

};
</script>

<style>
.person-container{
    display:flex;
    height:100%;
    flex-direction: column;
    background-color: var(--color-light);
    backdrop-filter: blur(0px);
    border-radius:5px;
    animation: blur-in 400ms ease-in 500ms forwards; /* Important */

}
.person-image{
    width:100%;
    height: 50%;
    /* background-color: black; */
    border-radius:inherit;
    display: flex;
    position:relative;
    border-radius:5px;
    background-position: center;
    background-color: rgb(38, 38, 38);
    background-size: 100% auto;
    background-repeat:no-repeat ;
}
.person-image img{
    height:104px;
    width:104px;
    box-shadow:0px 0px 50px -20px;
    margin: auto;
    border-radius:5px;
    background-color: rgb(6, 6, 6);
    border:none;
    z-index:100;
    position: absolute;
    bottom:-30%;
    left:2.5%;
}
.person-details{
    width:100%;
    height:50%;

}
.person-details h4{
    margin:none;
    color:var(--text-color);
}
.person-name{
    display:flex;
    justify-content: right;
    /* justify-content: center; */
    align-items: center;
    width:99%;
    gap:0.3em;

    font-weight:bold;
}
.person-bottom{
    display:flex;
}
.person-bottom .inline-slot{
    margin-left:auto;
    margin-right:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.person-bottom .inline-slot h4,.person-bottom .inline-slot h5{
    margin:0;
    color:var(--text-color);
    opacity: 0.5;
}

.inline-slot button{
    border:none;
    height:25px;
    border-radius:2px;
}
.inline-slot button.accept{
    background-color: rgb(88, 164, 78);
    color:white;
    border:none;
    height:25px;
    border-radius:2px;
}

.inline-slot button.accept h5{
    margin:0;
    color:white;
    opacity: 1;
    font-size: 1em;
}



.username{
    /* width:calc(90%-10px); */
    margin-left: 10px;
    text-align: left;
    color:var(--text-color);

}
</style>
<style scoped>
hr {
  background-color: rgba(250, 235, 215, 0.144);
  height:3px;
  background-color:var(--text-color);
}
</style>