<template>
<div class="Wallpaper">
    <div class="Wallpaper-inside">
    <div class="circle-big">
        <img :src="$settings.wallpaperUrl" alt="">   
	  </div>
  <div class="circle-big">
    <h3>Wallpaper</h3>
	</div>
    <div class="circle-big show-wallpaper">
        <button v-on:click.stop="$emit('show')"><img src="../../../assets/img/right-arrow.png" alt=""></button>
	</div>
    </div>
</div>
</template>

<script>
export default {
    computed:{
        storageUsed:function(){
            return (this.$storageInfo.storageUsed / 1.074e+9).toFixed(2)
        }
    }
}
</script>

<style>


.Wallpaper {
  width: 100%;
  height:100%;
  border-radius: 5px;
  box-shadow: .5rem .5rem 1rem rgba(0, 0, 0, 0.6);
  background: #413951;
  background: linear-gradient(145deg, #413951 0%, #201c29 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#413951', endColorstr='#201c29',GradientType=0 );
  color: #fff;
  font-family: 'montserrat', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Wallpaper h2{
    margin:0;
}

.Wallpaper-inside{
    width:95%;
    height:100%;
    display:grid;
    grid-template-columns: 1.2fr 1fr .5fr;
    align-items: center;
    gap:30px;
}

.headline {
  font-size: 2rem;
  text-align: center;
}

.circle-big {
  position: relative;
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;  
  gap:50px;
}

.circle-big img{
    width:90%;
    height:auto;
    max-height:200px;
    border-radius:5px;
    object-fit: cover;
}

.circle-big .text {
  width: 100%;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  /* line-height: 22px; */

}

.circle-big .text .small {
  font-size: 14px;
  color: rgb(133, 133, 133);
  /* margin-top:5%; */
}


.circle-big button{
  height:80%;
  width:120px;
  border:none;
  border-radius:5px;
}
.circle-big button img{
  height:auto;
  width:30%;
}

.show-wallpaper button{
  background-color: #8182819c;
}
</style>