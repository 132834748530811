<template>
  <div class="nothing">
      <h1>In active development</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.nothing{
    height:90vh !important;
    width:100vw !important;
}
</style>