<template>
  <div class="files-area">
      <div class="files-area-actions-container">
          <button class="delete-files show" v-on:click="showDelete($props.itemId)" ref="delete-files" v-if="del">Delete Files</button>
          <h4 v-if="removing">removing...</h4>
          <h4 v-if="error" class="error">Error</h4>
          <button v-on:click.stop="click($el)">
        <transition name="faded" mode="out-in">
              <img v-if="gridView" src="../assets/img/list.png" alt="" key="grid">
              <img v-else src="../assets/img/grid.png" alt="" key="list">
        </transition>
          </button>
      </div>
     <div :id="'files-view-container'+$props.itemId" class="files-view-container">
      <template v-for="(file,index) in Files">
          <div class="file" v-bind:key="index">
              <div class="file-delete-container">
                  <button v-on:click="deleteFile(file.name,$props.itemId)">x</button>
              </div>
              <div class="file-img-container">
                  <img :src="file.thumbnail" alt="">
              </div>
              <div class="file-info-container">
                  <div class="file-name"><h5>{{file.name}}</h5></div>
                  <div class="file-actions">
                      <h6>{{$utils.formatBytes(file.Size)}}</h6>
                      <ViewPdfButton :fileName="file.name" :itemId="$props.itemId" v-if="file.name.includes('.pdf')" :shared="shared"></ViewPdfButton>
                      <DownloadButton :fileName="file.name" :itemId="$props.itemId" :shared="shared"></DownloadButton>

                      <!-- <button><img src="../assets/img/download.png" alt=""></button> -->
                      <!-- <button></button> -->
                  </div>
              </div>
          </div>
      </template>
      </div>


  </div>
</template>

<script>
import DownloadButton from './buttons/DownloadButton.vue'
import ViewPdfButton from './buttons/ViewPdfButton.vue'

export default {
  props:["itemId",'files','shared','del'],
  components: { DownloadButton,ViewPdfButton },
 data(){
     return{
        //  Files:[{name:"blahblahblah.pdf"},{name:"Superimportantthing.pdf"},{name:"Superimportantthing.pdf"},{name:"Superimportantthing.pdf"},{name:"Superimportantthing.pdf"},{name:"Superimportantthing.pdf"},{name:"Superimportantthing.pdf"}],
        //  Files:this.$props.files,
         gridView:true,
         removing:false,
         error:false
     }
 },
 computed:{
     Files:function(){
         return this.$props.files
     }
 },
 methods:{
     click(element){
        //  console.log(element)
        element.querySelector('#files-view-container'+this.$props.itemId).classList.toggle('files-view-rows')
        this.gridView = !this.gridView
     },
     gridOrList(){
         return true;
     },
     showDelete(itemId){
        //  console.log("run function")
         var el = document.querySelector('#files-view-container'+itemId)
         el.classList.contains('show-delete')? el.classList.remove('show-delete') : el.classList.add("show-delete")
     },
     deleteFile(fileName,itemId){
         this.error = false
         this.removing = true
        //  console.log(this.$route.params.id)
        if(this.shared === true){
         this.$fileStore.fileStoreApiShared.delete(`/delete/${fileName}/${this.$route.params.id}/${itemId}`).then(()=>{
             this.removing = false
             this.error = false
             this.$emit("delete", {fileName,flowId:this.$route.params.id,itemId})
         }).catch(()=>{
             this.removing = false
             this.error = true
             setTimeout(()=>{
                 this.error = false
             },2000) 
         })
        }
        else{
         this.$fileStore.fileStoreApi.delete(`/delete/${fileName}/${this.$route.params.id}/${itemId}`).then(()=>{
             this.removing = false
             this.error = false
             this.$emit("delete", {fileName,flowId:this.$route.params.id,itemId})
         }).catch(()=>{
             this.removing = false
             this.error = true
             setTimeout(()=>{
                 this.error = false
             },2000) 
         })
        }
        
     }
 }
}
</script>
<style>
.files-area h6{
    /* margin:0; */
    margin-right:auto;
    height:fit-content;
    /* color: rgba(240, 248, 255, 0.322); */
    color: var(--text-color-contrast-light);


}

.files-area-actions-container {
    /* background-color: aqua; */
    display:flex;
    justify-content: flex-end;
    height:35px;
    margin-bottom:15px;
    gap:5px;
}
.files-area-actions-container h4 {
    /* background-color: aqua; */
    color:var(--text-color);
    font-weight: bold;
}

.files-area-actions-container .error{
    color:var(--nice-red)
}

.files-area-actions-container button{
    border:none;
    outline:none;
    border-radius:2px;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.144);
    opacity:0.5;
    filter:var(--invert);
    transition:all 0.2s ease-in-out;
}
.show-delete .files-area-actions-container button{
    background-color:"red";
    filter:none;
}

.files-area-actions-container button:hover{
    opacity:1;
}

.files-area-actions-container button img{
    height:24px;
    /* opacity:0.7; */
    /* transition: all 0.2s ease-in-out; */
}

.delete-files{
    margin-right:auto;
}

.files-view-container{
    transition: all 0.5s ease-in-out;
    display: grid;
    grid-gap:10px;
    max-height:400px;
    overflow-y:auto;
    /* overflow-x:hidden; */
    /* overflow-y:hidden ; */
    justify-items: center;
    grid-template-columns: repeat(2,1fr);
}

.files-view-rows{
    grid-template-columns: repeat(1,539px);
}

.files-view-rows .file{
    height:70px;
    display:flex;
}

.files-view-rows .file-info-container .file-name{
    flex-grow: 1;
    margin:0;
}

.files-view-rows .file-img-container{
    display:none;
}

.files-view-rows .file-info-container{
    width:98%;
    padding:1%;
    /* height:100%; */
    position:unset;
    flex-direction: row;
    background-color: transparent;
}
.files-view-rows .file-actions{
    height:100%;
    align-self: flex-end;
    width:40%;
    justify-content: flex-end;

}
.files-view-rows .file-actions h6{
    margin-left:20px;
    margin-right:10px;
    order:2;
}


.file{
    background-color:var(--color);
    height:150px;
    width:95%;
    border-radius:5px;
    position:relative;
    transition:background-color 0.2s ease-in-out;

}

.file-delete-container{
    display:none;
}

.show-delete .file-delete-container{
    display:block;
    position:absolute;
    right:0px;
    border-radius:5px;
    height:15%;
    width:10%;
}
.show-delete .file-delete-container button{
    border:none;
    border-radius:2px;
    background-color: var(--nice-red);
    color:white;
    height:100%;
    width:100%;

}

.files-view-rows .file-delete-container {
    z-index:10;
    height:25px;
    width:5%;
}

.file-img-container{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.file-img-container img{
    width:auto;
    height:100%;
}
.file-info-container{
    position:absolute;
    width:auto;
    bottom:0;
    padding:5%;
    width:90%;
    background-color:var(--color-light);
    /* background-color: black; */
    backdrop-filter:blur(5px);
    display:flex;
    flex-direction: column;
    border-radius:inherit;
    transition:all 0.2s ease-in-out;
}
.file-name{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
 
    margin-bottom:10px;
    transition:all 0.2s ease-in-out;
}
.file-name h5{
    margin:0;
    color:var(--text-color);
       font-weight: 400;
       transition: all 0.2s ease-in-out;
}
.file-actions{
    align-self: flex-end;
    height:30px;
    width:100%;
    transition:all 0.2s ease-in-out;
    display:flex;
    align-items: center;
    /* background-color: aqua; */
}

.file-actions button{
    opacity:0;
}


.file-actions button img{
    height:inherit;
    max-height:24px;
    max-width:24px;
}

.file-info-container:hover .file-actions{
    height:60px;
}

.file-info-container:hover button{
    opacity:1;
}

.faded-enter-active, .faded-leave-active {
  transition: all 0.3s;
}
.faded-enter, .faded-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height:0px;
}
</style>