<template>
  <div class="flow-actions"  >
      <button class="delete position" v-on:dragenter="cubeDragOver($event)" v-on:dragleave="stopDragOver($event)" v-on:drop="drop($event)" v-on:mouseover="text='Drag a cube or item here to delete it'" v-on:mouseleave ="text='delete'" @dragover.prevent v-if="!toDelete">{{text}}</button>
      <div v-else class="confirm position">
          <h2>Are you Sure?</h2>
          <h4>Do you want to delete the {{type}} <b v-if="type!='item'">{{toDeleteCubeName}}</b> <b v-else>{{toDeleteItemName}}</b>{{type=="item"? " from ": ""}} <b v-if="type=='item'">{{cubeName}}</b> ?</h4>
          <div class="buttons">
                <button v-on:click="yes()">Yes</button>
                <button v-on:click="no()">No</button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:["Cubes","Items"],
    data(){
        return{
            text: "delete",
            toDelete:null,
            toDeleteCubeName:null,
            toDeleteItemName:null,
            cubeName:null,
            type:null
        }
    },
    methods:{
        cubeDragOver(event){
            // console.log(event)
            event.target.style.height = "500%"
            event.target.style.width = "50%"
            // clone.style.width=document.querySelector(".cube").offsetWidth+"px";

        },
        stopDragOver(event){
            event.target.style.height = "80%"
            event.target.style.width = "5%"

        },
        drop(event){
            // event.stopPropogation()
            // event.preventDefault()
            // console.log(event)
            const cubeId = event.dataTransfer.getData("cubeId")
            const itemId = event.dataTransfer.getData("itemId")
            if(cubeId){
                this.type = "cube named"
                const cubeIndex = this.Cubes.findIndex(cube => cube.cube_id == cubeId)
                if(!itemId){
                    this.$emit("toDeleteCube",cubeIndex)
                }
                this.toDeleteCubeName = this.Cubes[cubeIndex].title
                this.toDelete = cubeId
            }
            if(itemId){
                this.type = "item"
                const itemIndex = this.Items.findIndex(item => item.id == itemId )
                this.$emit("toDeleteItem",itemIndex)
                this.toDeleteItemName = this.Items[itemIndex].title
                this.cubeName = this.Cubes[this.Cubes.findIndex(cube => cube.cube_id == this.Items[itemIndex].cube_id )].title
                this.toDelete = itemId
            }
            // clone.style.width=document.querySelector(".cube").offsetWidth+"px";
        },
        yes(){
            if (this.type != "item"){
                this.$emit("deleteCube",{cube_id:this.toDelete})
                this.no()
            }
            else {
                this.$emit("deleteItem",{item_id:this.toDelete})
                this.no()
            }
        },
        no(){
            this.$emit("toDeleteCube",null)
            this.$emit("toDeleteItem",null)
            this.toDelete = null
            this.toDeleteCubeName = null
            this.toDeleteItemName = null
            this.cubeName = null
            this.type = null
        }
    }
}
</script>

<style>
    .flow-actions{
        position:relative;
        display:flex;
        justify-content: flex-end;
        /* align-items: center; */
        width:50%;
        height:100%;
    }

    .position{
        position:absolute;
        bottom:10%;
        right:10%;
        z-index:200;
    }

    .flow-actions .delete{
        transition: all 0.2s ease-in-out;
        width:60px;
        height:80%;
    }
    .flow-actions .delete:hover{
        bottom:10%;
        z-index:200;
        width:50%;
        height:500%;
    }
    .confirm{
        display:flex;
        flex-direction: column;
        background-color: white;
        width:50em;
        height:500%;
        transition: all 0.2s ease-in-out;
        border-radius:2px;
    }
    .confirm h4{
        text-align: center;
        color:black;
    }
    .buttons{
        display:flex;
        align-items: center;
        justify-content: center;
        height:30%;
        width:100%;
    }
    .buttons button{
        width:30%;
        height:50%;
        margin:1%;
    } 
</style>