<template>
    <div class="addlink">
        
        <div class="protocol-text"><p :style="protocol===true?{color:'white',backgroundColor:'black'}:{}" class="http">http</p><p :style="protocol===false||protocol==='add'?{color:'white',backgroundColor:'black'}:{}" class="https">https</p></div>
        <input type="text" class="link-input" placeholder="add link" v-model="link" v-on:click="$event.stopPropagation()">
        
        <button class="add-link-button" v-if="link" v-on:click="addlink()">{{links}}</button>
        
        
    </div>
</template>
<script>
export default {
    props:['reset'],
    watch:{
        reset:function(){
            this.link = null;
            this.protocol = null;
        }
    },
    methods:{
        addlink(){
            if(this.link.match(this.compattern)){
            if (this.protocol===true || this.protocol===false){
                this.$emit('addlink',this.link)
                this.link = null;
                this.protocol = null;
            }
            else if(this.protocol==='add'){
                this.$emit('addlink','https://'+this.link)
                this.link = null;
                this.protocol = null;
            }}
        }
    },
    data(){
        return{
        protocol:null,
        link:null,
        buttontext:null,
        compattern:/\.com/igm,
        pattern:/(^http[s]?:\/\/)/im
        }
    },
    computed:{
        links:function(){
            if (this.link.match(this.compattern)){
                if (this.link.match(this.pattern))
                {
                    if (this.pattern.exec(this.link)[0]==='http://')
                    {
                        this.protocol=true
                    }
                    else if (this.pattern.exec(this.link)[0]==='https://')
                    {
                        this.protocol=false
                    }
                }
                else {
                    this.protocol='add'
                }
                return 'Add Link'
            }
            else {
                this.protocol=null
                return 'Invalid link'
            }

        }
    }
}
</script>
<style scoped>
.addlink{
    width:100%;
}
.protocol-text{
    margin-top:5px;
    margin-bottom:5px;
    width:100%;
    color:grey;
}
.http{
    margin:0;
    display: inline-block;
    width:50%;
    text-align: center;
}
.https{
    margin:0;
    display: inline-block;
    width:50%;
    text-align: center;
}
.link-input{
    width:100%;
    height:30px;
    border-radius:2px;
    border:none;
    text-align: center;
    padding:0;
}
.add-link-button{
    display:block;
    background-color: black;
    letter-spacing: 1px;
    color:white;
    height:25px;
    width:100%;
    margin-top:1px;
    margin-bottom:2px;
    border:none;
    border-radius:0px 0px 5px 5px;
}
</style>