<template>
<div class="notebooks-container">
  <h1>Notebooks is currently under development</h1>
</div>

</template>

<script>
export default {

}
</script>

<style>
.notebooks-container h1{
    margin:0;
}
.notebooks-container{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;

}
</style>