<template>
<div class="add-item-container">
    <input type="text" placeholder="Add an item" v-model="itemName" v-on:keyup.enter="$refs.addItem.click()">
    <button v-on:click="addItem()" ref="addItem" class="add"></button>
</div>
            
</template>

<script>
export default {
    data(){
        return{
            itemName:null
        }
    },
    methods:{
        addItem(){
            this.$emit('addItem',{itemName:this.itemName})
            this.itemName=null
        }
    }
}
</script>

<style>
.add-item-container{
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.add-item-container input{
  width:85%;
  height:100%;
  margin:0;
  padding:0;
  border:0;
  outline:none;
  text-align: center;
}
.add-item-container button{
  width:10%;
  height:100%;
  margin:0;
  padding:0;
  border:0;
  background-color: black;
  color:white;
}
</style>