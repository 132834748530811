<template>
<div class="Profile">
    <div class="Profile-inside">
    <div class="circle-big">
        	<h1>{{$name}}</h1>
	</div>
    <div class="circle-big profile">
        <button :style="{backgroundImage:'url('+$img.img+')'}" v-on:click="profile()"><img src="https://img.icons8.com/material-rounded/512/ffffff/user-male-circle.png"/></button>
		<!-- <button><img src="https://img.icons8.com/material-rounded/512/000000/user-male-circle.png"/></button> -->
	</div>
    <div class="circle-big" >
        <button style="background-color: rgb(197, 55, 55);" v-on:click="logout()"><img src="https://img.icons8.com/external-tal-revivo-bold-tal-revivo/512/ffffff/external-logout-screen-from-the-working-portfolio-of-a-user-classic-bold-tal-revivo.png"/></button>
	</div>
    </div>
</div>
</template>

<script>
export default {
    computed:{
        storageUsed:function(){
            return (this.$storageInfo.storageUsed / 1.074e+9).toFixed(2)
        }
    },
    methods:{
    logout(){
      window.location.href='https://login.cubeitdone.com/auth/realms/cubeItDone/protocol/openid-connect/logout?redirect_uri=https://cubeitdone.com/'
    },
    profile(){
      window.location.href='https://profile.cubeitdone.com'
    },
    }
}
</script>

<style>

.Profile {
  width: 100%;
  height:100%;
  border-radius: 5px;
  box-shadow: .5rem .5rem 1rem rgba(0, 0, 0, 0.6);
  background: #413951;
  background: linear-gradient(145deg, #413951 0%, #201c29 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#413951', endColorstr='#201c29',GradientType=0 );
  color: #fff;
  font-family: 'montserrat', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Profile h2{
    margin:0;
}

.Profile-inside{
    width:95%;
    height:100%;
    display:grid;
    grid-template-columns: 1.2fr 1fr .5fr;
    align-items: center;
    gap:30px;
}
.profile button{
    background-position: center;
    background-size:cover;
    background-color: rgb(50, 50, 52);   
}
</style>