<template>
<div class="pop-up">
<button class="exit" v-on:click="$emit('show',!show)"></button>
<slot></slot>
</div>
</template>

<script>

export default {
    props:["show"]
}
</script>

<style>
.pop-up{
    background-color: rgba(0, 0, 0, 0.616);
    position: fixed;
    width:100%;
    height:90%;
    bottom:0;
    left:0;
    z-index:210;
    display:flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
}
.pop-up > .exit{
    position:absolute;
    height:40px;
    width:40px;
    left:1%;
    top:1em;
    background-size:contain;
    background-color: transparent;
}
</style>